export enum PageModal {
  margin = 'margin',
  transfer = 'transfer',
  restrictedAccess = 'restrictedAccess',
  earnInvest = 'invest',
  earnRedeem = 'redeem',
  unwind = 'unwind'
}

export const RoutePaths = {
    restrictedPersons: '/restricted-persons',
}
