import React, {useEffect, useState} from 'react'
import {Box, Button, Spinner, Text} from "grommet";
import {useAccount, useWriteContract, useWaitForTransactionReceipt} from 'wagmi'
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {
  FormError,
  LiquidityOperation,
  MarketInfo,
  MarketPortfolio,
  RiskDirection,
  FutureInfo,
  LiquidityQuote, LiquidityDistribution, MakerLiquidityDistribution
} from "../../types";
import {Header} from "./Header";
import {Request} from "./Request";
import config, {configMode} from "../../config";
import RouterABI from '../../abi/RouterABI.json';
import {CurrentProvision} from "./CurrentProvision";
import {getFutureAlias, getFutureByAlias, getOraclePackage, prepareFormNumber} from "../../utils";
import {ExplorerLink} from "../../components/Text";
import {BigNumber} from "ethers";
import erc20MockABI from "../../abi/erc20MockABI.json";
import {Margin} from "./Margin";
import {getFormError} from "./validator";
import useDebounce from "../../hooks/useDebounce";
import {QuoteErrorMessage, RouterErrorMessage, WidgetContainer} from "../../components";
import {
  getPoolLiquidityDistribution,
  getMakerLiquidityDistribution
} from "../../api/viewContract";
import {getAllowance, getBalanceOf} from "../../api/erc20Contract";
import {getLPQuote} from "../../api/quoterContract";
import {useProtocolData} from "../../providers/ProtocolDataProvider";
import {Address} from "viem";
import {switchNetwork, useBalance, useNetwork} from "../../hooks/blockchainHooks";

interface FormValuesState {
  operation: LiquidityOperation
  notional: string,
  collateral: string,
  swapRateMin: string,
  swapRateMax: string,
  isNativeTokenSelected: boolean
}

const defaultValuesState: FormValuesState = {
  operation: LiquidityOperation.provide,
  notional: '0',
  collateral: '0',
  swapRateMin: '0.0',
  swapRateMax: '10.0',
  isNativeTokenSelected: false
}

export interface LiquidityProvisionParams {
  futureId: string
  marketId: string
  market: MarketInfo
  marketPortfolio?: MarketPortfolio
  currentSwap?: FutureInfo
  liquidityQuote?: LiquidityQuote
  formValues: FormValuesState
  underlyingBalance: BigNumber
  poolLiquidityDistribution: LiquidityDistribution
  makerLiquidityDistribution: MakerLiquidityDistribution
  nativeTokenBalance: BigNumber
}

export interface LiquidityProvisionProps extends LiquidityProvisionParams {
  formError: FormError | null
  onChangeFormValue: (newValues: Record<string, string | boolean | RiskDirection>) => void
}

export const LiquidityProvision = () => {
  const params = useParams()
  const { isConnected, address: userAddress } = useAccount()
  const {chain} = useNetwork()

  const isUnsupportedNetwork = isConnected && chain === undefined

  const [searchParams] = useSearchParams()
  const fromPage = searchParams.get('fromPage') || 'trade'
  const navigate = useNavigate()
  // const marketId = params.marketId as string
  // const futureId = params.futureId as string
  const paramsFutureAlias = params.futureAlias || ''

  const {
    markets: peripheryMarkets,
    portfolio: portfolios,
    portfolioInitialFetching: isLoading,
    refetchPortfolio,
    currentMarketType
  } = useProtocolData()

  const [marketId, setMarketId] = useState('')
  const [futureId, setFutureId] = useState('')
  const [formValues, setValues] = useState<FormValuesState>(defaultValuesState)
  const debouncedNotional = useDebounce(formValues.notional, 250)
  const [provideLiquidityTxHash, setProvideLiquidityHash] = useState('')
  const [approveTxHash, setApproveTxHash] = useState('')
  const [txError, setTxError] = useState<Error | null>(null)
  const [txTimestamp, setTxTimestamp] = useState(0)

  const [poolLiquidityDistribution, setPoolLiquidityDistribution] = useState<LiquidityDistribution>({
    provisionDistribution: { total: BigNumber.from(0), receiver: BigNumber.from(0), payer: BigNumber.from(0) },
    currentFutureRate: BigNumber.from(0),
    intervalLiquidity: []
  })
  const [makerLiquidityDistribution, setMakerLiquidityDistribution] = useState<MakerLiquidityDistribution>({
    currentFutureRate: BigNumber.from(0),
    intervalLiquidity: []
  })
  const [liquidityQuote, setLiquidityQuote] = useState<LiquidityQuote>()
  const [liquidityQuoteError, setQuoteError] = useState<Error | null>(null)
  const [allowance, setAllowance] = useState<BigNumber>(BigNumber.from(0))
  const [underlyingBalance, setUnderlyingBalance] = useState<BigNumber>(BigNumber.from(0))

  const market = peripheryMarkets.find((item) => item.descriptor.id === marketId)
  const underlyingDecimals = market ? market.descriptor.underlyingDecimals : 18
  const underlying = market ? market.descriptor.underlying : ''
  const marketPortfolio = portfolios.find(p => p.descriptor.id === marketId)

  const { data: nativeTokenBalanceData } = useBalance({
    address: userAddress,
    watch: true
  })
  const nativeTokenBalance = nativeTokenBalanceData ? BigNumber.from(nativeTokenBalanceData?.value.toString()) : BigNumber.from(0)

  const {
    data: approveTxData,
    // isError: isApproveTxError,
    isLoading: isApproveConfirming,
    isSuccess: isApproveSuccess
  } = useWaitForTransactionReceipt({
    hash: approveTxHash as `0x${string}`,
    confirmations: config.txConfirmations
  })

  const {
    data: provideLiquidityData,
    isLoading: isProvideConfirming,
    isError: isProvideError,
    error: provideError,
    isSuccess: isProvideSuccess
  } = useWaitForTransactionReceipt({
    hash: provideLiquidityTxHash as `0x${string}`,
    confirmations: config.txConfirmations
  })

  const loadLiquidityQuote = async () => {
    if(userAddress) {
      setQuoteError(null)
      const oraclePackage = await getOraclePackage(marketId)
      const notional = prepareFormNumber(debouncedNotional, underlyingDecimals)
      const operation = formValues.operation === LiquidityOperation.provide ? '0' : '1'
      const min = prepareFormNumber(formValues.swapRateMin, 16)
      const max = prepareFormNumber(formValues.swapRateMax, 16)
      const data = await getLPQuote(futureId, notional, userAddress, operation, min, max, [oraclePackage])
      setLiquidityQuote(data)
      console.log('LP Quote: ', data)
    }
  }

  useEffect(() => {
    if(paramsFutureAlias) {
      const data = getFutureByAlias(paramsFutureAlias, peripheryMarkets)
      if(data) {
        setMarketId(data.market.descriptor.id)
        setFutureId(data.future.id)
        return
      }
      const [market] = peripheryMarkets
      if(market) {
        setMarketId(market.descriptor.id)
        if(market.futures.length > 0) {
          setFutureId(market.futures[0].id)
          const futureAlias = getFutureAlias(market, market.futures[0])
          if(futureAlias) {
            navigate(`/${currentMarketType}/liquidity/${futureAlias}?network=${configMode}`)
          }
        }
      }
    }
  }, [paramsFutureAlias, peripheryMarkets.length]);

  // Liquidity distribution
  useEffect(() => {
    const loadLD = async () => {
      try {
        const oraclePackage = await getOraclePackage(marketId)
        if(futureId) {
          const data = await getPoolLiquidityDistribution(futureId, [oraclePackage])
          console.log('Pool liquidity distribution:', data)
          setPoolLiquidityDistribution(data)
        }
        if(userAddress) {
          const makerData = await getMakerLiquidityDistribution(futureId, userAddress)
          console.log('Maker liquidity distribution:', makerData)
          setMakerLiquidityDistribution(makerData)
        }
      } catch (e) {
        console.error(`Cannot load liquidity distribution`, e)
      }
    }
    loadLD()
  }, [futureId, userAddress, isProvideSuccess]);

  useEffect(() => {
    const loadData = async () => {
      try {
        if(
          userAddress
          && !prepareFormNumber(debouncedNotional, underlyingDecimals).isZero()
          && !prepareFormNumber(formValues.notional, underlyingDecimals).isZero()
          && !isProvideSuccess
          && +(formValues.swapRateMax || 0) > +(formValues.swapRateMin || 0)
        ) {
          // await loadPortfolio()
          await loadLiquidityQuote()
        } else {
          setLiquidityQuote(undefined)
        }
      } catch (e) {
        console.error(`Liquidity quote error`, e)
        setQuoteError(e as Error)
      }
    }
    loadData()
  }, [futureId, userAddress, debouncedNotional, formValues.swapRateMin, formValues.swapRateMax, formValues.operation]);

  useEffect(() => {
    const loadData = async () => {
      try {
        if(underlying && userAddress) {
          const allowanceData = await getAllowance(underlying, userAddress)
          const balanceData = await getBalanceOf(underlying, userAddress)
          setAllowance(allowanceData)
          setUnderlyingBalance(balanceData)
        }
      } catch (e) {
        console.error(`Cannot load ERC20 data:`, e)
      }
    }
    loadData()
  }, [underlying, userAddress, isApproveSuccess]);

  const {
    writeContractAsync: provideLiquidityWrite,
    isPending: isProvideInitiated,
    error: provideLiquidityError
  } = useWriteContract()

  const {
    writeContractAsync: removeLiquidity,
    isPending: isWithdrawInitiated,
    error: removeLiquidityError
  } = useWriteContract()

  const isProvideRunning = (isProvideInitiated || isWithdrawInitiated) || isProvideConfirming
  const isProvideCompleted = !isProvideRunning && !isProvideError && provideLiquidityData

  const { writeContractAsync: callApprove, isPending: isApproveInitiated } = useWriteContract()

  useEffect(() => {
    if(isProvideSuccess && provideLiquidityTxHash) {
      setTxTimestamp(Date.now())
    }
  }, [isProvideSuccess, provideLiquidityTxHash]);

  useEffect(() => {
    if(provideLiquidityTxHash && isProvideSuccess) {
      refetchPortfolio()
      setLiquidityQuote(undefined)
      onChangeFormValue({
        notional: defaultValuesState.notional,
        collateral: defaultValuesState.collateral,
      })
    }
  }, [isProvideSuccess, provideLiquidityTxHash])

  useEffect(() => {
    if(Date.now() - txTimestamp > 1000) {
      setTxError(null)
      setProvideLiquidityHash('')
    }
  }, [formValues.notional, formValues.operation])

  useEffect(() => {
    const error = provideLiquidityError || removeLiquidityError || null
    if(error || provideError) {
      console.error('Error on transaction call:', error)
    }
    setTxError(error)
  }, [provideError, provideLiquidityError, removeLiquidityError]);

  const onChangeFormValue = (newValues: Record<string, string | boolean | RiskDirection>) => {
    const nextState = {
      ...formValues,
      ...newValues
    }
    setValues(nextState)

    // if(provideLiquidityData) {
    //   setProvideLiquidityHash('')
    // }
    if(approveTxData) {
      setApproveTxHash('')
    }
    setTxError(null)
  }

  if(isLoading || !market) {
    return <Box width={'100%'} justify={'center'} align={'center'} pad={{ top: '64px' }}>
      {isLoading && <Spinner color={'spinner'} size={'small'} />}
      {!isLoading && !market && <Box>
          Market "{marketId}" not found
      </Box>}
    </Box>
  }

  const currentSwap = market.futures.find(future => future.id === futureId)
  const onOpenNextPageClicked = () => {
    navigate(`/${fromPage}`)
  }

  const onApproveClicked = async () => {
    try {
      const resultHash = await callApprove?.({
        address: (market ? market.descriptor.underlying : '0x') as `0x${string}`,
        abi: erc20MockABI as any[],
        functionName: 'approve',
        args: [config.routerContractAddress, prepareFormNumber(formValues.collateral, underlyingDecimals).toString()],
      })
      if(resultHash) {
        setApproveTxHash(resultHash)
        console.log('Approve tx hash:', resultHash)
      }
    } catch (e) {
      console.log('Error on approve:', e)
    }
  }

  const onProvideClicked = async () => {
    try {
      const oraclePackage = await getOraclePackage(marketId)
      let txResultHash: Address
      if(formValues.operation === LiquidityOperation.provide) {
        const collateralValue = formValues.isNativeTokenSelected
          ? 0
          : prepareFormNumber(formValues.collateral, underlyingDecimals)
        txResultHash = await provideLiquidityWrite({
          address: config.routerContractAddress,
          abi: RouterABI,
          functionName: 'provideLiquidity',
          args: [
            futureId,
            prepareFormNumber(formValues.notional, underlyingDecimals),
            collateralValue,
            prepareFormNumber(formValues.swapRateMin, 16),
            prepareFormNumber(formValues.swapRateMax, 16),
            (Date.now() + 5 * 60 * 1000),
            true,
            [oraclePackage]
          ],
          value: formValues.isNativeTokenSelected
            ? BigInt(prepareFormNumber(formValues.collateral, underlyingDecimals).toString())
            : undefined
        })
      } else {
        txResultHash = await removeLiquidity({
          address: config.routerContractAddress,
          abi: RouterABI,
          functionName: 'removeLiquidity',
          args: [
            futureId,
            prepareFormNumber(formValues.notional, underlyingDecimals),
            prepareFormNumber(formValues.swapRateMin, 16),
            prepareFormNumber(formValues.swapRateMax, 16),
            (Date.now() + 5 * 60 * 1000),
            true,
            [oraclePackage]
          ]
        })
      }
      if(txResultHash) {
        console.log(`Transaction hash (operation: ${formValues.operation}):`, txResultHash)
        setProvideLiquidityHash(txResultHash)
      }
    } catch (e) {
      console.error(`Error on call ${formValues.operation}:`, e)
    }
  }

  const formParams: LiquidityProvisionParams = {
    futureId,
    marketId,
    market,
    marketPortfolio: liquidityQuote ? liquidityQuote.marketPortfolio : marketPortfolio,
    currentSwap,
    liquidityQuote,
    formValues,
    underlyingBalance,
    poolLiquidityDistribution,
    makerLiquidityDistribution,
    nativeTokenBalance
  }
  const formError = getFormError(formParams)
  const formProps: LiquidityProvisionProps = {
    ...formParams,
    formError,
    onChangeFormValue
  }
  if(formError) {
    console.log('LP form error:', formError)
  }

  const allowanceDelta = prepareFormNumber(formValues.collateral, underlyingDecimals).sub(allowance)
  const isSufficientAllowance = allowanceDelta.isNegative() || allowanceDelta.isZero()
  const isProvideVisible = formValues.isNativeTokenSelected ? true : isSufficientAllowance
  const isProvideEnabled = !formError && liquidityQuote && !liquidityQuoteError

  return <Box align={'center'}>
    <WidgetContainer width={'1100px'} margin={{ top: '36px' }} style={{ position: 'relative', minWidth: '1100px' }}>
      <Header {...formProps} />
      <Box direction={'row'} margin={{ top: '22px' }} gap={'22px'}>
        <Box width={'50%'} gap={'22px'}>
          <CurrentProvision {...formProps} />
          <Margin {...formProps} />
        </Box>
        <Box width={'50%'}>
          <Request {...formProps} />
        </Box>
      </Box>
      <Box direction={'row'} justify={'end'} gap={'16px'} margin={{ top: '22px' }}>
        {liquidityQuoteError &&
            <QuoteErrorMessage
                prefix={'Quote error'}
                error={liquidityQuoteError}
            />
        }
        {txError &&
            <RouterErrorMessage
                prefix={'Request error'}
                error={txError}
            />
        }
        {isUnsupportedNetwork
          ? <Button
              style={{ padding: '10px 16px', background: '#5359C6', minWidth: '162px' }}
              onClick={() => {
                switchNetwork({
                  chainId: config.chainId
                }).catch(e => {
                  console.error('Failed to switch network', e);
                })
              }}
            >
              Switch network
            </Button>
            : (isConnected && isProvideVisible && !isProvideCompleted)
            ?
            <Button
                disabled={isProvideRunning || !isProvideEnabled}
                style={{ padding: '10px 16px', background: '#5359C6', minWidth: '162px' }}
                onClick={onProvideClicked}
            >
              {isProvideRunning &&
                  <Box width={'100%'} direction={'row'} justify={'center'} align={'center'} gap={'16px'}>
                      <Spinner size={'xsmall'} color={'white'} />
                      <Text>{isProvideInitiated ? 'Sign transaction' : 'Waiting for confirmation'}</Text>
                  </Box>
              }
              {!isProvideRunning &&
                  <Box pad={'2px 0'}>
                      <Text>{formValues.operation === LiquidityOperation.provide ? 'Provide' : 'Remove'}</Text>
                  </Box>
              }
            </Button>
            : null
        }
        {(isConnected && isProvideCompleted) &&
            <Box direction={'row'} align={'center'} gap={'32px'}>
                <Box>
                    <ExplorerLink route={`/tx/${provideLiquidityData?.transactionHash}`} />
                </Box>
                <Button
                    style={{ padding: '10px 0', background: '#1a8754', minWidth: '162px' }}
                    onClick={onOpenNextPageClicked}
                >
                    <Box pad={'2px 0'}>
                        <Text>Open {fromPage}</Text>
                    </Box>
                </Button>
            </Box>
        }
        {(isConnected && !isProvideVisible && !isProvideCompleted) &&
            <Button
                disabled={isApproveInitiated || isApproveConfirming || Boolean(formError)}
                style={{ padding: '10px 16px', background: '#5359C6', minWidth: '162px' }}
                onClick={onApproveClicked}
            >
                <Box width={'100%'} direction={'row'} justify={'center'} align={'center'} gap={'16px'}>
                  {(isApproveInitiated || isApproveConfirming) &&
                      <Spinner size={'xsmall'} color={'white'} />
                  }
                  <Box pad={'2px 0'}>
                    {(isApproveInitiated || isApproveConfirming)
                      ? <Text>Waiting for confirmation</Text>
                      : <Text>Approve</Text>
                    }
                  </Box>
                </Box>
            </Button>
        }
        {!isConnected &&
            <Button
                style={{ padding: '12px 32px' }}
                disabled={true}
            >
                <Text>Connect wallet</Text>
            </Button>
        }
      </Box>
    </WidgetContainer>
  </Box>
}
