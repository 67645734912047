import React, {useEffect, useState} from "react";
import {Box, Spinner, Text, Table, TableBody, TableCell, TableHeader, TableRow} from "grommet";
import {EarnWidget, VaultProps} from "./common";
import moment from "moment";
import {GetHistoryParams, getVaultHistory, getVaultHistoryCount, VaultHistoryItem, VaultOpType, VaultOpTypeAlias} from "../../api/vault-api";
import Decimal from "decimal.js";
import {Button, Pagination, Select} from "antd";
import {useAccount} from "wagmi";
import {Search, ShareRounded} from "grommet-icons";
import usePoller from "../../hooks/usePoller";
import useIsTabActive from "../../hooks/useIsTabActive";
import {getMapperForUSDT} from "../../mappers";
import styled from "styled-components";
import {ReactComponent as RedeemTime} from "../../assets/images/redeemTime.svg";
import {getExplorerUrl} from "../../utils";
import "./index.css";
import {useVault} from "../../providers/VaultDataProvider";
import {BigNumber} from "ethers";
const RedeemInfo = () => {
  return (
    <Box align="center" justify="center" gap="12px" width={{max: "fit-content"}} direction="row" pad={"12px 14px"} border={{size: "1px", color: "#2D2E43", style: "dotted"}} round="6px" margin={{bottom: "20px", top: "20px"}}>
      <RedeemTime width={16} />
      <Text size="15px" color="#838495">
        Your withdrawal request is being processed. Withdrawals may take up to 24 hours.
      </Text>
    </Box>
  );
};

const TableContainer = styled(Table)`
border-spacing: 0 10px; 
  border-collapse: separate; 
  thead {
    th {
      color: #838495;
      font-size: 14px;
      font-weight: 500;
      border: none;
    }
  }

  tr {
    border-top: 0 !important;
  }

  td:first-child,
  th:first-child {
    border-top: 0 !important;
    padding: 0;
  }

  th:last-child,
  td:last-child {
    padding-right: 0;
  }
`;

const Badge = (props: {title: string | React.ReactNode; color?: string; description: string; align?: string}) => {
  const {title} = props;

  return (
    <Box align={props.align || "start"} gap={"2px"}>
      {typeof title === "string" ? (
        <Text size={"14px"} color={props.color || "accentWhite"}>
          {props.title}
        </Text>
      ) : (
        title
      )}
      <Text color={props.color || "textSecondary"}>{props.description}</Text>
    </Box>
  );
};

const DefaultLimit = 8;

type VaultOpTypeUI = VaultOpType | "all";

// const TxHistoryItem = (props: VaultProps & { item: VaultHistoryItem }) => {
//   const { vault } = props
//   const { txHash, opType, blockTimestamp, assets } = props.item

//   const ts = moment(blockTimestamp)
//   const title = ts.isSame(new Date(), 'day')
//     ? ts.format('HH:mm:ss')
//     : ts.format('DD-MM-YY HH:mm:ss')
//   const action =
//     opType === 'deposit'
//       ? 'Sent'
//       : opType === 'withdrawal'
//       ? 'Received'
//       : 'Requested'
//   const sign = opType === 'deposit' ? '+' : ''
//   const color =
//     opType === 'deposit'
//       ? 'positiveValue'
//       : opType === 'withdrawal'
//       ? 'text'
//       : 'text'

//   const underlyingDecimals = vault?.underlyingDecimals || 6
//   const underlyingName = vault?.underlyingName || 'USDT'

//   const amount = (
//     <Box direction={'row'} align={'center'} gap={'6px'}>
//       <Text size={'14px'} color={color}>
//         {sign}
//         {new Decimal(assets).div(10 ** underlyingDecimals).toString()}{' '}
//         {getMapperForUSDT(underlyingName || '')}
//       </Text>
//     </Box>
//   )

//   return (
//     <TableRow
//       key={txHash}
//       items={[
//         <Badge title={VaultOpTypeAlias[opType]} description={title} />,
//         <Badge title={amount} description={action} align={'end'} />,
//       ]}
//     />
//   )
// }

export const TransactionsHistory = (props: VaultProps) => {
  const {address: userAddress} = useAccount();
  const [opType, setOpType] = useState<VaultOpTypeUI>("all");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // Status to track loading internally
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [loadingError, setLoadingError] = useState<Error>();
  const [historyCount, setHistoryCount] = useState<number>(0);
  const [history, setHistory] = useState<VaultHistoryItem[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const {vault} = useVault();

  const isTabActive = useIsTabActive();

  const pendingWithdraws = vault ? vault?.userPendingWithdrawalsAmount > BigNumber.from(0) : false;

  useEffect(() => {
    fetchHistory(1);
  }, [userAddress, opType]);

  usePoller(() => {
    if (userAddress && !isFetching && isTabActive && currentPage === 1) {
      fetchHistory(currentPage, false);
    }
  }, 5_000);

  const fetchHistory = async (page = 1, updateLoadingStatus = true) => {
    try {
      setLoadingError(undefined);
      if (!userAddress) {
        setHistoryCount(0);
        setHistory([]);
        return;
      }
      if (updateLoadingStatus) {
        setIsLoading(true);
      }
      setIsFetching(true);
      const params: GetHistoryParams = {
        userAddress,
        offset: (page - 1) * DefaultLimit,
        limit: DefaultLimit,
      };
      if (opType !== "all") {
        params.opType = opType;
      }
      // await new Promise(resolve => setTimeout(resolve, 1000))
      const [countData, items] = await Promise.all([getVaultHistoryCount(params), getVaultHistory(params)]);
      setHistoryCount(countData);
      setHistory(items);
      // console.log('Loaded vault history: ', items, 'count:', countData)
    } catch (e) {
      console.error("Failed to fetch history:", e);
      setHistoryCount(0);
      setHistory([]);
      setLoadingError(e as Error);
    } finally {
      setIsLoading(false);
      setIsFetching(false);
    }
  };

  const widgetHeight = pendingWithdraws ? `${DefaultLimit * 67}px` : `${DefaultLimit * 62}px`;

  return (
    <Box gap={"8px"}>
      <EarnWidget
        style={{
          padding: "20px 24px",
          height: widgetHeight,
          position: "relative",
        }}
      >
        <Box justify={"between"} direction={"row"} align={"center"} margin={{bottom: "15px"}}>
          <Text color={"#D6D6DC"} size={"20px"}>
            Operations history
          </Text>
          <Box width={"200px"}>
            <Select
              bordered={false}
              style={{background: "#19191A", borderRadius: "6px"}}
              value={opType}
              options={["all", "deposit", "withdrawal", "withdrawal_request"].map((item) => {
                return {
                  value: item,
                  label: VaultOpTypeAlias[item as VaultOpTypeUI],
                };
              })}
              onChange={(value) => {
                setOpType(value);
                setCurrentPage(1);
              }}
            />
          </Box>
        </Box>
        {pendingWithdraws && (
          <Box justify="center" align="center" width={"100%"}>
            <RedeemInfo />
          </Box>
        )}
        {isLoading ? (
          <Box width={"100%"} height={"100%"} align={"center"} justify={"center"} style={{position: "absolute"}}>
            <Spinner color={"spinner"} />
          </Box>
        ) : loadingError ? (
          <Box align={"center"} justify={"center"} gap={"16px"} height={"100%"}>
            <Text size={"16px"}>Failed to load transactions list</Text>
            <Button
              type={"primary"}
              onClick={() => {
                const nextPage = 1;
                setCurrentPage(nextPage);
                fetchHistory(nextPage);
              }}
            >
              Retry
            </Button>
          </Box>
        ) : history.length === 0 ? (
          <Box height={"100%"} align={"center"} justify={"center"} gap={"16px"}>
            <Search color={"text"} />
            <Text size={"20px"}>{opType === "all" ? "History is empty" : "No items found"}</Text>
          </Box>
        ) : (
          <Box>
            <Box width={"100%"}>
              <TableContainer>
                <TableHeader style={{height: "36px"}}>
                  <TableRow>
                    <TableCell scope="col" size={"1/5"}>
                      <Box direction={"row"}>
                        <Text
                          size={"16px"}
                          weight={400}
                          color={"textSecondary"}
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          Operation
                        </Text>
                      </Box>
                    </TableCell>

                    <TableCell scope="col" size={"1/5"}>
                      <Box direction={"row"}>
                        <Text
                          size={"16px"}
                          weight={400}
                          color={"textSecondary"}
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          Date
                        </Text>
                      </Box>
                    </TableCell>

                    <TableCell scope="col" size={"1/5"}>
                      <Box direction={"row"}>
                        <Text
                          size={"16px"}
                          weight={400}
                          color={"textSecondary"}
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          Size
                        </Text>
                      </Box>
                    </TableCell>

                    <TableCell scope="col" size={"1/5"}>
                      <Box direction={"row"}>
                        <Text
                          size={"16px"}
                          weight={400}
                          color={"textSecondary"}
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          Status
                        </Text>
                      </Box>
                    </TableCell>

                    <TableCell scope="col" size={"1/5"}>
                      <Box direction={"row"} width={"100%"} justify="end">
                        <Text
                          size={"16px"}
                          weight={400}
                          color={"textSecondary"}
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          Explorer
                        </Text>
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableHeader>

                <TableBody>
                  {history.map((item) => {
                    const txUrl = `${getExplorerUrl()}/tx/${item.txHash}`;
                    const opType = item?.opType;
                    const ts = moment(item.blockTimestamp);
                    // const title = ts.isSame(new Date(), 'day')
                    //   ? ts.format('HH:mm:ss')
                    //   : ts.format('DD-MM-YY HH:mm:ss')

                    const title = ts.format("DD-MM-YY HH:mm:ss");
                    const sign = opType === "deposit" ? "+" : "";
                    const color = opType === "deposit" ? "positiveValue" : opType === "withdrawal" ? "text" : "text";

                    let operationType;

                    const status = item.opType === "withdrawal_request" ? "Requested" : item?.txHash ? "Completed" : "Failed";

                    const operationTypeFn = (expr: string) => {
                      switch (expr) {
                        case "deposit":
                          operationType = "Deposit";
                          break;
                        case "withdrawal":
                          operationType = "Withdrawal";
                          break;
                        case "withdrawal_request":
                          operationType = "Withdrawal";
                          break;
                      }
                    };

                    operationTypeFn(item.opType);

                    const {vault} = props;

                    const underlyingDecimals = vault?.underlyingDecimals || 6;
                    const underlyingName = vault?.underlyingName || "USDT";

                    return (
                      <TableRow key={item.txHash}>
                        <TableCell scope="col" size={"1/5"}>
                          <Box direction={"row"}>
                            <Text
                              size={"16px"}
                              weight={400}
                              color={"#ADAEB8"}
                              style={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {operationType}
                            </Text>
                          </Box>
                        </TableCell>
                        <TableCell scope="col" size={"1/5"}>
                          <Box direction={"row"}>
                            <Text
                              size={"16px"}
                              weight={400}
                              color={"#ADAEB8"}
                              style={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {title}
                            </Text>
                          </Box>
                        </TableCell>
                        <TableCell scope="col" size={"1/5"}>
                          <Box direction={"row"}>
                            <Text size={"16px"} weight={400} color={color}>
                              {sign}
                              {new Decimal(item.assets).div(10 ** underlyingDecimals).toString()} {getMapperForUSDT(underlyingName || "")}
                            </Text>
                          </Box>
                        </TableCell>
                        <TableCell scope="col" size={"1/5"}>
                          <Box direction={"row"}>
                            <Text
                              size={"16px"}
                              weight={400}
                              color={"#ADAEB8"}
                              style={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {status}
                            </Text>
                          </Box>
                        </TableCell>
                        <TableCell scope="col" size={"1/5"}>
                          <a href={txUrl} target="_blank">
                            <Box direction={"row"} width={"100%"} justify="end" gap="5px" align="center">
                              <Text
                                size={"16px"}
                                weight={400}
                                color={"#ADAEB8"}
                                style={{
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                Tx
                              </Text>
                              <ShareRounded size={"14px"} color={"#ADAEB8"} />
                            </Box>
                          </a>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </TableContainer>
            </Box>
          </Box>
        )}
        <Box align={"center"} justify="end" height={"100%"}>
          <Pagination
            disabled={isLoading}
            className="custom-pagination"
            defaultCurrent={currentPage}
            current={currentPage}
            total={historyCount}
            onChange={(page) => {
              setCurrentPage(page);
              fetchHistory(page);
            }}
          />
        </Box>
      </EarnWidget>
    </Box>
  );
};
