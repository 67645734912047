import React, {useEffect, useRef, useState} from 'react'
import {Box, Text} from "grommet";
import {TradeProps} from "../common";
import {
  Arrow, CompactSelect, GradientButton,
  MarketIcon,
  OpTypeBadge,
  QuestionMark,
  RateBadge,
  WidgetContainer,
  Switch
} from "../../../components";
import {FutureInfo, OpType} from "../../../types";
import {formatFloatRates, useRates} from "../../../providers/FloatRateProvider";
import tooltips from "../../../utils/tooltips";
import moment from "moment";
import useOnClickOutside from "../../../hooks/useOnClickOutside";
import {useNavigate} from "react-router-dom";
import {getFutureStats} from "../../../api/dataService";
import {fromBn} from "evm-bn";
import {ViewTypeProps} from "../../../app.types";
import useInterval from "../../../hooks/useInterval";
import {useAccount} from "wagmi";
import {useCurrentRate} from "../../../hooks/useCurrentRate";
import {MarketSelect} from "../../../components/market-select";
import {useProtocolData} from "../../../providers/ProtocolDataProvider";
import {getFutureAlias} from "../../../utils";
import {setLastUsedMarketAlias} from "../../../utils/localstorage";
import {useFutureAlias} from "../../../hooks/useFutureAlias";
import {configMode} from "../../../config";
import {ShareRounded} from "grommet-icons";
import { removeUSDT } from '../../../mappers';

export const MarketTradeHeader = (props: TradeProps & ViewTypeProps) => {
  const {
    viewType = 'default',
    futureId,
    market,
    future,
    setModalId
  } = props

  const {
    currentRate: currentFutureRate,
    rateDelta
  } = useCurrentRate(futureId)

  const { futuresStats, markets, currentMarketType } = useProtocolData()
  const futureData = useFutureAlias(markets)

const isDydx = market?.descriptor.sourceName === 'dYdX'
  console.log('ab-market', props.market)
  const rates = useRates()
  const navigate = useNavigate()
  const ref = useRef<any>();
  const { isConnected } = useAccount()

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [floatRateMode, setFloatRateMode] = useState<'APR' | '8h' | '24h' | '1h'>('APR')
  const [maturityCountdown, setMaturityCountdown] = useState('')
  
  const aprArray = currentMarketType == 'funding' ? [{ id: 'APR', text: 'APR' }, { id: '8h', text: '8h' }] : [{ id: 'APR', text: 'APR' }, { id: '24h', text: '24h' }]

  const finalArray = isDydx ? [{ id: 'APR', text: 'APR' }, { id: '1h', text: '1h' }] : aprArray

  useEffect(() => {
    setModalId(isModalOpen ? 'market' : '')
  }, [isModalOpen]);



  useEffect(() => {
    if(market) {
      if(!['funding', 'staking'].includes(market.descriptor.tag)) {
        setFloatRateMode('APR')
      }
    }
  }, [market]);

  const updateMaturityCountdown = (future: FutureInfo) => {
    const { termStart, termLength } = future

    const timestamp = termStart.add(termLength).mul(1000).toNumber()
    const delta = timestamp - Date.now()

    if(delta > 1000) {
      const duration = moment.duration(delta, 'milliseconds')
      const days = Math.floor(+duration.asDays().toString())
      const hours = duration.hours()
      const minutes = duration.minutes()
      const seconds = duration.seconds()
      setMaturityCountdown(`${days}d ${hours}h ${minutes}m ${seconds}s`);
    } else {
      setMaturityCountdown('Matured')
    }
  }

  useEffect(() => {
    if(future) {
      updateMaturityCountdown(future)
    }
  }, [future]);

  useInterval(() => {
    if(future) {
      updateMaturityCountdown(future)
    }
  }, 1000)

  useOnClickOutside(ref, () => setIsModalOpen(false));

  if(!market || !future) {
    return <Box>
      Loading
    </Box>
  }

  const { descriptor: {
    id: marketId,
    sourceName,
    underlyingName,
    instrumentName,
    underlyingDecimals,
    tag
  } } = market

  const floatRate = formatFloatRates({
    rates,
    sourceName,
    tag,
    marketId
  })

  console.log('float', floatRate)

  let floatRateAprValue = 0
  let floatRateOracleScale = null
  let futureRateInOracleScale = null
  if(floatRate) {
    floatRateOracleScale = floatRate.rate
    if(tag === 'funding') {
      if (isDydx) {
        // Turn 1h funding rate into APR
        floatRateAprValue = floatRateOracleScale * 365 * 24
        // Turn annual future rate into 1h
        futureRateInOracleScale = currentFutureRate.div(365 * 24)
      } else {
        // Turn 8h funding into APR
        floatRateAprValue = floatRateOracleScale * 365 * 3
        // Turn annual future rate into 8h
        futureRateInOracleScale = currentFutureRate.div(365 * 3)
      }
    } else if (tag === 'staking') {
      floatRateAprValue = floatRateOracleScale * 365
      futureRateInOracleScale = currentFutureRate.div(365)
    }
  }

  const futureStats = futuresStats.find(item => item.futureId === futureId)
  const bpsChange = futureStats ? Math.round(futureStats.stats.rateChange / 10**16 * 100): 0

  const onMarketSelectClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const selectElements = document.querySelectorAll('.rho_compact_select')
    // @ts-ignore
    if(selectElements.length > 0 && selectElements[0].contains(e.target)){

    } else {
      setIsModalOpen(!isModalOpen)
    }
  }

  if(viewType === 'mobile') {
    return <Box>
      <MarketSelect
        viewType={viewType}
        market={futureData?.market}
        future={futureData?.future}
        setSelectedMarkets={props.setFilteredMarkets}
        selectedMarkets={props.filteredMarkets}
        onDropdownChange={(isOpened) => props.setModalId(isOpened ? 'market' : '')}
        onSelect={(market, future) => {
          if(future && market && typeof market !== 'string') {
            const marketAlias = getFutureAlias(market, future)
            setLastUsedMarketAlias(marketAlias, currentMarketType)
            navigate(`/${currentMarketType}/${marketAlias}?network=${configMode}`)
          }
        }}
      />
    </Box>
  }

  return <Box ref={ref} style={{ position: 'relative' }} key={currentMarketType}>
    <WidgetContainer
      direction={'row'}
      style={{
        padding: '0 20px 0 0',
        // padding: '16px 20px 16px 0',
        borderBottomLeftRadius: isModalOpen ? '0px' : undefined
    }}
    >
      <Box
        direction={'row'}
        justify={'between'}
      >
        <Box
          direction={'row'}
          gap={'24px'}
        >
          <MarketSelect
            currentFutureRate={currentFutureRate}
            viewType={viewType}
            market={futureData?.market}
            future={futureData?.future}
            setSelectedMarkets={props.setFilteredMarkets}
            selectedMarkets={props.filteredMarkets}
            onDropdownChange={(isOpened) => props.setModalId(isOpened ? 'market' : '')}
            onSelect={(market, future) => {
              if(future && market && typeof market !== 'string') {
                const marketAlias = getFutureAlias(market, future)
                setLastUsedMarketAlias(marketAlias, currentMarketType)
                navigate(`/${currentMarketType}/${marketAlias}?network=${configMode}`)
              }
            }}
          />
          <Box height={'calc(100% - 32px)'} alignSelf={'center'} border={{ side: 'left', size: '1px' }} />
          <Box justify={'center'}>
          <Box 
  direction="row" 
  width="190px" 
  justify="center" 
  align="center" 
  gap="small"
>
  <Box 
    align="center" 
    justify="center" 
    height="100%"
    margin={{ right: '10px'}}
  >
    <RateBadge
      id={futureId}
      //@ts-ignore
      value={+fromBn( floatRateMode === 'APR' ? currentFutureRate : futureRateInOracleScale , 16)}
      color={rateDelta.eq(0) ? undefined : rateDelta.gt(0) ? 'ratePositive' : 'rateNegative'}
      dynamicColorByDelta={false}
      fractionalDigits={floatRateMode !== '1h' ? 3 : 5}
      size="20px"
      weight={500}
      suffix="%"
    />
  </Box>

  {/* {futureRateInOracleScale && (
    <Box 
      style={{ display: floatRateMode === '8h' ? 'flex' : 'none' }} 
      align="center" 
      justify="center" 
      height="100%"
    >
      <RateBadge
        id={futureId}
        value={+fromBn(futureRateInOracleScale, 16)}
        fractionalDigits={3}
        size="18px"
        weight={500}
        suffix="%"
      />
    </Box>
  )} */}

  {floatRateOracleScale && (

    <Box background="inputBg" pad="4px" round="6px" direction='row' align='center' gap='4px'>
      <Switch
        height="19px"
        options={finalArray}
        align="center"
        textSize="12px"
        onChange={(value) => setFloatRateMode(value.id as any)}
      />
      <Box style={{marginRight: '4px'}}>
          <QuestionMark tooltipId="future_rate" tooltipText={tooltips.futureRate} />
    </Box>
    </Box>
  )}



</Box>
          </Box>
          <Box height={'calc(100% - 32px)'} alignSelf={'center'} border={{ side: 'left', size: '1px' }} />
        </Box>
      </Box>
      <Box
        direction={'row'}
        pad={{ left: '24px' }}
        align={'center'}
        gap={'20px'}
      >
      </Box>
      <Box
        direction={'row'}
        // margin={{ left: '8px' }}
        pad={{ right: '8px' }}
        align={'center'}
        gap={'28px'}
      >
        <Box>
          <RateBadge
            id={futureId}
            value={bpsChange}
            size={'15px'}
            weight={400}
            suffix={' bps'}
            prefix={+bpsChange > 0 ? '+' : ''}
            dynamicColorByDelta={false}
            dynamicColorByAbsValue={true}
          />
          <Box direction={'row'} align={'center'} gap={'4px'} margin={{ top: '4px' }}>
            <Text color={'textSecondary'} size={'13px'}>24h change</Text>
            <QuestionMark tooltipId={'24_change'} tooltipText={'24h change'} />
          </Box>
        </Box>
        {viewType === 'default' &&
            <Box>
                <RateBadge
                    id={futureId}
                    value={((futureStats?.stats.low || 0) / 10 ** 16)}
                    fractionalDigits={2} size={'15px'}
                    weight={400}
                    suffix={'%'}
                    dynamicColorByDelta={false}
                />
                <Box direction={'row'} align={'center'} gap={'4px'} margin={{ top: '4px' }}>
                    <Text color={'textSecondary'} size={'13px'}>24h low</Text>
                    <QuestionMark tooltipId={'24h_low'} tooltipText='24h low' />
                </Box>
            </Box>
        }
        {viewType === 'default' &&
            <Box>
                <RateBadge
                    id={futureId}
                    value={((futureStats?.stats.high || 0) / 10 ** 16)}
                    fractionalDigits={2}
                    size={'15px'}
                    weight={400}
                    suffix={'%'}
                    dynamicColorByDelta={false}
                />
                <Box direction={'row'} align={'center'} gap={'4px'} margin={{ top: '4px' }}>
                    <Text color={'textSecondary'} size={'13px'}>24h high</Text>
                    <QuestionMark tooltipId={'24h_high'} tooltipText='24h high' />
                </Box>
            </Box>
        }
        {viewType === 'default' &&
            <Box width={'130px'}>
                <Box>
                    <Text size={'15px'} color={'accentWhite2'}>{maturityCountdown}</Text>
                </Box>
                <Box direction={'row'} align={'center'} gap={'4px'} margin={{ top: '4px' }}>
                    <Text color={'textSecondary'} size={'13px'}>Time to maturity</Text>
                    <QuestionMark tooltipId={'mat_countdown'} tooltipText='Time to maturity' />
                </Box>
            </Box>
        }
        {viewType === 'default' &&
            <Box justify={'center'}>
                <Box direction={'row'} gap={'6px'} align={'center'} onClick={() => {
                  const marketName = `${sourceName} ${instrumentName}`.toLowerCase()
                  let url = ''

                  const futureInstrument = removeUSDT(market?.descriptor?.instrumentName || '')


                  if(marketName.includes('binance')) {
                    url = 'https://www.binance.com/en/futures/funding-history/perpetual/real-time-funding-rate'
                  } else if(marketName.includes('okx')) {
                    url = 'https://www.okx.com/trade-market/funding/swap'
                  } else if(marketName.includes('cesr')) {
                    url = 'https://www.coindesk.com/indices/ether/cesr'
                  }
                  if (isDydx) {
                    url = `https://dydx.trade/trade/${futureInstrument}-USD`
                  }
                  if(url) {
                    window.open(url, '_blank')
                  }
                }}>
                    <MarketIcon name={sourceName} size={'20px'} iconSize={'15px'} borderSize={'1px'} />
                    <Box direction={'row'} justify={'between'}>
                          <Box style={{ display: floatRateMode === 'APR' ? 'flex' : 'none' }}>
                              <RateBadge
                                  id={futureId}
                                  value={floatRateAprValue}
                                  fractionalDigits={3}
                                  size={'15px'}
                                  weight={500}
                                  suffix={'%'}
                                  dynamicColorByAbsValue={true}

                              />
                          </Box>
                        {floatRateOracleScale &&
                            <Box style={{ display: floatRateMode !== 'APR' ? 'flex' : 'none' }}>
                                <RateBadge
                                    id={futureId}
                                    value={floatRateOracleScale}
                                    fractionalDigits={5}
                                    size={'15px'}
                                    weight={500}
                                    suffix={'%'}
                                    dynamicColorByAbsValue={true}

                                />
                            </Box>
                        }
                      </Box>
                      <ShareRounded size={'16px'} color={'accentWhite'} />
                </Box>
                <Box direction={'row'} align={'center'} gap={'4px'} margin={{ top: '4px' }}>
                    <Text color={'textSecondary'} size={'13px'}>Floating rate</Text>
                    <QuestionMark tooltipId={'float_rate'} tooltipText={tooltips.floatRate} />
                </Box>
            </Box>
        }
      </Box>
      {isConnected &&
          <Box margin={{ left: 'auto' }} align={'center'} justify={'center'}>
              <GradientButton onClick={() => {
                navigate(`/${currentMarketType}/liquidity/${getFutureAlias(market, future)}?network=${configMode}`)
              }} text={'Provide liquidity'} />
          </Box>
      }
    </WidgetContainer>
  </Box>
}
