import React, {useEffect, useMemo, useState} from "react";
import {Box, Text} from "grommet";
import {WidgetContainer} from "../../../../components";
import {TradingView, TradingViewItem} from "./tradingView";
import {ChartContainerMinWidth, ChartHeight} from "./common";
import {useAccount} from "wagmi";
import moment from "moment/moment";
import {BarPrice, Time} from "lightweight-charts";
import Decimal from "decimal.js";
import {abbreviateNumber} from "../../../../utils";
import {useProtocolData} from "../../../../providers/ProtocolDataProvider";
import {Select} from "antd";
import {MenuItem} from "../deposit";
import {getVaultStats, VaultStats} from "../../../../api/vault-api";
import {useVault} from "../../../../providers/VaultDataProvider";
import {getMapperForUSDT} from "../../../../mappers";

type ChartType = "tvl" | "profit";
type ChartInterval = "1d" | "7d" | "30d";
const allChartIntervals: ChartInterval[] = ["7d", "30d"];
const chartIntervalMap: Record<string, number> = {
  "1d": 1,
  "7d": 7,
  "30d": 30,
};

export const EarnHistoryChart = () => {
  const {address: userAddress} = useAccount();
  const {markets} = useProtocolData();
  const viewType = "desktop";
  const market = markets[0];
  const {vault} = useVault();

  const [chartType, setChartType] = useState<ChartType>("profit");
  const [chartInterval, setChartInterval] = useState("all");
  const [marginPoints, setMarginPoints] = useState<VaultStats[]>([]); // Already correctly typed as array
  const [isLoading, setIsLoading] = useState(false);
  const [abortController, setAbortController] = useState<AbortController>();

  useEffect(() => {
    const loadChartData = async () => {
      try {
        // setIsLoading(true)
        const controller = new AbortController();

        if (abortController) {
          abortController.abort();
        }
        setAbortController(controller);

        // const amountOfDays = chartIntervalMap['30d']
        // const fromDate = moment()
        // .subtract(amountOfDays, 'days')
        // .format('YYYY-MM-DD');

        const fromDate = moment("2024-08-01").format("YYYY-MM-DD");

        const params = {
          fromDate: fromDate,
          limit: 1000,
        };
        const points: VaultStats[] = await getVaultStats(params);
        setMarginPoints(points); // Setting array of VaultStats
        console.log("Vault stats loaded:", points);
      } catch (e) {
        console.error("Failed to load chart data:", e);
        setMarginPoints([]); // Set empty array on error
      }
    };
    loadChartData();
  }, [vault, chartType, chartInterval]);

  const chartLineItems: TradingViewItem[] = useMemo(() => {
    if (marginPoints.length === 0) {
      return new Array(30)
        .fill(null)
        .map((_, index) => {
          const day = moment().subtract(index, "day");
          const time = day.unix() as Time;
          return {
            value: 0,
            time,
          };
        })
        .reverse();
    }
    return marginPoints.map((item) => {
      let value = 0;

      if (vault) {
        const decimals = new Decimal(10).pow(vault.underlyingDecimals); // 10^6

        if (chartType === "tvl") {
          value = Number(new Decimal(item.totalValueLocked).div(decimals));
        } else if (chartType === "profit") {
          value = Number(item.profitPercentage);
        }
      }

      const time = moment(item.timestamp).unix() as Time;
      return {
        value,
        time,
      };
    });
  }, [chartType, chartInterval, userAddress, marginPoints, vault]);

  const mappedTimes = {
    all: "All time",
    "7d": "Last week",
    "30d": "Last 30 days",
  };

  return (
    <WidgetContainer
      style={{
        minWidth: ChartContainerMinWidth,
      }}
    >
      <Box justify={"between"} height={"100%"}>
        <Box direction={"row"} justify={"between"}>
          <Box direction="row" justify="between" width={"100%"} margin={{bottom: "14px"}}>
            <Box direction="row" gap="36px">
              <Text size={"22px"} weight={500} color={"textHeader"}>
                Performance history
              </Text>
              <Box direction="row" gap="8px" margin={{bottom: "17px"}}>
                <MenuItem item="Vault P&L" value="profit" active={chartType === "profit"} setActive={setChartType} />
                <MenuItem item="Vault TVL" value="tvl" active={chartType === "tvl"} setActive={setChartType} />
              </Box>
            </Box>

            {/* <Select
              bordered={false}
              style={{ background: '#19191A', borderRadius: '6px' }}
              value={chartInterval}
              options={['all', '7d', '30d'].map((item) => ({
                value: item,
                label: mappedTimes[item as keyof typeof mappedTimes],
              }))}
              onChange={(value) => {
                setChartInterval(value)
              }}
            /> */}
          </Box>
        </Box>
        <Box style={{position: "relative"}}>
          <Box
            style={
              isLoading
                ? {
                    opacity: 0.4,
                    filter: "blur(1px)",
                    pointerEvents: "none",
                  }
                : {}
            }
          >
            <TradingView
              height={ChartHeight}
              lineItems={chartLineItems}
              priceFormatter={(value: BarPrice) => {
                return abbreviateNumber(value, 2);
              }}
              tooltipFormatter={(tooltip) => {
                const prefix = chartType === "profit" ? "" : "$";
                const suffix = chartType === "profit" ? "%" : ` ${getMapperForUSDT(market?.descriptor.underlyingName)}`;
                return {
                  ...tooltip,
                  title: chartType === "tvl" ? "Total Value Locked" : "Profit Percentage",
                  value: `${prefix}${abbreviateNumber(tooltip.value, market?.descriptor?.underlyingName === "WETH" ? 5 : 2)}${suffix}`,
                };
              }}
            />
          </Box>
          {isLoading && (
            <Box width={"100%"} height={"260px"} justify={"center"} align={"center"} style={{position: "absolute"}}>
              <Box pad={"12px"}>
                <Text size={"16px"} color={"accentWhite"}>
                  Loading
                </Text>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </WidgetContainer>
  );
};
