import React from 'react'
import {Anchor, Box, Table, TableBody, TableCell, TableHeader, TableRow, Text} from "grommet";
import {MarketInfo} from "../../types";
import styled from "styled-components";
import {Number, CoinName, getIconByName} from "../../components";
import {BigNumber} from "ethers";
import moment from "moment";
import {getExplorerUrl} from "../../utils";
import {fromBn} from "evm-bn";
import bn from "bignumber.js";
import { Trade } from '../../api/dataService'
import { getMapperForUSDT } from '../../mappers';

const TableContainer = styled(Table)`
  thead {
    th {
      color: #858CAD;
      font-weight: 600;
      border: none;
    }
  }

  border-collapse: separate;
  border-spacing: 0;

  tbody {
    tr:nth-child(2n + 1) {
      background-color: #191D28;
      border-radius: 10px;
    }
  }
`

export interface TradeEventsProps {
  userAddress?: string
  markets: MarketInfo[]
  events: Trade[]
}

interface TradeEventRowProps {
  event: Trade;
  market?: MarketInfo;
}

const TradeEventRow = (props: TradeEventRowProps) => {
  const { market, event  } = props

  const underlyingDecimals = market?.descriptor.underlyingDecimals || 6
  const underlyingName = market?.descriptor.underlyingName ? getMapperForUSDT(market?.descriptor.underlyingName) : ''
  const blockTimestampMoment = moment(+event.timestamp * 1000)
  const txUrl = `${getExplorerUrl()}/tx/${event.txHash}`
  const feesSign = props.event.ownerIsMaker ? '+' : '-'

  return <TableRow key={event.txHash}>
    <TableCell>{event.ownerIsMaker ? 'Trade - LP' : 'Trade'}</TableCell>
    <TableCell>{event.market}</TableCell>
    <TableCell>
      <Box>
        <Text>
          {(moment(event.maturity * 1000).format('DD/MM/YYYY'))}
        </Text>
      </Box>
    </TableCell>
    <TableCell>{event.direction === 0 ? 'Receiver' : 'Payer'}</TableCell>
    <TableCell>
      <Box direction={'row'} gap={'6px'}>
        <Number
          value={BigNumber.from(event.notionalAmount)}
          decimals={underlyingDecimals}
          name={underlyingName}
        />
        <CoinName name={underlyingName} />
      </Box>
    </TableCell>
    <TableCell>
      <Text>
        {bn(fromBn(BigNumber.from(event.rate), 16)).toFormat(3, 1)}%
      </Text>
    </TableCell>
    <TableCell>
      <Box direction={'row'} gap={'1px'} align={'center'}>
        {feesSign}
        <Number
          value={BigNumber.from(event.feeAmount)}
          decimals={underlyingDecimals}
          name={underlyingName}
          showName={true}
        />
      </Box>
    </TableCell>
    <TableCell width={'60px'}>
      <Box>
        <Box>
          <Text>{blockTimestampMoment.format('HH:mm:ss')}</Text>
        </Box>
        <Box>
          <Text style={{ opacity: 0.4 }}>
            {blockTimestampMoment.format('DD/MM/YYYY')}
          </Text>
        </Box>
      </Box>
    </TableCell>
    <TableCell width={'32px'}>
      <Anchor href={txUrl} target={'_blank'}>
        <Box>
          {getIconByName('external').icon}
        </Box>
      </Anchor>
    </TableCell>
  </TableRow>
}

export const TradeEvents = (props: TradeEventsProps) => {
  return <TableContainer>
    <TableHeader style={{ height: '35px' }}>
      <TableRow>
        <TableCell scope="col">
          <Box direction={'row'} align={'center'} gap={'2px'}>
            <Text size={'12px'} color={'textSecondary'}>EVENT</Text>
          </Box>
        </TableCell>
        <TableCell scope="col">
          <Box direction={'row'} align={'center'} gap={'2px'}>
            <Text size={'12px'} color={'textSecondary'}>MARKET</Text>
          </Box>
        </TableCell>
        <TableCell scope="col">
          <Box direction={'row'} align={'center'} gap={'2px'}>
            <Text size={'12px'} color={'textSecondary'}>MATURITY</Text>
          </Box>
        </TableCell>
        <TableCell scope="col">
          <Box direction={'row'} align={'center'} gap={'2px'}>
            <Text size={'12px'} color={'textSecondary'}>DIRECTION</Text>
          </Box>
        </TableCell>
        <TableCell scope="col">
          <Box direction={'row'} align={'center'} gap={'2px'}>
            <Text size={'12px'} color={'textSecondary'}>NOTIONAL</Text>
          </Box>
        </TableCell>
        <TableCell scope="col">
          <Box direction={'row'} align={'center'} gap={'2px'}>
            <Text size={'12px'} color={'textSecondary'}>RATE</Text>
          </Box>
        </TableCell>
        <TableCell scope="col">
          <Box direction={'row'} align={'center'} gap={'2px'}>
            <Text size={'12px'} color={'textSecondary'}>FEE</Text>
          </Box>
        </TableCell>
        <TableCell scope="col">
          <Box direction={'row'} align={'center'} gap={'2px'}>
            <Text size={'12px'} color={'textSecondary'}>DATE</Text>
          </Box>
        </TableCell>
        <TableCell scope="col">
          <Box direction={'row'} align={'center'} gap={'2px'}>
            <Text size={'12px'} color={'textSecondary'}>EXPLORER</Text>
          </Box>
        </TableCell>
      </TableRow>
    </TableHeader>
    <TableBody>
      {props.events.map((event, index) => {
        const market = props.markets.find(market => market.descriptor.id === event.marketId)
        return <TradeEventRow key={event.txHash} market={market} event={event} />
      })}
    </TableBody>
  </TableContainer>
}
