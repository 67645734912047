import React from "react";
import {Grommet, Box, Text, Stack} from "grommet";
import {Book} from "grommet-icons";
import {ReactComponent as BookIcon} from "../../../assets/images/book.svg";

const theme = {
  global: {
    colors: {
      brand: "#228BE6",
    },
    font: {
      family: "Arial",
      size: "18px",
      height: "20px",
    },
  },
};

export const LearnMoreComponent = () => {
  return (
    <a href="https://docs.rho.trading/how-to-trade-on-rho/new-user-guide-start-here" target="_blank" style={{cursor: "pointer", width: "auto", marginBottom: "14px"}}>
      <Box
        align="center"
        justify="center"
        height="40px"
        background="#232325"
        width="100%"
        round="6px"
        pad="12px"
        style={{
          border: "1px solid #2D2E43",
          cursor: "pointer",
          transition: "background-color 0.2s ease-in-out",
        }}
        onMouseEnter={(e) => (e.currentTarget.style.background = "#2e2e38")}
        onMouseLeave={(e) => (e.currentTarget.style.background = "#232325")}
      >
        <Box direction="row" gap="10px" align="center" justify="center" width="auto">
          <BookIcon />
          <Box gap="2px">
            <Text size="13px" color="#838495">
              Learn more about interest rate trading here
            </Text>
            <Box
              width="auto"
              height="0.5px"
              border={{
                color: "#838495",
                style: "dashed",
              }}
            />
          </Box>
        </Box>
      </Box>
    </a>
  );
};
