import { useEffect, useMemo, useState, useRef } from 'react';
import {
  getActiveMarketsInfo,
  getMarketsOraclePackages,
  getPortfolio,
} from "../api/viewContract";
import { MarketInfo, MarketPortfolio } from "../types";
import { marginTotal } from "../utils/mappers";
import { useTokenPrice } from "../providers/PriceProvider";
import { bnToDecimal } from "../utils";
import { useProtocolData } from '../providers/ProtocolDataProvider';
import { useUserPositions } from './useUserPositions';
import { useMarketsUserData } from './useMarketsUserData';
import { useMarketsWithdrawableMargin } from './useWithdrawableMargin';
import pLimit from 'p-limit';

const usePortfolio = (params: { userAddress?: string; refetch?: () => void }) => {
  const { userAddress } = params;
  const { ethereum: ethPrice, tetherPrice } = useTokenPrice();

  const { refetchPortfolio } = useProtocolData()
  const { refetch: refetchPositions } = useUserPositions(userAddress)



  const [isInitialFetching, setInitialFetching] = useState(true);
  const [isFetched, setFetched] = useState(false);
  const [isFetching, setFetching] = useState(false);
  const [forcedFetch, setForcedFetch] = useState(false)
  const [portfolio, setPortfolio] = useState<MarketPortfolio[]>([]);
  const [markets, setMarkets] = useState<MarketInfo[]>([]);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const canFetchRef = useRef(true); // Track if fetching is allowed

  
  const {
     refetch: refetchMarkets
  } = useMarketsWithdrawableMargin(markets.map(item => item.descriptor.id))

 

  const limit = pLimit(1); // Allow only 1 request at a time
  
  const loadData = async () => {
    if (!canFetchRef.current && !forcedFetch) return; 
  
    await limit(async () => { // Queue the API call
      try {
        setFetching(true);
  
        const marketItems = await getActiveMarketsInfo();
        setMarkets(marketItems);
  
        if (userAddress) {
          const oraclePackages = await getMarketsOraclePackages();
          const portfolioItems = await getPortfolio(userAddress, oraclePackages);
          setPortfolio(portfolioItems);
        } else {
          setPortfolio([]);
        }
      } catch (e) {
        console.error("usePortfolio: failed to load data: ", e);
      } finally {
        setFetching(false);
        setFetched(true);
        setInitialFetching(false);
  
        // Disable fetching for 1 second
        canFetchRef.current = false;
        setTimeout(() => {
          canFetchRef.current = true;
        }, 1000);
  
        setTimeout(() => {
          setForcedFetch(false);
        }, 3000);
      }
    });
  };
  
  

  useEffect(() => {
    const ethereum = (window as any).ethereum;

    if (!ethereum?.on) {
      console.error('Ethereum provider not available');
      return;
    }

    const handleAccountsChanged = async () => {
      // Reset all states
      setPortfolio([]);
      setMarkets([]);
      setFetched(false);
      setForcedFetch(true)
      
      // Clear any existing timeout
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      
      // Trigger immediate data refresh
      loadData();
      refetchMarkets()
      refetchPortfolio();
      refetchPositions();
    };

    ethereum.on('accountsChanged', handleAccountsChanged);

    return () => {
      ethereum.removeListener('accountsChanged', handleAccountsChanged);
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [userAddress]);

  useEffect(() => {
    setInitialFetching(true);
    loadData();
  }, [userAddress]); // Do NOT clear timeout on userAddress change

  return useMemo(() => {
    let accountTotalMarginUSD = 0;
    if (userAddress) {
      accountTotalMarginUSD = portfolio.reduce((acc, portfolioItem) => {
        const { descriptor: { underlyingName: name, underlyingDecimals } } = portfolioItem;
        const margin = marginTotal(portfolioItem.marginState.margin);

        let tokenPrice = 0;
        if (ethPrice && name.toLowerCase().endsWith("eth")) {
          tokenPrice = ethPrice;
        } else if (tetherPrice && name.toLowerCase().includes("usd")) {
          tokenPrice = tetherPrice;
        }
        return acc + tokenPrice * bnToDecimal(margin, underlyingDecimals).toNumber();
      }, 0);
    }

    return {
      isInitialFetching,
      isFetching,
      isFetched,
      markets,
      portfolio,
      accountTotalMarginUSD,
      refetch: loadData,
      setForcedFetch
    }
  }, [userAddress, portfolio, isFetching])
};

export default usePortfolio;