import {Box, CheckBox, Text} from "grommet";
import React from "react";
import {MenuItemId} from "./common";
import {Arrow, FilterButton} from "../../../components";
import {Button} from "antd";
import styled from "styled-components";
import {BottomMenuFilterMode, PanelVisibilityStatus} from "./index";
import { useProtocolData } from "../../../providers/ProtocolDataProvider";
import {Checkbox, MenuProps, Skeleton} from 'antd';

const MenuItemText = styled(Text)`
    transition: color 250ms;

    &:hover {
        color: ${props => props.theme.global.colors.accentWhite2}
    }
`

export const CustomCheckbox = styled(Checkbox)`
  .ant-checkbox-inner {
    border-color: #393946 !important; /* Default border color */
  }

  &:hover .ant-checkbox-inner {
    border-color: #838495 !important; /* Border color on hover */
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #7A85B8 !important; /* Change fill */
    border-color: #393946 !important;
  }
`;

const MenuItem = (props: {
  id: MenuItemId,
  text: string,
  isActive?: boolean,
  onClick: () => void
}) => {


  const { text, isActive = false } = props
  const color = isActive ? 'accentWhite2' : 'textSecondary'

  return <Box onClick={() => props.onClick()}>
    <MenuItemText size={'18px'} weight={500} color={color}>
      {props.text}
    </MenuItemText>
  </Box>
}



export const PanelMenu = (props: {
  visibilityStatus: PanelVisibilityStatus
  activeItem?: MenuItemId,
  onSelect: (id: MenuItemId) => void
  onChangeVisibilityStatus: (status: PanelVisibilityStatus) => void
  filterMode: BottomMenuFilterMode
  setFilterMode: (mode: BottomMenuFilterMode) => void
}) => {
  const { visibilityStatus , activeItem, onSelect } = props

  let isAllActive = props.filterMode === 'all'
  let isMarketActive = props.filterMode === 'market'
  let isInstrumentActive = props.filterMode === 'instrument'

  if(activeItem === 'marginManagement') {
    isAllActive = false
    isMarketActive = true
    isInstrumentActive = false
  }

  const { userPositions } = useProtocolData()

  const openPositions = userPositions
  .filter(item => {
    const { openPosition } = item
    const isOpenPosition = openPosition && openPosition.notional !== '0'
    return isOpenPosition 
  })
  .map(item => item.openPosition)

  const menuConfig = [{
    id: MenuItemId.openPositions,
    text: `Open positions (${openPositions.length})`
  }, {
    id: MenuItemId.tradeHistory,
    text: 'Trade history'
  }, {
    id: MenuItemId.marginManagement,
    text: 'Margin management'
  }]

  const handleCheck = () => {
    if (props.filterMode == 'all') {
      props.setFilterMode('instrument')
    } else {
      props.setFilterMode('all')
    }
  }


  return <Box
    direction={'row'}
    justify={'between'}
    margin={{ left: '16px' }}
    pad={visibilityStatus !== 'closed' ? '0 0 8px' : 'unset'}
  >
    <Box direction={'row'} gap={'16px'}>
      {menuConfig.map((item) => {
        const { id, text } = item
        const isActive = activeItem === id && visibilityStatus !== 'closed'
        return <MenuItem
          key={id}
          id={id}
          text={text}
          isActive={isActive}
          onClick={() => onSelect(id)}
        />
      })}
    </Box>
    <Box
      direction={'row'}
      gap={'16px'}
    >
      <Box direction={'row'} gap={'8px'}>
        <FilterButton
          id={'all'}
          text={'All'}
          isActive={isAllActive}
          isDisabled={activeItem === 'marginManagement'}
          onClick={() => props.setFilterMode('all')}
        />
        <FilterButton
          id={'market'}
          text={'Market'}
          isActive={isMarketActive}
          isDisabled={false}
          onClick={activeItem !== 'marginManagement' ? () => props.setFilterMode('market') : () => {}}
        />
        <FilterButton
          id={'instrument'}
          text={'Instrument'}
          isActive={isInstrumentActive}
          isDisabled={activeItem === 'marginManagement'}
          onClick={() => props.setFilterMode('instrument')}
        />
      </Box>

      {/* <Box>
       <CustomCheckbox
         checked={props.filterMode != 'all'}
         onChange={() => handleCheck()}>
          <Text color={'textSecondary'}>Hide other symbols</Text>
        </CustomCheckbox>
      </Box> */}
      {/* <Box align={'center'}>
        <Button
          type={'text'}
          onClick={() => props.onChangeVisibilityStatus(visibilityStatus === 'closed' ? 'opened-default' : 'closed')}
          style={{ minHeight: '24px' }}
        >
          <Arrow direction={visibilityStatus !== 'closed' ? 'down' : 'up'} />
        </Button>
      </Box> */}

    </Box>
  </Box>
}
