import React, { useEffect, useState, useMemo, useCallback } from 'react'
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  Text,
} from 'grommet'
import styled from 'styled-components'
import {
  IRateMathType,
  RiskDirection,
  RiskDirectionType,
} from '../../../../types'
import {
  DynamicValue,
  MaturityBadge,
  QuestionMark,
  RightArrow,
  ValuesChangeBadge,
} from '../../../../components'
import tooltips from '../../../../utils/tooltips'
import { marginTotal } from '../../../../utils/mappers'
import { BigNumber } from 'ethers'
import { TradeProps } from '../../common'
import {
  bnToDecimal,
  getFutureAlias,
  numberToBN,
  prepareFormNumber,
} from '../../../../utils'
import { useAccount } from 'wagmi'
import { useNavigate } from 'react-router-dom'
import { RiskDirectionTag } from '../../../../components/tag'
import { CompoundingRateMath, LinearRateMath } from '../../../../utils/leverage'
import { useProtocolData } from '../../../../providers/ProtocolDataProvider'
import { Skeleton } from 'antd'
import { OpenPosition, UserPositions } from '../../../../api/dataService'
import { toBn } from 'evm-bn'
import { configMode } from '../../../../config'
import { useActiveModal } from '../../../../providers/ModalsProvider'
import { PageModal } from '../../../../constants'
import { usePositionState } from '../../../../providers/PositionStateProvider'
import { Spinner } from 'grommet'
import { formatDate, removeUSDT } from '../../../../mappers'
import moment from 'moment'
import { RhoSpinner } from '../../../../components/Spinner'

const TableContainer = styled(Table)`
  thead {
    th {
      color: #838495;
      font-size: 14px;
      font-weight: 500;
      border: none;
    }
  }

  tr {
    border-top: 0 !important;
  }

  td:first-child,
  th:first-child {
    border-top: 0 !important;
    padding: 0;
  }

  td {
    // border-top: 1px solid
    //   ${(props) => props.theme.global.colors.tableRowBorder}!important;
  }

  th:last-child,
  td:last-child {
    padding-right: 0;
  }
`

const UnwindButton = styled(Box)`
  width: 55px;
  height: 25px;
  border-radius: 6px;
  background: #2e2e38;
`

const TableMarkerCell = styled(TableCell)<{ isActive: boolean }>`
  padding: 0 !important;
  ${(props) =>
    props.isActive &&
    `
        border-left: 3px solid ${props.theme.global.colors.brandRho};
      `}
`

export const OpenPositionsList = (
  props: TradeProps & {
    openPositions: OpenPosition[]
  }
) => {
  const { openPositions, formValues, tradeQuote } = props

  const { setActiveModal } = useActiveModal()
  const { positionState, setPositionField } = usePositionState()

  const [userStatePositions, setUserStatePositions] =
    useState<OpenPosition[]>(openPositions)

  const navigate = useNavigate()
  const { isConnected } = useAccount()
  const {
    portfolio,
    userPositions,
    markets,
    refetchUserPositions,
    currentMarketType,
  } = useProtocolData()

  const futures = useMemo(() => markets.flatMap((item) => item.futures), [markets])

  const handleUnwindPosition = useCallback((position: OpenPosition, modalProps: any) => {
    setActiveModal(PageModal.unwind, { position, modalProps, props })
    setPositionField('positionId', position.futureId)
  }, [props, setActiveModal, setPositionField])

  const sortPositions = useCallback((positions: OpenPosition[]) => {
    if (!positions.length || !markets.length || !futures.length) return positions;
    
    const sorted = [...positions].sort((a, b) => {
      const futureA = futures.find((item) => item.id === a.futureId)
      const futureB = futures.find((item) => item.id === b.futureId)
      const marketA = markets.find((item) => item.descriptor.id === futureA?.marketId)
      const marketB = markets.find((item) => item.descriptor.id === futureB?.marketId)
      
      // Compare exchange names first
      const sourceCompare = (marketA?.descriptor.sourceName || '').localeCompare(marketB?.descriptor.sourceName || '')
      if (sourceCompare !== 0) return sourceCompare
      
      // Then compare instrument names
      const instrumentA = marketA?.descriptor.instrumentName.split(' ')[0] || ''
      const instrumentB = marketB?.descriptor.instrumentName.split(' ')[0] || ''
      const instrumentCompare = instrumentA.localeCompare(instrumentB)
      if (instrumentCompare !== 0) return instrumentCompare
      
      // If same underlying, sort by maturity
      const maturityA = futureA ? (+futureA.termStart.toString() + +futureA.termLength.toString()) : 0
      const maturityB = futureB ? (+futureB.termStart.toString() + +futureB.termLength.toString()) : 0
      return maturityA - maturityB
    })

    // Move current future to the top if it exists
    if (props.future) {
      const currentIndex = sorted.findIndex(pos => pos.futureId === props.future?.id)
      if (currentIndex > -1) {
        const currentPosition = sorted.splice(currentIndex, 1)[0]
        sorted.unshift(currentPosition)
      }
    }

    return sorted;
  }, [markets, futures, props.future])

  useEffect(() => {
    const sorted = sortPositions(openPositions)
    if (JSON.stringify(sorted) !== JSON.stringify(userStatePositions)) {
      setUserStatePositions(sorted)
    }
  }, [openPositions, markets, futures, props.future, sortPositions, userStatePositions])

  const tableSize = '1/8'

  return (
    <Box width={'100%'}>
      <TableContainer>
        <TableHeader style={{ height: '36px' }}>
          <TableRow>
            <TableCell size={tableSize} scope="col" />
            <TableCell scope="col">
              <Box direction={'row'} align={'center'} gap={'2px'}>
                <Text
                  size={'12px'}
                  color={'textSecondary'}
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  MARKET
                </Text>
                <QuestionMark
                  tooltipId={'open_market'}
                  tooltipText={'Market name'}
                />
              </Box>
            </TableCell>
            <TableCell scope="col" size={tableSize}>
              <Box direction={'row'} align={'center'} gap={'2px'}>
                <Text
                  size={'12px'}
                  color={'textSecondary'}
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  MATURITY
                </Text>
                <QuestionMark
                  tooltipId={'open_maturity'}
                  tooltipText={tooltips.maturity}
                />
              </Box>
            </TableCell>
            <TableCell scope="col" size={tableSize}>
              <Box direction={'row'} align={'center'} gap={'2px'}>
                <Text
                  size={'12px'}
                  color={'textSecondary'}
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  DIRECTION
                </Text>
                <QuestionMark
                  tooltipId={'open_rd'}
                  tooltipText={tooltips?.riskDirection}
                />
              </Box>
            </TableCell>
            <TableCell scope="col" size={tableSize}>
              <Box direction={'row'} align={'center'} gap={'2px'}>
                <Text
                  size={'12px'}
                  color={'textSecondary'}
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  NOTIONAL
                </Text>
                <QuestionMark
                  tooltipId={'open_notional'}
                  tooltipText={tooltips.notional}
                />
              </Box>
            </TableCell>
            <TableCell scope="col" size={tableSize}>
              <Box direction={'row'} align={'center'} gap={'2px'}>
                <Text
                  size={'12px'}
                  color={'textSecondary'}
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  LEVERAGE
                </Text>
                <QuestionMark
                  tooltipId={'open_leverage'}
                  tooltipText={tooltips.futureLeverage}
                />
              </Box>
            </TableCell>
            <TableCell scope="col" size={tableSize}>
              <Box direction={'row'} align={'center'} gap={'2px'}>
                <Text
                  size={'12px'}
                  color={'textSecondary'}
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  1% Value (DV100)
                </Text>
                <QuestionMark
                  tooltipId={'open_dv01'}
                  tooltipText={tooltips.dv01}
                />
              </Box>
            </TableCell>
            <TableCell scope="col" size={tableSize}>
              <Box direction={'row'} align={'center'} gap={'2px'}>
                <Text
                  size={'12px'}
                  color={'textSecondary'}
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  P&L
                </Text>
                <QuestionMark
                  tooltipId={'open_pl'}
                  tooltipText={tooltips.profitAndLoss}
                />
              </Box>
            </TableCell>
            <TableCell scope="col" size={tableSize}>
              <Box direction={'row'} align={'center'} gap={'2px'} width={'100%'} justify='end'>
                <Text
                  size={'12px'}
                  color={'textSecondary'}
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  Action
                </Text>
                <QuestionMark
                  tooltipId={'open_unwind'}
                  tooltipText={tooltips.unwindOrder}
                />
              </Box>
            </TableCell>
          </TableRow>
        </TableHeader>
        <TableBody>
          {userStatePositions.map((item, index) => {
            const { futureId, direction: riskDirection } = item

            const future = futures.find((item) => item.id === futureId)
            const market = markets.find(
              (item) => item.descriptor.id === future?.marketId
            )
            const portfolioItem = portfolio.find(
              (item) => item.descriptor.id === future?.marketId
            )
            const marketName = market
              ? `${market?.descriptor.sourceName} ${
                  market?.descriptor.instrumentName.split(' ')[0]
                }`
              : ''
            const maturityTimestamp = future
              ? (+future.termStart.toString() + +future.termLength.toString()) *
                1000
              : 0
            const isActive = item.futureId === props.futureId
            const zero = BigNumber.from(0)
            const underlyingDecimals =
              market?.descriptor.underlyingDecimals || 6

            const notional = BigNumber.from(item.notional)
            // const dv01 = toBn(item.dv01.toString(), underlyingDecimals)
            let dv100: BigNumber

            dv100 = toBn('0', underlyingDecimals)

            if (market && future) {
              const rateMath =
                market?.descriptor?.rateMathType === IRateMathType.LINEAR
                  ? new LinearRateMath()
                  : new CompoundingRateMath()

              const dv100decimals = rateMath
                .getDv100(
                  bnToDecimal(notional, underlyingDecimals),
                  bnToDecimal(future.vAMMParams.currentFutureRate, 18),
                  future.termStart
                    .add(future.termLength)
                    .sub(Math.round(Date.now() / 1000))
                    .toNumber()
                )
                .abs()

              dv100 = prepareFormNumber(
                dv100decimals.toString(),
                underlyingDecimals
              )
            }

            const profitAndLoss = numberToBN(
              item.position.profitAndLoss,
              underlyingDecimals
            )

            let nextRiskDirection: null | RiskDirectionType = riskDirection
            let nextNotional: null | BigNumber = null
            let nextDv100 = null

            const marginFrom = portfolioItem
              ? marginTotal(portfolioItem.marginState.margin)
              : zero
            let leverageTo: number | null = null

            const underlyingName = market?.descriptor.underlyingName || 'N/A'

            if (isActive) {
              const userPosition = userPositions.find(
                (item) => item.futureId === futureId
              )
              if (userPosition?.openPosition.notional) {
                const { openPosition } = userPosition

                let notional = BigNumber.from(openPosition.notional)
                if (openPosition.direction === RiskDirectionType.RECEIVER) {
                  notional = notional.mul(-1)
                }
                let formNotional = prepareFormNumber(
                  formValues.notional,
                  underlyingDecimals
                )
                if (formValues?.riskDirection === RiskDirection.receiver) {
                  formNotional = formNotional.mul(-1)
                }
                const resultNotional = formNotional.add(notional)
                nextRiskDirection = resultNotional.eq(0)
                  ? null
                  : resultNotional.lt(0)
                  ? RiskDirectionType.RECEIVER
                  : RiskDirectionType.PAYER
                nextNotional = resultNotional.abs()

                if (tradeQuote) {
                  leverageTo = +Math.abs(props.leverage).toFixed(1)
                }

                if (market && future) {
                  const rateMath =
                    market.descriptor.rateMathType === IRateMathType.LINEAR
                      ? new LinearRateMath()
                      : new CompoundingRateMath()

                  const dv100decimals = rateMath
                    .getDv100(
                      bnToDecimal(notional, underlyingDecimals),
                      bnToDecimal(future.vAMMParams.currentFutureRate, 18),
                      future.termStart
                        .add(future.termLength)
                        .sub(Math.round(Date.now() / 1000))
                        .toNumber()
                    )
                    .abs()

                  const nextdv100decimal = rateMath
                    .getDv100(
                      bnToDecimal(resultNotional, underlyingDecimals),
                      bnToDecimal(future.vAMMParams.currentFutureRate, 18),
                      future.termStart
                        .add(future.termLength)
                        .sub(Math.round(Date.now() / 1000))
                        .toNumber()
                    )
                    .abs()

                  // const nextDv01Decimal = rateMath.getDv01(
                  //   bnToDecimal(resultNotional, underlyingDecimals),
                  //   bnToDecimal(future.vAMMParams.currentFutureRate, 18),
                  //   future.termStart.add(future.termLength).sub(Math.round(Date.now()/1000)).toNumber()
                  // ).abs()
                  // nextDv01 = prepareFormNumber(nextDv01Decimal.toString(), underlyingDecimals)
                  nextDv100 = prepareFormNumber(
                    nextdv100decimal.toString(),
                    underlyingDecimals
                  )
                }
              }
            }

            let leverageFrom = marginFrom.gt(0)
              ? +notional.mul(100).div(marginFrom).toString()
              : 1
            leverageFrom = +(leverageFrom / 100).toFixed(1)

            const rowFutureAlias =
              market && future ? getFutureAlias(market, future) : ''
            const currentFutureAlias =
              props.market && props.future
                ? getFutureAlias(props.market, props.future)
                : ''

            const onSelectFuture = () => {
              if (rowFutureAlias !== currentFutureAlias) {
                navigate(
                  `/${currentMarketType}/${rowFutureAlias}?network=${configMode}`
                )
              }
            }

            const modalProps = {
              marketName,
              notional,
              riskDirection,
              underlyingDecimals,
              underlyingName,
              marketId: future?.marketId,
            }

            const isProcessing =
              positionState?.positionId === item.futureId &&
              positionState.positionState == 'loading'

            const sourceName = market?.descriptor?.sourceName
            const instrumentNameMarket = market?.descriptor?.instrumentName
            const instrumentName: string = removeUSDT(
              instrumentNameMarket || ''
            )

            let futureDate: string = ''

            if (future) {
              futureDate = formatDate(
                moment(
                  future.termStart.add(future.termLength).toNumber() * 1000
                ).format('DD-MM-YYYY')
              )
            }

            return (
              <TableRow
                key={item.futureId + index + props?.future}
                style={{
                  borderTop: '1px solid #383D57',
                  cursor:
                    rowFutureAlias !== currentFutureAlias
                      ? 'pointer'
                      : 'default',
                }}
                onClick={
                  rowFutureAlias !== currentFutureAlias
                    ? onSelectFuture
                    : undefined
                }
              >
                <TableMarkerCell size={'16px'} isActive={isActive} />
                <TableCell size={tableSize}>
                  <Box>
                    <Text
                      style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {sourceName} {instrumentName} {futureDate}
                    </Text>
                  </Box>
                </TableCell>
                <TableCell size={tableSize}>
                  <Box>
                    <MaturityBadge
                      value={maturityTimestamp}
                      showDaysLeft={false}
                    />
                  </Box>
                </TableCell>
                <TableCell size={tableSize}>
                  <Box direction={'row'}>
                    {riskDirection === nextRiskDirection && (
                      <RiskDirectionTag direction={riskDirection} />
                    )}
                    {riskDirection !== nextRiskDirection && (
                      <Box direction={'row'} align={'center'} gap={'4px'}>
                        <RiskDirectionTag direction={riskDirection} />
                        <RightArrow fill={'gray'} />
                        <RiskDirectionTag direction={nextRiskDirection} />
                      </Box>
                    )}
                  </Box>
                </TableCell>
                <TableCell size={tableSize}>
                  <ValuesChangeBadge
                    from={notional}
                    to={nextNotional}
                    decimals={underlyingDecimals}
                    name={underlyingName}
                    showName={true}
                  />
                </TableCell>
                <TableCell size={tableSize}>
                  <Box direction={'row'} align={'center'} gap={'2px'}>
                    <Text
                      style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {isConnected ? `${leverageFrom.toString()}x` : '-'}
                    </Text>
                    {leverageTo !== null && leverageTo !== leverageFrom && (
                      <Box direction={'row'} align={'center'}>
                        <RightArrow isPositive={leverageFrom < leverageTo} />
                      </Box>
                    )}
                    {leverageTo !== null && leverageTo !== leverageFrom && (
                      <Text
                        style={{
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {leverageTo.toString()}x
                      </Text>
                    )}
                  </Box>
                </TableCell>
                <TableCell size={tableSize}>
                  <ValuesChangeBadge
                    from={dv100}
                    to={!notional.isZero() ? nextDv100 : null}
                    decimals={underlyingDecimals}
                    name={underlyingName}
                    showName={true}
                  />
                </TableCell>
                <TableCell size={tableSize}>
                  <DynamicValue
                    value={profitAndLoss}
                    decimals={underlyingDecimals}
                    name={underlyingName}
                  />
                </TableCell>

                <TableCell size={tableSize}>
                  <Box width={'100%'} justify='end' align='end'>
                  {!isProcessing ? (
                    <UnwindButton
                      onClick={() => handleUnwindPosition(item, modalProps)}
                      align={'center'}
                      justify={'center'}
                    >
                      <Text
                        color={'#E76565'}
                        style={{
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        Close
                      </Text>
                    </UnwindButton>
                  ) : (
                    <Box gap={'7px'} direction="row">
                      <RhoSpinner />
                      <Text color={'accentWhite2'}>Loading</Text>
                    </Box>
                  )}
                  </Box>
                </TableCell>
                {/*<TableCell pad={{ right: '22px', left: '22px' }}>*/}
                {/*  {!isMatured &&*/}
                {/*      <Box direction={'row'} justify={'end'}>*/}
                {/*          <Button disabled={true} style={{*/}
                {/*            height: '25px',*/}
                {/*            padding: '2px 10px',*/}
                {/*            border: 'none'*/}
                {/*          }}>Close</Button>*/}
                {/*      </Box>*/}
                {/*  }*/}
                {/*</TableCell>*/}
              </TableRow>
            )
          })}
        </TableBody>
      </TableContainer>
    </Box>
  )
}
