import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import './index.css';
import {
  ChartingLibraryWidgetOptions,
  IChartingLibraryWidget,
  IPositionLineAdapter,
  ResolutionString,
  TimeFrameType,
  widget,
} from '../../../charting_library';
import {CreateDatafeed, DatafeedParams} from "./datafeed";
import {customFormatters, disabledFeatured, enabledFeatures, SupportedResolutions} from "./constants";
import {Box, Spinner} from "grommet";
import {FutureInfo, MarketInfo, RiskDirection, RiskDirectionType} from "../../../types";
import {useFutureUserData} from "../../../hooks/useFutureUserData";
import {abbreviateNumber, bnToDecimal, getActivePositions, getFutureAlias, getLiquidationRate} from "../../../utils";
import {useProtocolData} from "../../../providers/ProtocolDataProvider";
import { getMapperForUSDT } from '../../../mappers';

export interface AdvancedTradingViewProps {
  market: MarketInfo
  future: FutureInfo
  timestampFrom: number
}

// 72 candles for all
const TimeFrameMap: Record<string, string> = {
  '60': '4D',
  '1H': '4D',
  '240': '16D',
  '4H': '16D',
  '1D': '72D'
}

const defaultInterval = '1H'

const floatingRateTooggleLSKey = 'rho_protocol_floating_rate_toggle'
const positionsTooggleLSKey = 'rho_protocol_positions_toggle'

export const AdvancedTradingView = (props: AdvancedTradingViewProps) => {
  const { market, future } = props

  const { portfolio } = useProtocolData()
  const { userFutureData } = useFutureUserData(future.id)

  const [isChartReady, setIsChartReady] = React.useState(false)
  const chartContainerRef = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;

  const [selectedInterval, setSelectedInterval] = useState<string>(defaultInterval)
  const [chartWidget, setChartWidget] = useState<IChartingLibraryWidget>()
  const [avgRateEntity, setAvgRateEntity] = useState<IPositionLineAdapter>()
  const [liqRateEntity, setLiqRateEntity] = useState<IPositionLineAdapter>()

  const [isFloatingRateVisible, setIsFloatingRateVisible] = useState<boolean>(() => {
    const saved = localStorage.getItem(floatingRateTooggleLSKey);
    return saved !== null ? JSON.parse(saved) : false;
  });
  const [isPositionsVisible, setIsPositionsVisible] = useState<boolean>(() => {
    const saved = localStorage.getItem(positionsTooggleLSKey);
    return saved !== null ? JSON.parse(saved) : false;
  });

  const symbol = market && future ? getFutureAlias(market, future) : ''
  const rateSymbol = `FLOATING_RATE`
  const floatingRateToggle = useRef<HTMLElement | null>(null);

  useEffect(() => {
    localStorage.setItem(floatingRateTooggleLSKey, JSON.stringify(isFloatingRateVisible));
    localStorage.setItem(positionsTooggleLSKey, JSON.stringify(isPositionsVisible));
  }, [isFloatingRateVisible, isPositionsVisible]);

  useEffect(() => {
    const datafeedParams: DatafeedParams = {
      marketId: props.market.descriptor.id,
      futureId: props.future.id,
      timestampFrom: props.timestampFrom,
      symbols: [{
        symbol,
        full_name: `Rho:${symbol}`,
        description: symbol,
        exchange: 'Rho',
        ticker: symbol,
        type: 'crypto',
      }, {
        symbol: rateSymbol,
        full_name: rateSymbol,
        description: rateSymbol,
        exchange: 'Rho',
        ticker: rateSymbol,
        type: 'crypto',
      }]
    }

    const chartOverrides = {
      "paneProperties.background": "#1E1E20",
      "paneProperties.backgroundType": "solid",
      "paneProperties.vertGridProperties.color": "#272729",
      "paneProperties.horzGridProperties.color": "#272729",
      "scalesProperties.textColor": "#D6D6DC",
      "scalesProperties.lineColor": "#1E1E20",
    };

    const widgetOptions: ChartingLibraryWidgetOptions = {
      symbol: `Rho:${symbol}`,
      datafeed: CreateDatafeed(datafeedParams) as any,
      interval: selectedInterval as ResolutionString,
      timeframe: TimeFrameMap[selectedInterval],
      container: chartContainerRef.current,
      library_path: '/charting_library/',
      locale: 'en',
      autosize: true,
      disabled_features: disabledFeatured,
      enabled_features: enabledFeatures,
      theme: 'dark',
      overrides: chartOverrides,
      compare_symbols: [{
        symbol: rateSymbol,
        title: rateSymbol
      }],
      loading_screen: { backgroundColor: "#1E1E20", foregroundColor: "#1E1E20" },
      custom_formatters: customFormatters,
      custom_css_url: "/tradingview-chart.css",
      favorites: {
        intervals: SupportedResolutions
      }
    };

    const tvWidget = new widget(widgetOptions);

    tvWidget.onChartReady(async () => {
      // SeriesType.Area
      tvWidget.activeChart().setChartType(1)

      tvWidget.activeChart().onIntervalChanged().subscribe(null, (interval, timeframeObj) => {
        if(Number(interval)) {
          setSelectedInterval(`${Number(interval) / 60}H`)
        } else {
          setSelectedInterval(interval)
        }

        const value = TimeFrameMap[interval]

        console.log('Interval changed:', interval, 'next value: ', value)

        return timeframeObj.timeframe = {
          value,
          type: 'period-back' as TimeFrameType.PeriodBack
        }
      })

      const floatingRateStudyId = await tvWidget.activeChart().createStudy(
        "Overlay",
        true,
        false,
        { "symbol": rateSymbol },
        {
          'lineStyle.color': '#7D7C7C',
          'lineStyle.priceSource': 'open',
        },
        {
          priceScale: 'as-series'
        }
      );
      

      const volumeId = await tvWidget.activeChart().createStudy(
        'Volume',           // Study name
        false,              // Separate pane (false = overlay on the main chart)
        true,               // Visible
        {},                 // Inputs (no additional inputs for 'Volume')
        {
          'volume.color.0': '#E84855',           // Down-volume color
          'volume.color.1': '#5DB75D',           // Up-volume color
          'volume.SMA.show': false,         // Disable SMA
          'volume.showStudyArguments': false,           // Hide arguments (e.g., SMA) in the name
          'volume.showLegend': false,                   // Optionally hide the legend
        }
      );
      
      const panes = tvWidget.chart().getPanes(); // Get all panes

      if (panes[1]) {
      panes[1].setHeight(70) // sets the height of the Volume chart
      }
      

      tvWidget.activeChart().getTimeScale().defaultRightOffset().setValue(20, true)

      if (floatingRateStudyId) {
        tvWidget.activeChart().getStudyById(floatingRateStudyId).setVisible(isFloatingRateVisible)
      }

      tvWidget.headerReady().then(() => {
        addFloatingRateToggle(tvWidget);
        addOpenPositionToggle(tvWidget);
      })

      setChartWidget(tvWidget)
      setIsChartReady(true)
    });

    return () => {
      floatingRateToggle.current?.remove()
      floatingRateToggle.current = null

      setChartWidget(undefined)
      tvWidget.remove();
    };
  }, [props.future.id]);

  // Position rate line
  const [positionLineData, setPositionLineData] = useState<{
    price: number;
    text: string;
    quantity: string;
    color: string;
  } | null>(null);
  
  useEffect(() => {
    if (!chartWidget || !isPositionsVisible) {
      setPositionLineData(null);
      return;
    }
  
    try {
      const marketPortfolio = portfolio.find(item => item.descriptor.id === market.descriptor.id);
      const openPositions = getActivePositions(marketPortfolio?.futureOpenPositions || [], market);
      const openPosition = openPositions.find(item => item.futureId === future.id);
  
      if (userFutureData && openPosition && openPosition.notional.gt(0)) {
        const notional = `${bnToDecimal(
          openPosition.notional,
          market.descriptor.underlyingDecimals
        ).toFixed(2)}`;
  
        const [direction, directionSymbol] = openPosition?.riskDirection === RiskDirectionType.RECEIVER
          ? ['Short', '↓']
          : ['Long', '↑'];
  
        setPositionLineData({
          price: +userFutureData.averageRatePosition / 10 ** 16,
          text: `${direction} ${directionSymbol}`,
          quantity: `${abbreviateNumber(notional)} ${getMapperForUSDT(market.descriptor.underlyingName)}`,
          color: '#4194E7'
        });
      } else {
        setPositionLineData(null);
      }
    } catch (e) {
      console.error('Failed to add position rate', e);
      setPositionLineData(null);
    }
  }, [isPositionsVisible, chartWidget, portfolio, market, userFutureData]);

  useEffect(() => {
    if (!chartWidget || !chartWidget.chart || !isChartReady) return;
  
    // Remove existing line if it exists
    if (avgRateEntity) {
      avgRateEntity.remove();
      setAvgRateEntity(undefined);
    }
  
    // If position line should be shown, create it
    if (positionLineData) {
      try {
        const entity = chartWidget.chart()
          ?.createPositionLine({ disableUndo: true }) // Ensure chart() is defined
          .setPrice(positionLineData.price)
          .setQuantity(positionLineData.quantity)
          .setText(positionLineData.text)
          .setLineColor(positionLineData.color)
          .setBodyTextColor('#EEE')
          .setBodyBackgroundColor('#1E1E1E');
  
        if (entity) {
          setAvgRateEntity(entity);
        }
      } catch (error) {
        console.error("Error creating position line:", error);
      }
    }
  }, [chartWidget, positionLineData, isChartReady]);
  

  useEffect(() => {
    if(!chartWidget || !floatingRateToggle.current) {
      return
    }

    const studies = chartWidget.activeChart().getAllStudies()

    if (studies.length > 0) {
      chartWidget.activeChart().getStudyById(studies[0].id).setVisible(isFloatingRateVisible)
    }
  }, [isFloatingRateVisible]);

  // Liquidation rate order line
  useEffect(() => {
    if(!chartWidget) {
      return
    }

    try {
      if(liqRateEntity) {
        liqRateEntity.remove()
      }
    } catch(e) {}

    if(!market || !future || !portfolio) {
      return
    }

    const marketPortfolio = portfolio.find(item => item.descriptor.id === market.descriptor.id)
    if(!marketPortfolio) {
      return
    }

    // don't have open positions
    const activePositions = getActivePositions(marketPortfolio.futureOpenPositions, market)
    const activePosition = activePositions.find(item => item.futureId === future.id)
    if(!activePosition || activePosition.notional.eq(0)) {
      return
    }

    try {
      const riskDirection = marketPortfolio.marginState?.riskDirection
      const liquidationRate = getLiquidationRate(
        market,
        future,
        marketPortfolio,
        {
          notional: '0',
          collateral: '0',
          riskDirection: riskDirection === RiskDirectionType.RECEIVER ? RiskDirection.receiver : RiskDirection.payer,
          isMaxRateLimitAuto: true,
          maxRateLimit: '0',
          isNativeTokenSelected: false
        },
      )

      if(liquidationRate && Math.abs(liquidationRate) < 1000) {
        const entity = chartWidget.chart()
          .createPositionLine({ disableUndo: true })
          .setPrice(liquidationRate)
          .setQuantity('')
          .setText('Liquidation rate')
          .setBodyTextColor('black')
          .setBodyBackgroundColor('#ffcc48')
          .setBodyBorderColor('#ffcc48')
          .setLineColor('#ffcc48')
        setLiqRateEntity(entity)
      }
    } catch (e) {}
  }, [chartWidget, market, future, portfolio])

  function addFloatingRateToggle(tvWidget: IChartingLibraryWidget) {
    const button = tvWidget.createButton();

    button.innerHTML = `
        <span class="toggle-label">Floating rate</span>
        <div class="toggle-switch">
          <div class="toggle-slider"></div>
        </div>
      `
    button.classList.add('toggle-container')
    if (isFloatingRateVisible) {
      button.classList.add('active-toggle-container')
    }

    button.setAttribute('title', 'Display or hide floating rate');

    button.addEventListener(
        "click",
        () => setIsFloatingRateVisible(
            (prevState) => {
              button.classList.toggle('active-toggle-container')
              return !prevState
            }
        )
    );

    floatingRateToggle.current = button
  }

  function addOpenPositionToggle(tvWidget: IChartingLibraryWidget) {
    const button = tvWidget.createButton();

    button.innerHTML = `
        <span class="toggle-label">Positions</span>
        <div class="toggle-switch">
          <div class="toggle-slider"></div>
        </div>
      `
    button.classList.add('toggle-container')
    if (isPositionsVisible) {
      button.classList.add('active-toggle-container')
    }

    button.setAttribute('title', 'Display or hide open positions');

    button.addEventListener(
        "click",
        () => setIsPositionsVisible(
            (prevState) => {
              button.classList.toggle('active-toggle-container')
              return !prevState
            }
        )
    );

    floatingRateToggle.current = button
  }


  return (
    <Box background={'widgetBg'} style={{ position: 'relative' }}>
      {!isChartReady &&
        <Box
            height={'100%'}
            width={'100%'}
            align={'center'}
            justify={'center'}
            style={{ position: 'absolute' }}
        >
          <Spinner color={'spinner'} />
        </Box>
      }
      <Box
        ref={ chartContainerRef }
        className={ 'TVChartContainer' }
        style={{ visibility: isChartReady ? 'visible' : 'hidden' }}
      />
    </Box>
  );
};
