import {useEffect, useState} from "react";
import {getUserPositions, UserPositions} from "../api/dataService";

export const useUserPositions = (userAddress?: string) => {
  const [userPositions, setUserPositions] = useState<UserPositions[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const loadData = async (setIsLoadingFlag = true) => {
    try {
      if(userAddress) {
        if(setIsLoadingFlag) {
          setIsLoading(true)
        }
        const data = await getUserPositions(userAddress)
        setUserPositions([...data])
        // console.log('[useUserPositions] data loaded', data)
      } else {
        setUserPositions([])
      }
    } catch (e) {
      console.error('[useUserPositions] Failed to load user positions', e)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    setUserPositions([])
    loadData()
  }, [userAddress]);

  return {
    isLoading,
    userPositions,
    refetch: async () => {
      return loadData(false)
    }
  }
}
