import React, {useState} from 'react'
import {Box, Text} from 'grommet'
import {BigNumber} from "ethers";
import {PlacesType, Tooltip} from "react-tooltip";
import {fromBn, toBn} from "evm-bn";
import {abbreviateNumber} from "../utils";
import bn from "bignumber.js";
import {useTokenPrice} from "../providers/PriceProvider";
import { getMapperForUSDT } from '../mappers';

export interface NumberProps {
  value: BigNumber
  decimals: number
  name?: string
  showName?: boolean
  decimalPlaces?: number
  showTooltip?: boolean
  tooltipPrefix?: string
  tooltipPlace?: PlacesType
  fontSize?: string
  fontWeight?: 'normal' | 'bold' | 'bolder' | 'lighter' | number
  fontColor?: string
}

const MinVisibleValue = '0.000001'

const formatBigNumber = (value: BigNumber, decimals: number, decimalPlaces = 6) => {
  const valueStr = fromBn(value, decimals).toString()
  return bn(valueStr)
    .dp(decimalPlaces)
    .toFormat({
      decimalSeparator: '.',
      groupSeparator: ',',
      groupSize: 3,
    })
}

export const getNumberText = (params: NumberProps) => {
  const { value, decimals} = params

  const valueAbs = value.abs()
  const valueStr = fromBn(value, decimals).toString()

  // >= 100,000
  if(valueAbs.gte(toBn('100000', decimals))) {
    return abbreviateNumber(valueStr)
  }

  // >= 10,000
  if(valueAbs.gte(toBn('10000', decimals))) {
    return bn(valueStr)
      .dp(0)
      .toFormat({
        decimalSeparator: '.',
        groupSeparator: ',',
        groupSize: 3,
      })
  }

  // >= 1
  if(valueAbs.gte(toBn('1', decimals))) {
    return bn(valueStr)
      .dp(2)
      .toFormat({
        decimalSeparator: '.',
        groupSeparator: ',',
        groupSize: 3,
      })
  }
  if(valueAbs.gt(0) && valueAbs.lt(toBn(MinVisibleValue, decimals))) {
    return `<${MinVisibleValue}`
  }
  if(valueAbs.gt(0) && valueAbs.lt(toBn('1', decimals))) {
    return bn(valueStr)
      .dp(4)
      .toFormat({
        decimalSeparator: '.',
        groupSeparator: ',',
        groupSize: 3,
      })
  }
  return value.toString()
}

const NumberTooltip = (props: NumberProps) => {
  const { value, decimals, name = '' } = props

  const { ethereum: ethPrice, tetherPrice } = useTokenPrice()

  let valueUsd: string | null = null
  if(ethPrice && name.toLowerCase().includes('eth')) {
    valueUsd = formatBigNumber(value.mul(ethPrice), decimals, 2)
  } else if(tetherPrice && name.toLowerCase().includes('usd')) {
    const valueString = (+fromBn(value, decimals) * tetherPrice).toString()
    valueUsd = new bn(valueString).dp(2).toFormat({
      decimalSeparator: '.',
      groupSeparator: ',',
      groupSize: 3,
    })
  }

  const tokenValueFontSize = decimals > 6 ? '12px' : '14px'

  return <Box gap={'8px'}>
    {valueUsd !== null &&
        <Box style={{ textAlign: 'center' }}>
            <Text size={'14px'} weight={'normal'}>$ {valueUsd}</Text>
        </Box>
    }
    <Box direction={'row'} gap={'4px'}>
      <Text size={tokenValueFontSize} weight={'normal'}>
        {formatBigNumber(value, decimals, decimals)}
      </Text>
      {name &&
          <Text size={tokenValueFontSize} weight={'normal'}>{getMapperForUSDT(name)}</Text>
        }
    </Box>
  </Box>
}

const generateRandomString = () => {
  return [...Array(30)].map(() => Math.random().toString(36)[2]).join('')
}

export const Number = (props: NumberProps) => {
  const {
    name = '',
    showTooltip = true,
    showName = false,
    fontColor = 'text',
  } = props

  const [tooltipId] = useState(generateRandomString())
  const valueFormatted = getNumberText(props)
  const tooltipContent = <NumberTooltip {...props} />

  return <Box direction={'row'}>
    <Text
      size={props.fontSize}
      weight={props.fontWeight}
      color={fontColor}
      data-tooltip-id={tooltipId}
    >

      {valueFormatted}{(showName && name) ? ` ${getMapperForUSDT(name)}` : ''}
    </Text>

    {showTooltip &&
        <Tooltip
            id={tooltipId}
            place={props.tooltipPlace}
            border={'1px solid #454A55'}
            opacity={1}
            style={{
              fontSize: '14px',
              fontWeight: 'normal',
              textAlign: 'left',
            }}
        >
          {tooltipContent}
        </Tooltip>
    }
  </Box>
}
