import {readContract} from "wagmi/actions";
import config from '../config'
import VaultBaseABI from "../abi/VaultBaseABI.json";
import VaultContractProviderABI from "../abi/VaultContractProviderABI.json";
import {MarketOraclePackage} from "./oracleService";
import {BigNumber} from "ethers";
import {enrichResponse} from "./utils";
import {wagmiConfig} from "../modules/wagmi";


export const getMarketIds = async (vaultProxyAddress: string) => {
  return await readContract(wagmiConfig, {
    address: vaultProxyAddress as `0x${string}`,
    abi: VaultBaseABI,
    functionName: 'getMarketIds',
    args: [],
    chainId: config.chainId
  }) as string[]
}

export const getAllVaultIds = async () => {
  return await readContract(wagmiConfig, {
    address: config.vaultContractProvider as `0x${string}`,
    abi: VaultContractProviderABI,
    functionName: 'getAllVaultIds',
    args: [],
    chainId: config.chainId
  }) as string[]
}

export const getAllVaultAddresses = async () => {
  return await readContract(wagmiConfig, {
    address: config.vaultContractProvider as `0x${string}`,
    abi: VaultContractProviderABI,
    functionName: 'getAllVaultAddresses',
    args: [],
    chainId: config.chainId
  }) as string[]
}

export const getMaxWithdraw = async (
  vaultAddress: string,
  owner: string,
  marketsOraclePackages: MarketOraclePackage[]
) => {
  const value = await readContract(wagmiConfig, {
    address: vaultAddress as `0x${string}`,
    abi: VaultBaseABI,
    functionName: 'maxWithdraw',
    args: [owner, owner, marketsOraclePackages],
    chainId: config.chainId
  })
  return enrichResponse(value) as BigNumber
}

export const getVaultIdByAddress = async (vaultAddress: string) => {
  return await readContract(wagmiConfig, {
    address: config.vaultContractProvider as `0x${string}`,
    abi: VaultContractProviderABI,
    functionName: 'getVaultIdByAddress',
    args: [vaultAddress],
    chainId: config.chainId
  }) as string
}

export const getUnderlyingAddress = async (vaultAddress: string) => {
  return await readContract(wagmiConfig, {
    address: vaultAddress as `0x${string}`,
    abi: VaultBaseABI,
    functionName: 'asset',
    args: [],
    chainId: config.chainId
  }) as string
}

export const getVaultName = async (vaultAddress: string) => {
  return await readContract(wagmiConfig, {
    address: vaultAddress as `0x${string}`,
    abi: VaultBaseABI,
    functionName: 'name',
    args: [],
    chainId: config.chainId
  }) as string
}

export const getVaultSymbol = async (vaultAddress: string) => {
  return await readContract(wagmiConfig, {
    address: vaultAddress as `0x${string}`,
    abi: VaultBaseABI,
    functionName: 'symbol',
    args: [],
    chainId: config.chainId
  }) as string
}

export const getVaultTotalSupply = async (vaultAddress: string) => {
  const value = await readContract(wagmiConfig, {
    address: vaultAddress as `0x${string}`,
    abi: VaultBaseABI,
    functionName: 'totalSupply',
    args: [],
    chainId: config.chainId
  }) as string
  return enrichResponse(value) as BigNumber
}

export const getTotalAssets = async (
  vaultAddress: string,
  marketsOraclePackages: MarketOraclePackage[]
) => {
  const value = await readContract(wagmiConfig, {
    address: vaultAddress as `0x${string}`,
    abi: VaultBaseABI,
    functionName: 'totalAssets',
    args: [marketsOraclePackages],
    chainId: config.chainId
  }) as string
  return enrichResponse(value) as BigNumber
}

export interface RhoVaultKey {
  name: string
  version: number
  underlying: string
}

export const getVaultKey = async (vaultAddress: string) => {
  return await readContract(wagmiConfig, {
    address: vaultAddress as `0x${string}`,
    abi: VaultBaseABI,
    functionName: 'getKey',
    args: [],
    chainId: config.chainId
  }) as RhoVaultKey
}

export const getUserPendingWithdrawalsAmount = async (vaultAddress: string, userAddress: string) => {
  const value = await readContract(wagmiConfig, {
    address: vaultAddress as `0x${string}`,
    abi: VaultBaseABI,
    functionName: 'getUserPendingWithdrawalsAmount',
    args: [userAddress],
    chainId: config.chainId
  })
  return enrichResponse(value) as BigNumber
}

export const getIsVaultUser = async (vaultAddress: string, userAddress: string) => {
  return await readContract(wagmiConfig, {
    address: vaultAddress as `0x${string}`,
    abi: VaultBaseABI,
    functionName: 'isVaultUser',
    args: [userAddress],
    chainId: config.chainId
  }) as boolean
}
