import React from 'react'
import { TradeProps } from "../common";
import { Box } from "grommet";
import { TradeRequest } from "../trade-request";
import { TradingViewChart } from "../trading-view";
import { MarketTradeHeader } from "../market-header";
import { BottomPanel } from "../bottom-panel";
import { WidgetsBox } from "../../../components";

const gap = '16px';
const minHeight = '600px';
const headerHeight = '74px';
const widgetWidth = '360px';
const fullWidgetWidth = `calc(100% - ${widgetWidth})`;

export const DesktopView = (formProps: TradeProps) => {
  const { modalId } = formProps;

  return (
    <Box
      width={'calc(100vw - 32px)'}
      height={'auto'}
      margin={{ left: gap, right: gap }}
      gap={gap}
      overflow={{vertical: 'auto'}} // Enable scrolling
    >
      <Box style={{ minHeight: headerHeight }}>
        <MarketTradeHeader {...formProps} />
      </Box>
      <Box direction={'row'} justify={'between'} gap={gap} >
        <Box gap={gap} width={fullWidgetWidth}  >
          <WidgetsBox width={'100%'} height={'auto'} overlay={modalId !== ''}>
            <TradingViewChart {...formProps} />
          </WidgetsBox>
          <BottomPanel overlay={modalId !== ''} {...formProps} />
        </Box>
        <WidgetsBox
          width={widgetWidth}
          height={'100%'}
          overlay={modalId !== ''}
          style={{ minHeight, minWidth: widgetWidth }}
        >
          <TradeRequest {...formProps} />
        </WidgetsBox>
      </Box>
    </Box>
  );
};
