import React, {useEffect, useMemo, useState} from 'react'
import {TradeProps} from "../common";
import {Box, Text} from "grommet";
import {TradeButton} from "./TradeButton";
import {useAccount, useWriteContract} from "wagmi";
import {getAccount} from "@wagmi/core";
import {getLiquidationRate, getOraclePackage, prepareFormNumber} from "../../../utils";
import {BigNumber} from "ethers";
import {FutureOpenPosition, RiskDirection} from "../../../types";
import config from "../../../config";
import RouterABI from "../../../abi/RouterABI.json";
import erc20MockABI from "../../../abi/erc20MockABI.json";
import {ApproveButton} from "./ApproveButton";
import {NotificationBadge, Number, QuestionMark, UnsupportedNetwork, ValuesChangeBadge} from "../../../components";
import {ConnectWalletButton} from "./ConnectWallet";
import {RestrictedAccess} from "./RestrictedAccess";
import {useProtocolData} from "../../../providers/ProtocolDataProvider";
import tooltips from "../../../utils/tooltips";
import {Typography} from "antd";
import {toast} from "react-toastify";
import {DepositButton} from "./DepositButton";
import {PageModal} from "../../../constants";
import {useInsufficientMargin} from "../../../hooks/useInsufficientMargin";
import {useActiveModal} from "../../../providers/ModalsProvider";
import {wagmiConfig} from "../../../modules/wagmi";

import {usePublicClient} from "../../../hooks/blockchainHooks";
import { usePositionState } from '../../../providers/PositionStateProvider';
import { useCurrentRate } from '../../../hooks/useCurrentRate';
import { useWithdrawableMargin } from '../../../hooks/useWithdrawableMargin';


export const ActionButtons = (props: TradeProps) => {
  const {
    futureId,
    marketId,
    market,
    future,
    portfolio,
    formValues,
    tradeQuote,
    underlying,
    underlyingName,
    underlyingDecimals,
    isExecuteTradeLoading,
    isApproveTxLoading,
    leverage,
    leverageRange,
  } = props

  const {isProtocolUser, addTx } = useProtocolData()
  const {chain} = getAccount(wagmiConfig)
  const client = usePublicClient()

  const {isConnected, address: userAddress} = useAccount()
  const [isLoading, setLoading] = useState(false)
  const { setActiveModal } = useActiveModal()
  const {resetPositionState} = usePositionState()

  const [triggerRefresh, setTriggerRefresh] = useState<any>('')

  const {
    refetch: refetchWithdrawableMargin
  } = useWithdrawableMargin(props.marketId)

  const {
    currentRate: currentFutureRate,
  } = useCurrentRate(futureId)

  useEffect(()=> {
    setTriggerRefresh(currentFutureRate)
  }, [currentFutureRate])

  const {
    writeContractAsync: executeTrade,
  } = useWriteContract({
    config: wagmiConfig
  })

  const {writeContractAsync: callApprove} = useWriteContract({config: wagmiConfig})

  const onTradeClicked = async () => {
    try {
      if (!marketId) {
        return false
      }
      setLoading(true)
      const oraclePackage = await getOraclePackage(marketId)
      const collateralValue = formValues.isNativeTokenSelected
        ? 0
        : prepareFormNumber(formValues.collateral, underlyingDecimals)
      // const notionalValue = new Decimal(leverage).toDecimalPlaces(2).eq(0)
      //   ? zero
      //   : prepareFormNumber(formValues.notional, underlyingDecimals)
      const notionalValue = prepareFormNumber(formValues.notional, underlyingDecimals)
      const txConfig = {
        args: [
          futureId,
          formValues?.riskDirection === RiskDirection.payer ? '1' : '0',
          notionalValue,
          prepareFormNumber(formValues.maxRateLimit, 16),
          collateralValue,
          (Date.now() + 5 * 60 * 1000),
          true,
          [oraclePackage]
        ],
        value: formValues.isNativeTokenSelected
          ? BigInt(prepareFormNumber(formValues.collateral, underlyingDecimals).toString())
          : undefined
      }
      console.log('executeTrade params:', txConfig, 'config.gasEstimateMultiplier', config.gasEstimateMultiplier)

      let gas = undefined
      if(config.gasEstimateMultiplier) {
        try {
          gas = await client.estimateContractGas({
            address: config.routerContractAddress,
            abi: RouterABI,
            functionName: 'executeTrade',
            args: txConfig.args,
            account: userAddress as `0x${string}`
          })
          gas = BigInt(Math.round(+gas.toString() * config.gasEstimateMultiplier))
          console.log(`Using gas multiplier: ${config.gasEstimateMultiplier}, gas value: ${gas}`)
        } catch(e) {
          console.error('Failed to estimate gas', e)
        }
      }

      const resultHash = await executeTrade({
        address: config.routerContractAddress,
        abi: RouterABI,
        functionName: 'executeTrade',
        ...txConfig,
        gas
      })
      if (resultHash) {
        resetPositionState()
        console.log('executeTrade transaction hash:', resultHash)
        props.setExecuteTxHash(resultHash)


        addTx({
          marketId,
          transactionHash: resultHash,
          status: 'pending',
          type: 'trade',
          amount: formValues.notional,
          underlyingName,
          direction: formValues?.riskDirection,
          rate: expectedTradeRate
        })
      }
    } catch (e) {
      console.log('Error on call executeTrade', e)
      const message = (e as Error).message
      let errorName = ''
      if (message && message.includes('Error')) {
        const [_, description] = message.split('Error:')
        if (description) {
          const [errorTitle] = description.split('\n')
          if (errorTitle) {
            errorName = errorTitle.trim()
          }
        }
      }
      toast.error(<Box>
        <Box direction={'row'} gap={'8px'}>
          <Typography.Text
            copyable={{text: message}}>
          </Typography.Text>
          <Text>Trade error</Text>
        </Box>
        {errorName &&
            <Box><Text>{errorName}</Text></Box>
        }
      </Box>, {
        toastId: 'trade_quote_error',
      })
    } finally {
      setLoading(false)
      refetchWithdrawableMargin()
    }
  }

  const onApproveClicked = async () => {
    try {
      setLoading(true)
      const resultHash = await callApprove({
        address: underlying as `0x${string}`,
        abi: erc20MockABI as any[],
        functionName: 'approve',
        args: [config.routerContractAddress, prepareFormNumber(formValues.collateral, underlyingDecimals).toString()],
      })
      if (resultHash) {
        props.setApproveTxHash(resultHash)
        console.log('Approve tx hash:', resultHash)
      }
    } catch (e) {
      console.log('Error on approve:', e)
    } finally {
      setLoading(false)
      refetchWithdrawableMargin()
    }
  }

  const zero = BigNumber.from(0)
  const isSufficientAllowance = formValues.isNativeTokenSelected
    ? true
    : props.allowance.sub(prepareFormNumber(formValues.collateral, underlyingDecimals))
      .gte(zero)

  const isInProgress = isLoading || isApproveTxLoading || isExecuteTradeLoading
  const isDisabled = isInProgress
    || !props.tradeQuote
    || !!props.formError
    || !!props.executeTradeReceipt

  const selectedQuote = useMemo(() => ( formValues?.riskDirection === RiskDirection.payer
    ? tradeQuote?.payerQuote
    : tradeQuote?.receiverQuote ), [currentFutureRate]);

  const selectedFee = (formValues?.riskDirection === RiskDirection.payer
      ? tradeQuote?.payerQuote.tradeInfo.protocolFee.add(tradeQuote.payerQuote.tradeInfo.lpFee)
      : tradeQuote?.receiverQuote.tradeInfo.protocolFee.add(tradeQuote.receiverQuote.tradeInfo.lpFee))
    || zero

    const selectedTradeRate = useMemo(() => (
      selectedQuote?.tradeInfo.tradeRate), [selectedQuote, currentFutureRate]);


  const onOpenManageMargin = (type: 'deposit' | 'withdraw') => {
    setActiveModal(PageModal.margin, {
      type,
      marketId
    })
  }

  const fee = selectedQuote ? <Number
    value={selectedFee}
    decimals={underlyingDecimals}
    name={underlyingName}
    showName={true}
  /> : null

  const expectedTradeRate = selectedTradeRate
    ? (+selectedTradeRate.toString() / 10 ** 16).toFixed(4)
    : ''

  const isInsufficientMargin = useInsufficientMargin({ market, future, portfolio, tradeQuote, formValues, leverage, leverageRange })

  if (isConnected && userAddress && !isProtocolUser) {
    return <RestrictedAccess/>
  }

  if (isConnected && chain === undefined) {
    return <Box width={'100%'} height={'128px'} margin={{top: '32px'}}>
      <UnsupportedNetwork/>
    </Box>
  }

  if (!isConnected) {
    return <Box gap={'20px'} margin={{top: '6px'}}>
      <ConnectWalletButton/>
    </Box>
  }

  const insufficientMarginContent = <Box gap={'22px'}>
    <NotificationBadge
      type={'warning'}
      title={'Insufficient margin'}
      description={'Please deposit additional collateral'}
    />
    <DepositButton onClick={() => {
      onOpenManageMargin('deposit')
    }} />
  </Box>

  let liquidationRate = 0
  try {
    liquidationRate = (market && future && portfolio)
      ? getLiquidationRate(
        market,
        future,
        portfolio,
        formValues,
      )
      : 0
  } catch (e) {
    console.error('Failed to calculate liquidation rate', e)
  }

  return <Box gap={'20px'} margin={{ top: '6px' }} key={triggerRefresh}>
    <Box key={isInsufficientMargin ? 'true' : 'false'}>
      {isInsufficientMargin
        ? insufficientMarginContent
        : isSufficientAllowance
          ? <TradeButton
              status={formValues?.riskDirection === RiskDirection.payer ? 'success' : 'error'}
              disabled={isDisabled}
              inProgress={isInProgress}
              onClick={onTradeClicked}
            />
          : <ApproveButton
              disabled={isDisabled}
              inProgress={isInProgress}
              onClick={onApproveClicked}
            />
      }
    </Box>

    {!isInsufficientMargin &&
        <Box gap={'4px'}>
            <Box direction={'row'} justify={'between'}>
                <Box direction={'row'} align={'center'} gap={'6px'}>
                    <Text color={'textSecondary'}>Expected trade rate</Text>
                    <QuestionMark tooltipId={'expected_rate'} tooltipText={tooltips.expectedRate} />
                </Box>
                <Text>
                  {expectedTradeRate ? `${expectedTradeRate} %` : '--'}
                  {/* <ValuesChangeBadge
                from={expectedTradeRate}
                to={!openPosition.notional.isZero() ? openPosition.notional : null}
                decimals={underlyingDecimals}
                name={underlyingName}
                showName={true}
              /> */}
                </Text>
            </Box>
            <Box direction={'row'} justify={'between'}>
                <Box direction={'row'} align={'center'} gap={'6px'}>
                    <Text color={'textSecondary'}>Estimated liquidation rate</Text>
                    <QuestionMark tooltipId={'liquidation_rate'} tooltipText={'Liquidation rate'} />
                </Box>
                <Text>
                  {(Math.abs(liquidationRate) !== Infinity && Math.abs(+leverage.toFixed(1)) > 0) ? `${liquidationRate.toFixed(4)} %` : '--'}
                </Text>
            </Box>
            <Box direction={'row'} justify={'between'}>
                <Box direction={'row'} align={'center'} gap={'6px'}>
                    <Text color={'textSecondary'}>Trading fees</Text>
                    <QuestionMark tooltipId={'fees'} tooltipText={tooltips.tradingFees} />
                </Box>
                <Box direction={'row'} align={'center'} gap={'8px'}>
                    <Text>{fee !== null ? fee : '--'}</Text>
                </Box>
            </Box>
        </Box>
    }
  </Box>
}
