import {Id, toast, ToastOptions} from "react-toastify";
import {Anchor, Box, Spinner, Text} from "grommet";
import {getExplorerUrl} from "../../utils";
import React, {ReactNode, useEffect, useState} from "react";
import {LinkOutlined} from "@ant-design/icons";
import {ShareRounded} from "grommet-icons";
import {SquidRouterQuote, SquidStatus, SquidStatusType, SquidStatusTypeAlias} from "../../api/squid";
import {BigNumber} from "ethers";
import {Number} from "../Number";
import {RightArrow} from "../Icon";
import {SquidTransactionRequest} from "../../hooks/useSquidRouteExecute";
import {SquidCallEventStatus} from "../../api/squid";

const defaultOptions: ToastOptions = {
  hideProgressBar: true,
}

export const tradeSuccessNotification = (params: {
  text: string;
  transactionHash: string
}) => {
  const { text, transactionHash } = params

  toast.success(<Box>
    <Text>Trade confirmed:</Text>
    <Text>
      {text}
    </Text>
    <Anchor
      href={`${getExplorerUrl()}/tx/${transactionHash}`}
      target={'_blank'}
      style={{ fontWeight: 400 }}
    >
      <Text weight={400}>Explorer link</Text>
      {' '}
      <LinkOutlined />
    </Anchor>
    </Box>, {
      ...defaultOptions,
      toastId: transactionHash
  })
}

export const marginSuccessNotification = (params: {
  type: 'deposit' | 'withdraw'
  text: string | ReactNode;
  transactionHash: string,
}) => {
  const { type, text, transactionHash } = params

  toast.success(<Box>
    {/*<Text>{type === 'deposit' ? 'Deposit' : 'Withdraw'} confirmed:</Text>*/}
    <Text>
      {text}
    </Text>
    <Anchor
      href={`${getExplorerUrl()}/tx/${transactionHash}`}
      target={'_blank'}
      style={{ fontWeight: 400 }}
    >
      <Text weight={400}>Explorer link</Text>
      {' '}
      <LinkOutlined />
    </Anchor>
  </Box>, {
    ...defaultOptions,
    toastId: transactionHash,
    autoClose: false
  })
}

export const unwindSuccessnotification = (symbol: string) => {

  const title = 'Position closed'

  toast.success(<Box>
    {/*<Text>{type === 'deposit' ? 'Deposit' : 'Withdraw'} confirmed:</Text>*/}
    <Text>
      {title}
    </Text>
   
   <Text>
    Symbol: {symbol}
   </Text>
  </Box>, {
    ...defaultOptions,
    autoClose: false
  })
}

export const CrossChainDepositNotification = (props: {
  requests: SquidTransactionRequest[]
}) => {
  const { requests} = props

  const [toastIds, setToastIds] = useState<Id[]>([])

  const renderNotification = (request: SquidTransactionRequest) => {
    const { quote, status } = request

    if (
      status?.toChain?.callEventStatus ===
      SquidCallEventStatus.CROSS_MULTICALL_FAILED
    ) {
      return (
        <Box gap={'8px'}>
          <Text size={'16px'} weight={500} color={'status-critical'}>
            Transaction Failed During Multicall
          </Text>
          <Text>Funds will be refunded to your address.</Text>
          {status?.axelarTransactionUrl && (
            <Anchor
              href={status?.axelarTransactionUrl}
              target={'_blank'}
              style={{ fontWeight: 400 }}
            >
              <Text weight={400} style={{ textDecoration: 'underline' }}>
              Get Transaction details
              </Text>
              <Box margin={{ left: '4px' }} justify={'center'}>
                <ShareRounded size={'14px'} color={'accentWhite'} />
              </Box>
            </Anchor>
          )}
        </Box>
      )
    }

    return <Box gap={'8px'}>
      <Text size={'16px'} weight={500}>Cross-chain deposit</Text>
      {quote &&
          <Box direction={'row'} align={'center'} gap={'4px'}>
              <Number
                  value={BigNumber.from(quote.route.estimate.fromAmount)}
                  decimals={quote.route.estimate.fromToken.decimals}
                  name={quote.route.estimate.fromToken.symbol}
                  showName={true}
                  // fontColor={'accentWhite'}
              />
              <RightArrow isPositive={true} />
              <Number
                  value={BigNumber.from(quote.route.estimate.toAmount)}
                  decimals={quote.route.estimate.toToken.decimals}
                  name={quote.route.estimate.toToken.symbol}
                  showName={true}
                  fontColor={'accentWhite'}
              />
          </Box>
      }
      <Box direction={'row'} gap={'8px'} align={'center'}>
        <Text color={status && [SquidStatusType.EXPRESS_EXECUTED, SquidStatusType.SUCCESS].includes(status?.status) ? 'positiveValue' : 'text'}>
          Status: {
          status?.status
            ? (SquidStatusTypeAlias[status.status] || status?.status)
            : 'Waiting for transaction'
        }
        </Text>
        {(!status || ![SquidStatusType.EXPRESS_EXECUTED, SquidStatusType.SUCCESS].includes(status?.status)) &&
            <Spinner width={'4px'} color={'spinner'} />
        }
      </Box>
      {status && status.axelarTransactionUrl &&
          <Anchor
              href={status.axelarTransactionUrl}
              target={'_blank'}
              style={{ fontWeight: 400 }}
          >
              <Text weight={400} style={{ textDecoration: 'underline' }}>Track status</Text>
              <Box margin={{ left: '4px' }} justify={'center'}>
                  <ShareRounded size={'14px'} color={'accentWhite'} />
              </Box>
          </Anchor>
      }
    </Box>
  }

  // Show notification for a first time
  useEffect(() => {
    requests.forEach((request) => {
      const { transactionHash } = request

      const existedToastId = toastIds.find(id => id === transactionHash)

      if(!existedToastId) {
        const newToastId = toast(renderNotification(request), {
          ...defaultOptions,
          toastId: transactionHash,
          autoClose: false
        })
        setToastIds((ids) => [newToastId, ...ids])
      } else {
        toast.update(transactionHash, {
          render: renderNotification(request)
        })
      }
    })
  }, [requests, toastIds]);

  return <Box>
  </Box>
}