import React from 'react'
import { Box, Text } from 'grommet'
import { WidgetContainer } from '../../../components'
import { ReactComponent as EarnIcon } from '../../../assets/images/infoEarn.svg'
const Info = () => {
  return (
    <WidgetContainer>
      <Box align="center" gap="12px" direction='row' style={{maxWidth: '868px'}}>
        <EarnIcon width={90}/>
        <Text size="14px" color="#838495">
          This vault operates within Rho USDT markets and allows users to earn a
          share of the revenue it generates. It employs two strategies:
          providing liquidity to collect fees and liquidating uncollateralized
          users to receive rewards. More details
        </Text>
      </Box>
    </WidgetContainer>
  )
}

export default Info
