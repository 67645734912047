import React from 'react'
import { Box, Text } from "grommet";
import styled from "styled-components";
import { NavLink, useLocation } from "react-router-dom";
import { useProtocolData } from '../../providers/ProtocolDataProvider';

interface MenuItemProps {
  children: React.ReactNode
  route: string
}

const MenuItemContainer = styled(Box)<{ isActive?: boolean }>`
  position: relative;
  flex-shrink: 0; 
  padding: 6px 12px; 

  ${(props) => props.isActive && `
    background: #26262B;
    border-radius: 6px;
  `}
`

const MenuItem = (props: MenuItemProps) => {
  const { route } = props;
  const { pathname } = useLocation();
  const activeRoute = pathname === '/' ? '/trade' : pathname;
  const isActive = !!route && activeRoute.startsWith(route);

  return (
    <NavLink to={props.route} style={{ textDecoration: 'none' }}>
      <MenuItemContainer isActive={isActive}>
        <Text weight={ isActive ? 500 : 400} size={'15px'} color={isActive ? '#E4E4E8' : '#B6B7C8'}>
          {props.children}
        </Text>
      </MenuItemContainer>
    </NavLink>
  );
};

export const HeaderMenu = () => {
  const { setCurrentMarketType } = useProtocolData();
  return (
    <Box direction={'row'} gap={'15px'} align='center' height={'60px'}>
      <Box onClick={() => setCurrentMarketType('funding')}>
        <MenuItem route={'/funding'}>Funding</MenuItem>
      </Box>
      <Box onClick={() => setCurrentMarketType('staking')}>
        <MenuItem route={'/staking'}>Staking</MenuItem>
      </Box>
      <MenuItem route={'/portfolio'}>Portfolio</MenuItem>
      <MenuItem route={'/history'}>History</MenuItem>
      <MenuItem route={'/stats'}>Stats</MenuItem>
    </Box>
  );
};
