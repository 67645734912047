import React from "react";
import styled from "styled-components";
import {Box} from "grommet";
import {ReactComponent as CandleImg} from '../assets/images/candle.svg';
import {ReactComponent as DocsImg} from '../assets/images/docs.svg';
import {ReactComponent as LearnImg} from '../assets/images/learn.svg';
import {ReactComponent as HistoryImg} from '../assets/images/history_active.svg';
import {ReactComponent as ArrowUpImg} from '../assets/images/arrow_up.svg';
import {ReactComponent as AaveImg} from '../assets/images/aave.svg';
import {ReactComponent as USDCImg} from '../assets/images/usdc.svg';
import {ReactComponent as RhoImg} from '../assets/images/rho_logo.svg';
import {ReactComponent as CompoundImg} from '../assets/images/compound.svg';
import {ReactComponent as LidoImg} from '../assets/images/lido.svg';
import {ReactComponent as GmxImg} from '../assets/images/gmx.svg';
import {ReactComponent as SofrImg} from '../assets/images/sofr.svg';
import {ReactComponent as EthImg} from '../assets/images/eth.svg';
import {ReactComponent as BtcImg} from '../assets/images/btc.svg';
import {ReactComponent as GlpImg} from '../assets/images/glp.svg';
import {ReactComponent as InfoTooltip} from '../assets/images/info_tooltip.svg';
import {ReactComponent as OKXLogo} from '../assets/images/okx.svg';
import {ReactComponent as USDTLogo} from '../assets/images/usdt.svg';
import {ReactComponent as BinanceImg} from '../assets/images/binance2.svg';
import {ReactComponent as ExternalImg} from '../assets/images/external.svg';
import {ReactComponent as CalendarImg} from '../assets/images/calendar.svg';
import {ReactComponent as DydxImg} from '../assets/images/dydx.svg';
import {ReactComponent as TriangleImg} from '../assets/images/triangle.svg';
import {ReactComponent as CheckmarkImg} from '../assets/images/checkmark.svg';
import {ReactComponent as BranchImg} from '../assets/images/branch.svg';
import {ReactComponent as MapImg} from '../assets/images/map.svg';
import {ReactComponent as LockImg} from '../assets/images/lock.svg';
import {ReactComponent as ChartImg} from '../assets/images/chart.svg';
import {ReactComponent as CollectionImg} from '../assets/images/collection.svg';
import {ReactComponent as DirectionImg} from '../assets/images/direction.svg';
import {ReactComponent as PulseImg} from '../assets/images/pulse.svg';
import {ReactComponent as FunnelImg} from '../assets/images/funnel.svg';
import {ReactComponent as SettingsImg} from '../assets/images/settings.svg';
import {ReactComponent as ChartPieImg} from '../assets/images/chartPie.svg';
import {ReactComponent as LayerImg} from '../assets/images/layer.svg';
import {ReactComponent as CoinDeskIMG} from '../assets/images/coindesk.svg';
import {ReactComponent as ByBitImg} from '../assets/images/bybit.svg';

export interface IconProps {
  color?: string
}

export const ImageContainer = styled(Box)<{ color: string }>`
  justify-content: center;
  align-content: center;
  svg {
    path {
      fill: ${props => props.color}
    }
  }
`

export const Candle = (props: IconProps) => {
  const { color = '#FFFFFF' } = props
  return <ImageContainer color={color}>
    <CandleImg />
  </ImageContainer>
}

export const Docs = (props: IconProps) => {
  const { color = '#FFFFFF' } = props
  return <ImageContainer color={color}>
    <DocsImg />
  </ImageContainer>
}

export const Learn = (props: IconProps) => {
  const { color = '#FFFFFF' } = props
  return <ImageContainer color={color}>
    <LearnImg />
  </ImageContainer>
}

export const History = (props: IconProps) => {
  const { color = '#FFFFFF' } = props
  return <ImageContainer color={color}>
    <HistoryImg />
  </ImageContainer>
}

const ArrowContainer = styled(Box)<{ rotate: string | null }>`
  transition: rotate 250ms;
  rotate: ${props => props.rotate}
`

export const Arrow = (props: { direction?: string, width?: string }) => {
  const { direction = 'down', width } = props

  let rotate: string | null = null
  if(direction === 'up') {
    rotate = '180deg'
  } else if(direction === 'left') {
    rotate = '-90deg'
  } else if(direction === 'right') {
    rotate = '90deg'
  }

  return <ArrowContainer rotate={rotate}>
    <ArrowUpImg style={{ width }} />
  </ArrowContainer>
}

export const RoundedIcon = (props: {
  icon?: any,
  minorIcon?: any,
  background?: string,
  size?: string,
  borderSize?: string
}) => {
  const { background = '#0E1033', size = '60px', borderSize = '2px' } = props

  return <Box style={{ position: 'relative' }}>
    <Box
      width={size} height={size}
      round={size}
      background={background}
      justify={'center'}
      align={'center'}
      border={{ color: 'white', size: borderSize }}
      style={{ minWidth: size, overflow: 'hidden' }}>
      {props.icon && props.icon}
    </Box>
    {props.minorIcon &&
        <Box
            width={'20px'}
            height={'20px'}
            justify={'center'}
            align={'center'}
            border={{ color: '#242833', size: '2px' }}
            background={'#242833'}
            round={'20px'}
            style={{ position: 'absolute', bottom: '-2px', right: '-2px' }}
        >
          {props.minorIcon}
        </Box>
    }
  </Box>
}

export const getIconByName = (name: string, size = '') => {
  name = name.toLowerCase().trim()
  let icon: any = ''
  let background = 'white'

  if(name.includes('rho')) {
    icon = <RhoImg width={size || '32px'} height={size || '32px'} />
    background = '#0E1033'
  } else if(name.includes('aave')) {
    icon = <AaveImg style={{ margin: '-1px' }} />
  } else if(name.includes('compound')) {
    icon = <CompoundImg />
  } else if(name === 'lido') {
    icon = <LidoImg width={'58px'} height={'58px'} />
  } else if(name.includes('gmx')) {
    icon = <GmxImg width={'38px'} height={'38px'} style={{ marginTop: '-4px' }} />
    background = '#0E1033'
  } else if(name.includes('sofr')) {
    background = '#0E1033'
    icon = <SofrImg />
  } else if(name.includes('usdc')) {
    icon = <USDCImg width={size || '22px'} height={size || '22px'} />
  } else if(name.includes('eth')) {
    icon = <EthImg width={size || '22px'} height={size || '22px'} />
  } else if(name.includes('btc')) {
    icon = <BtcImg width={size || '22px'} height={size || '22px'} />
  } else if(name.includes('glp')) {
    icon = <GlpImg width={'22px'} height={'22px'} />
  } else if(name === 'info') {
    icon = <InfoTooltip width={size || '12px'} height={size || '12px'} />
  } else if(name.startsWith('okx')) {
    icon = <OKXLogo /> 
  } else if(name.startsWith('dydx')) {
    background = '#222222'
    icon = <DydxImg width={size || '32px'} height={size || '32px'}/>
  } else if(name === 'usdt' || name === 'usd₮0') {
    icon = <USDTLogo width={size || '22px'} height={size || '22px'} />
  } else if(name.startsWith('binance')) {
    background = '#222222'
    icon = <BinanceImg width={size || '32px'} height={size || '32px'} />
  } else if(name === 'external') {
    icon = <ExternalImg width={size || '16px'} height={size || '16px'} />
  } else if(name === 'calendar') {
    icon = <CalendarImg />
  } else if(name === 'triangle') {
    icon = <TriangleImg width={size || '8px'} height={size || '8px'} />
  } else if(name === 'checkmark') {
    icon = <CheckmarkImg width={size || '18px'} height={size || '18px'} />
  } else if(name === 'branch') {
    icon = <BranchImg width={size || '22px'} height={size || '22px'} />
  } else if(name === 'map') {
    icon = <MapImg width={size || '24px'} height={size || '24px'} />
  } else if(name === 'lock') {
    icon = <LockImg width={size || '24px'} height={size || '24px'} />
  } else if(name === 'chart') {
    icon = <ChartImg width={size || '24px'} height={size || '24px'} />
  } else if(name === 'chartpie') {
    icon = <ChartPieImg width={size || '24px'} height={size || '24px'} />
  } else if(name === 'collection') {
    icon = <CollectionImg width={size || '24px'} height={size || '24px'} />
  } else if(name === 'direction') {
    icon = <DirectionImg width={size || '24px'} height={size || '24px'} />
  } else if(name === 'pulse') {
    icon = <PulseImg width={size || '24px'} height={size || '24px'} />
  } else if(name === 'funnel') {
    icon = <FunnelImg width={size || '24px'} height={size || '24px'} />
  } else if(name === 'settings') {
    icon = <SettingsImg width={size || '24px'} height={size || '24px'} />
  } else if(name === 'layer') {
    background = '#222222'
    icon = <LayerImg width={size || '24px'} height={size || '24px'} />
  } else if(name.includes('coindesk')) {
    background = '#262626'
    icon = <CoinDeskIMG width={size || '24px'} height={size || '24px'} />
  } else if(name.startsWith('bybit')) {
    background = '#262626'
    icon = <ByBitImg width={size || '24px'} height={size || '24px'} />
  }

  const iconWrapper = <Box align={'center'}>
    {icon}
  </Box>

  return {
    icon: iconWrapper,
    iconRaw: icon,
    background
  }
}

export const MarketIcon = (props: {
  name: string,
  tokenName?: string,
  size?: string,
  iconSize?: string,
  minorIconSize?: string,
  borderSize?: string
}) => {
  const { iconRaw, background } = getIconByName(props.name, props.iconSize)
  const { iconRaw: minorIcon } = getIconByName(props.tokenName || '', props.minorIconSize || '20px')

  return <RoundedIcon
    icon={iconRaw}
    minorIcon={minorIcon}
    background={background}
    size={props.size}
    borderSize={props.borderSize}
  />
}

export const RightArrow = (props: { isPositive?: boolean; fill?: string, size?: number | string;}) => {
  const { isPositive = true, size = 8 } = props
  const fill = props.fill || (isPositive ? '#5ABF7D' : '#E76565')

  return <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 8 8" fill="none">
    <g clipPath="url(#clip0_1063_4558)">
      <path d="M3.66539 7.47782C3.5136 7.47782 3.37967 7.42276 3.2636 7.31264L2.92878 6.97782C2.81569 6.86472 2.75914 6.72931 2.75914 6.57157C2.75914 6.41085 2.81569 6.27693 2.92878 6.16978L4.23682 4.85728H1.09396C0.939198 4.85728 0.813454 4.80148 0.716728 4.68987C0.620001 4.57826 0.571638 4.44359 0.571638 4.28585V3.71443C0.571638 3.55669 0.620001 3.42201 0.716728 3.31041C0.813454 3.1988 0.939198 3.143 1.09396 3.143H4.23682L2.92878 1.8305C2.81569 1.72335 2.75914 1.58943 2.75914 1.42871C2.75914 1.268 2.81569 1.13407 2.92878 1.02693L3.2636 0.692104C3.3767 0.579009 3.51063 0.522461 3.66539 0.522461C3.82313 0.522461 3.95854 0.579009 4.07164 0.692104L6.97789 3.59835C7.08801 3.70252 7.14307 3.83645 7.14307 4.00014C7.14307 4.16085 7.08801 4.29627 6.97789 4.40639L4.07164 7.31264C3.95557 7.42276 3.82015 7.47782 3.66539 7.47782Z" fill={fill} />
    </g>
    <defs>
      <clipPath id="clip0_1063_4558">
        <rect width="8" height="8" fill="white" transform="matrix(0 1 -1 0 8 0)"/>
      </clipPath>
    </defs>
  </svg>
}
