const createCsv = (items: object[]) => {
  const csvRows = [];
  const headers = Object.keys(items[0]);
  csvRows.push(headers.join(','));
  items.forEach(item => {
    const valuesString = Object.values(item).join(',');
    csvRows.push(valuesString)
  })
  return csvRows.join('\n')
}

export const downloadCsv = (items: object[], filename = 'export.csv') => {
  const csvData = createCsv(items)
  const blob = new Blob([csvData], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.setAttribute('href', url)
  a.setAttribute('download', filename);
  a.click()
}

const createCsvMargin = (items: object[], columnOrder: string[]) => {
  const csvRows = [];
  
  // Use the predefined column order
  csvRows.push(columnOrder.join(','));
  
  items.forEach(item => {
    //@ts-ignore
    const valuesString = columnOrder.map(key => item[key] ?? '').join(','); // Ensure correct order
    csvRows.push(valuesString);
  });

  return csvRows.join('\n');
}

export const downloadCsvMargin = (items: object[], filename = 'export.csv', columnOrder: string[]) => {
  if (!items.length) return;

  const csvData = createCsvMargin(items, columnOrder);
  const blob = new Blob([csvData], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  
  a.setAttribute('href', url);
  a.setAttribute('download', filename);
  a.click();
}