import React, {useEffect, useState} from 'react'
import {Box, Button, Spinner, Table, TableBody, TableCell, TableHeader, TableRow, Text} from "grommet";
import { useNavigate } from "react-router-dom";
import { Arrow, FloatRateBadge, MarketIcon, MaturityBadge } from "../../components";
import { RandomColorTickerBadge, OpTypeBadge } from "../../components";
import styled from "styled-components";
import {OpType, MarketInfo} from "../../types";
import { BigNumber } from "ethers";
import bn from "bignumber.js";
import { formatFloatRates, useRates } from '../../providers/FloatRateProvider';
import {getActiveMarketsInfo, getMarketsOraclePackages} from "../../api/viewContract";
import { toast } from 'react-toastify';
import {WidgetContainer} from "../../components";
import {getFutureAlias} from "../../utils";
import { useProtocolData } from '../../providers/ProtocolDataProvider';

const TableContainer = styled(Table)`
  thead {
    th {
      color: #858CAD;
      font-size: 12px;
      font-weight: 600;
      border: none;
    } 
  }
`

const MarketDetails = (props: MarketInfo) => {
  const { descriptor: { id }, futures } = props

  const navigate = useNavigate()
  const {currentMarketType} = useProtocolData()

  const onOpenTrade = (futureId: string) => {
    const future = futures.find(item => item.id === futureId)
    if(future) {
      navigate(`/${currentMarketType}/${getFutureAlias(props, future)}`)
    }
    // navigate(`/markets/${id}/future/${futureId}?fromPage=markets`)
  }

  const onOpenLiquidity = (swapId: string) => {
    navigate(`/markets/${id}/liquidity/${swapId}`)
  }

  return <Box>
    <TableContainer>
      <TableHeader>
        <TableRow>
          <TableCell size={'120px'} scope="col" border="bottom">
            MATURITY
          </TableCell>
          <TableCell scope="col" border="bottom">
            LAST RATE
          </TableCell>
        </TableRow>
      </TableHeader>
      <TableBody>
        {futures.map((future, index) => {
          const { termStart, termLength, vAMMParams, id } = future

          const maturity = BigNumber.from(termStart)
            .add(BigNumber.from(termLength))
            .mul(1000).toNumber()

          const lastRate = +new bn(vAMMParams.currentFutureRate.toString())
            .div(Math.pow(10, 16))
            .dp(2)

          // const termDays = BigNumber.from(termLength).div(BigNumber.from(24 * 60 * 60)).toNumber()
          // let termValue = `${termDays} days`
          // if (termDays > 30 && termDays < 360) {
          //   termValue = `${Math.round(termDays / 30)} months`
          // } else if (termDays >= 360) {
          //   termValue = `${Math.round(termDays / 360)} year`
          // }

          return <TableRow key={index} style={{ borderTop: '1px dashed #52587A' }}>
            <TableCell size={'120px'}>
              <MaturityBadge value={maturity} />
            </TableCell>
            <TableCell>
              <RandomColorTickerBadge value={lastRate} />
            </TableCell>
            <TableCell pad={{ right: '0px' }}>
              <Box direction={'row'} justify={'end'} gap={'16px'} pad={'12px 0'}>
                <Button primary onClick={() => onOpenTrade(id)}>Trade</Button>
                <Button primary onClick={() => onOpenLiquidity(id)}>Add liquidity</Button>
              </Box>
            </TableCell>
          </TableRow>
        }
        )}
      </TableBody>
    </TableContainer>
  </Box>
}

const Market = (props: MarketInfo) => {
  const {
    descriptor: {
      sourceName,
      underlyingName,
      tag,
      instrumentName
    }
  } = props

  // TODO: get from env variables
  // const isETH = underlyingName.toLowerCase().includes('eth')
  // const { ethereum: ethPrice } = useTokenPrice()
  // const interest = fromBn(openInterest.mul(isETH ? ethPrice : 1), underlyingDecimals).toString()

  const rates = useRates()
  const floatRate = formatFloatRates({
    rates,
    sourceName,
    tag,
    marketId: props.descriptor.id
  })

  const [isDetailsOpened, setDetailsOpened] = useState(true)

  return <WidgetContainer style={{ minWidth: '700px' }}>
    <Box direction={'row'} justify={'between'} align={'center'}>
      <Box direction={'row'}>
        <MarketIcon name={sourceName} tokenName={underlyingName} />
        <Box margin={{ left: '40px' }} pad={{ top: '8px' }}>
          <Box direction={'row'}>
            <Text size={'20px'} weight={500}>{sourceName} {instrumentName}</Text>
            <Box margin={{ left: '10px' }}>
              <OpTypeBadge opType={tag as OpType} />
            </Box>
          </Box>
          <Box margin={{ top: '2px' }} direction={'row'} align={"center"} gap={'10px'}>
            <Text size={'15px'} color={'textSecondary'}>{underlyingName}</Text>
            <FloatRateBadge floatRate={floatRate} coloredValue={false} />
          </Box>
        </Box>
        {/*{!isMainnet() &&*/}
        {/*  <Box margin={{ left: '60px' }} justify={'center'}>*/}
        {/*    <DashedValueBadge value={formatNumber(interest, 'usd')} text={'Open Interest'} />*/}
        {/*  </Box>*/}
        {/*}*/}
      </Box>
      <Box direction={'row'} gap={'12px'} align={'center'} onClick={() => setDetailsOpened(!isDetailsOpened)}>
        <Text size={'14px'} weight={600} color={'#BDBEDB'}>Explore Rates</Text>
        <Arrow direction={isDetailsOpened ? 'up' : 'down'} />
      </Box>
    </Box>
    {isDetailsOpened &&
      <Box margin={{ top: '32px' }}>
        <MarketDetails {...props} />
      </Box>
    }
  </WidgetContainer>
}

export const MarketsPage = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [markets, setMarkets] = useState<MarketInfo[]>([])

  const loadData = async () => {
    setIsLoading(true)
    try {
      const marketOraclePackages = await getMarketsOraclePackages()
      let data = await getActiveMarketsInfo(marketOraclePackages)
      data = data.map(market => {
        const futures = market.futures.sort((a, b) => {
          return (a.termStart.add(a.termLength))
            .sub((b.termStart.add(b.termLength)))
            .isNegative()
            ? -1 : 1
        })
        return {
          ...market,
          futures
        }
      })
      setMarkets(data)
      console.log('Markets: ', data)
    } catch (e) {
      const message = ((e as Error).message || 'Unknown error').slice(0, 256)
      console.error('Cannot load markets:', message)
      toast.error(message, {
        toastId: message
      })
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    loadData()
  }, []);

  // useInterval(() => {
  //   loadData()
  // }, 10000)

  return <Box width={'1040px'} margin={{ top: '32px' }} pad={'0 64px 32px'} gap={'32px'} style={{ minWidth: '980px' }}>
    {isLoading &&
        <Box width={'100%'} justify={'center'} align={'center'} pad={{ top: '64px' }}>
            <Spinner color={'spinner'} size={'small'} />
        </Box>
    }
    {!isLoading && markets.map((item) =>
      <Market key={item.descriptor.id} {...item} />
    )}
    {(!isLoading && markets.length === 0) &&
      <Box width={'100%'} justify={'center'} align={'center'} pad={{ top: '32px' }}>
        <Text size={'22px'} color={'textSecondary'}>No markets found</Text>
      </Box>
    }
  </Box>
}
