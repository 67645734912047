import React from "react";
import {Box, Text} from "grommet";
import styled from "styled-components";
import Background from "../../../assets/images/statsBackground.svg";
import {ReactComponent as EthD} from "../../../assets/images/ethDiamond.svg";
import {useVault} from "../../../providers/VaultDataProvider";
import {Number, QuestionMark} from "../../../components";
import {calculateCurrentAPY} from "../Deposited";
import {Skeleton} from "antd";

export const CustomContainer = styled(Box)`
  border-radius: 12px;
  background: #1e1e20;
  position: relative;
  height: inherit;
`;

export const Stats = () => {
  const {vault} = useVault();
  return (
    <CustomContainer
      width="509px"
      height="373px"
      justify="center"
      style={{
        backgroundImage: `url(${Background})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <Box style={{position: "relative", left: "64px"}}>
        <Box>
          <Text size="34px" weight={500} color="#D6D6DC">
            Rho Vault
          </Text>
          <Text size="16px" weight={400} color="#838495" margin={{top: "14px"}}>
            Earn APY on your USDT
          </Text>
        </Box>

        <Box margin={{top: "35px"}}>
          <Box direction="row" gap="10px" align="center">
            <Text size="22px" weight={400} color="#838495">
              APY:
            </Text>

            {vault ? (
              <Box style={{zIndex: 4}} direction="row" align="center" gap="6px">
                <Text size={"22px"} weight={500} color="#D6D6DC">
                  {calculateCurrentAPY(vault)}%
                </Text>
                <QuestionMark tooltipId="apy-vault" tooltipText={"Historical APY since August 2024. Note that past returns do not represent a guarantee of future returns."} size="14px" />
              </Box>
            ) : (
              <Skeleton.Input active size={"small"} style={{width: "140px", height: "35px"}} />
            )}
          </Box>

          <Box direction="row" gap="10px" align="center" margin={{top: "14px"}}>
            <Text size="22px" weight={400} color="#838495">
              TVL:
            </Text>
            {vault && (
              <Box style={{zIndex: 4}} direction="row" align="center" gap="2px">
                <Text size="22px" weight={500} color="textHeader">
                  $
                </Text>
                <Number value={vault.totalAssets} decimals={vault.underlyingDecimals} fontSize={"22px"} fontWeight={500} fontColor={"textHeader"} name={vault.underlyingName} showName={false} />
              </Box>
            )}
          </Box>
        </Box>
      </Box>

      <Box style={{position: "absolute", right: "0px"}}>
        <EthD />
      </Box>
    </CustomContainer>
  );
};
