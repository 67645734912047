import {BigNumber} from "ethers";
import {prepareFormNumber} from "../../utils";
import {FormError, FormErrors, LiquidityOperation} from "../../types";
import bn from "bignumber.js";
import {fromBn} from "evm-bn";
import {LiquidityProvisionParams} from "./index";
import {marginTotal} from "../../utils/mappers";
import { getMapperForUSDT } from "../../mappers";

export const getFormError = (params: LiquidityProvisionParams): FormError | null => {
  const {
    underlyingBalance,
    nativeTokenBalance,
    market: { descriptor: { underlyingDecimals, underlyingName } },
    formValues,
    marketPortfolio,
    liquidityQuote
  } = params

  const zero = BigNumber.from(0)

  const collateral = prepareFormNumber(formValues.collateral, underlyingDecimals)
  const rateMin = prepareFormNumber(formValues.swapRateMin, 16)
  const rateMax = prepareFormNumber(formValues.swapRateMax, 16)

  if(rateMax.sub(rateMin).lte(zero)) {
    return {
      field: 'swapRateMin',
      type: FormErrors.wrongValue,
      text: `Min rate should be lower than max`
    }
  }

  // if(rateMin.lt(prepareFormNumber(rateLowerLimit.toString(), 16))) {
  //   return {
  //     field: 'swapRateMin',
  //     type: FormErrors.wrongValue,
  //     text: `Lower bound should be greater than -5%`
  //   }
  // }
  //
  // if(rateMax.gt(prepareFormNumber(rateUpperLimit.toString(), 16))) {
  //   return {
  //     field: 'swapRateMin',
  //     type: FormErrors.wrongValue,
  //     text: `Upper bound should be lower than ${rateUpperLimit}%`
  //   }
  // }

  if(formValues.operation === LiquidityOperation.provide) {
    if(!formValues.isNativeTokenSelected && underlyingBalance.sub(collateral).isNegative()) {
      return {
        field: 'collateral',
        type: FormErrors.insufficientFunds,
        value: zero,
        text: 'Insufficient funds'
      }
    }

    if(formValues.isNativeTokenSelected && nativeTokenBalance.sub(collateral).isNegative()) {
      return {
        field: 'collateral',
        type: FormErrors.insufficientFunds,
        value: zero,
        text: 'Insufficient funds'
      }
    }

    if(liquidityQuote && formValues.operation === LiquidityOperation.provide) {
      const margin = marketPortfolio
        ? marginTotal(marketPortfolio.marginState.margin)
        : BigNumber.from(0)

      if(margin.add(collateral).sub(liquidityQuote.newMarginThreshold).isNegative()) {
        const additionalCollateral =  liquidityQuote.newMarginThreshold.sub(collateral).sub(margin)
        if(additionalCollateral.gt(zero)) {
          const text = `
          Additional collateral required:
          ${bn(fromBn(additionalCollateral, underlyingDecimals)).dp(2, bn.ROUND_UP).toString()}
          ${getMapperForUSDT(underlyingName)}
        `
          return {
            field: 'collateral',
            type: FormErrors.collateralRequired,
            value: additionalCollateral,
            text
          }
        }
      }
    }
  }

  if(formValues.operation === LiquidityOperation.remove) {
    // if(marketPortfolio && !liquidityQuote) {
    //   const { makerProvisions } = marketPortfolio
    //   const makerPosition = makerProvisions.find((pos) => pos.swapId === swapId)
    //   if(makerPosition && makerPosition.provisionNotional.sub(notional).isNegative()) {
    //     return {
    //       field: 'notional',
    //       type: FormErrors.insufficientFunds,
    //       value: zero,
    //       text: 'Insufficient liquidity'
    //     }
    //   }
    // }

    // if(marketPortfolio) {
    //   const margin = marginTotal(marketPortfolio.marginState.margin)
    //   const lmr = liquidityQuote
    //     ? liquidityQuote?.newMarginThreshold
    //     : marketPortfolio.marginState.lpMarginThreshold
    //
    //   if(margin.sub(collateral).lt(lmr)) {
    //     return {
    //       field: 'notional',
    //       type: FormErrors.insufficientFunds,
    //       text: 'Final margin less than LMR'
    //     }
    //   }
    // }
  }

  return null
}
