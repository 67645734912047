import React, {useMemo, useState} from 'react'
import {Box, Text} from "grommet";
import {WidgetContainer} from "../widgets";
import {Input} from "antd";
import {ReactComponent as SearchIcon} from "../../assets/images/search.svg";
import {MarketsTable} from "./MarketsTable";
import {FutureInfo, MarketInfo} from "../../types";
import {MarketSelectProps} from "./index";
import {getFutureAlias} from "../../utils";
import CryptoBubbleSelector from '../Filter';

type MarketType = 'all' | 'funding' | 'staking'

interface TabSelectItem {
  id: string;
  text: string;
}

const TabsSelect = (props: {
  items: TabSelectItem[]
  selectedId: string
  onSelect: (item: TabSelectItem) => void
}) => {
  return <Box direction={'row'} round={'6px'} background={'inputBg'} pad={'4px'}>
    {props.items.map(item => {
      const isSelected = item.id === props.selectedId
      return <Box key={item.id} pad={'5px 16px'} round={'4px'}
                  background={isSelected ? '#25252D' : 'unset'}
                  style={{ minWidth: '60px', textAlign: 'center' }}
                  onClick={() => props.onSelect(item)}
      >
        <Text
          weight={500}
          color={isSelected ? 'accentWhite2' : '#818298'}
        >
          {item.text}
        </Text>
      </Box>
    })}
  </Box>
}

export const MarketsSelect = (props: MarketSelectProps & {
  markets: MarketInfo[]
}) => {
  const { markets, mode = 'default' } = props

  const [marketType, setMarketType] = useState<MarketType>('all')
  const [selectedMarketId, setSelectedMarketId] = useState('all')
  const [searchValue, setSearchValue] = useState('')

  const filteredMarkets = useMemo(() => {
    return markets.map(market => {
      const { descriptor: { id, sourceName, instrumentName, tag } } = market
      const marketName = `${sourceName.toLowerCase()} ${instrumentName.toLowerCase()}`

      if(searchValue && !marketName.includes(searchValue.toLowerCase())) {
        const futures = market.futures.filter((future) => {
          const alias = getFutureAlias(market, future)
          return alias.toLowerCase().includes(searchValue.toLowerCase())
        })
        return {
          ...market,
          futures
        }
      }
      return market
    }).filter(market => {
      const { descriptor: { id, sourceName, instrumentName, tag } } = market
      const marketName = `${sourceName.toLowerCase()} ${instrumentName.toLowerCase()}`

      const marketNameInSearch = marketName.includes(searchValue.toLowerCase())
        && (marketType === 'all' || tag.toLowerCase().includes(marketType))
        && (selectedMarketId === 'all' || id === selectedMarketId)

      const futuresInSearch = markets
        .find(item => item.descriptor.id === market.descriptor.id)?.futures.length !== market.futures.length

      return (marketNameInSearch || futuresInSearch) && market.futures.length > 0
    })
  }, [markets, searchValue, marketType])

  const filterArray = [ 'BTC', 'ETH', ]

  return <WidgetContainer
    style={{
      // borderRadius: '0px',
      // borderBottomLeftRadius: '12px',
      // borderBottomRightRadius: '12px',
      paddingTop: 0,
  }}
  >
    {mode === 'default' &&
        <Box
            background={'widgetBg'}
            pad={{ top: '16px', bottom: '16px' }}
            style={{ position: 'sticky', top: '0', zIndex: 1 }}
        >
            <Box gap={'16px'}>
                <Box width={'100%'}>
                    <Input
                        value={searchValue}
                        placeholder={'Search'}
                        prefix={<SearchIcon width={'16px'} height={'16px'} />}
                        allowClear={true}
                        onChange={(e) => setSearchValue(e.target.value)}
                        style={{
                          height: '40px',
                        }}
                    />
                </Box>
                <CryptoBubbleSelector items={filterArray} setSelect={props.setSelectedMarkets}/>
                {/*<TabsSelect*/}
                {/*    selectedId={marketType}*/}
                {/*    items={[{ id: 'all', text: 'All' }, { id: 'funding', text: 'Funding' }, { id: 'staking', text: 'Staking' }]}*/}
                {/*    onSelect={(item) => setMarketType(item.id as MarketType)}*/}
                {/*/>*/}
            </Box>
        </Box>
    }
    {filteredMarkets.length === 0 &&
      <Box width={'100%'} align={'center'}>
          <Text size={'16px'}>No results found</Text>
      </Box>
    }
    <MarketsTable
      {...props}
      markets={filteredMarkets}
    />
  </WidgetContainer>
}
