import config from "../config";
import {WagmiAdapter} from "@reown/appkit-adapter-wagmi";
import {AppKitNetwork} from "@reown/appkit/networks";
import {arbitrum, arbitrumSepolia, avalanche, mainnet, polygon, base, bsc, mantle, fantom, optimism} from "wagmi/chains";

const rhoChains: [AppKitNetwork, ...AppKitNetwork[]] = [arbitrum, arbitrumSepolia];

// const crossChains: [AppKitNetwork, ...AppKitNetwork[]] = [arbitrum, arbitrumSepolia, mainnet, avalanche, polygon, base, bsc, mantle, fantom, optimism];

// const isCrossChain = false;

export const networks: [AppKitNetwork, ...AppKitNetwork[]] = rhoChains;

export const wagmiAdapter = new WagmiAdapter({
  networks,
  projectId: config.walletConnectProjectId,
  // ssr: true
});

export const wagmiConfig = wagmiAdapter.wagmiConfig;
