import React, { createContext, useContext, useState, PropsWithChildren } from 'react';

interface Position {
  positionId: string;
  positionState: string;
}

interface PositionContextType {
  positionState: Position | null;
  setPositionField: (field: keyof Position, value: string) => void;
  resetPositionState: () => void;
}

const initialState: PositionContextType = {
  positionState: null,
  setPositionField: () => {},
  resetPositionState: () => {},
};

const PositionContext = createContext<PositionContextType>(initialState);

export const usePositionState = () => useContext(PositionContext);

export const PositionProvider: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
  const [positionState, setPositionState] = useState<Position | null>(null);

  const setPositionField = (field: keyof Position, value: string) => {
    setPositionState((prev) => {
      if (field === 'positionId') {
        return { positionId: value, positionState: '' };
      }
      return prev ? { ...prev, [field]: value } : null;
    });
  };

  const resetPositionState = () => {
    setPositionState(null);
  };

  return (
    <PositionContext.Provider value={{ positionState, setPositionField, resetPositionState }}>
      {children}
    </PositionContext.Provider>
  );
};
