import React from 'react'
import {Box, Button, ButtonProps, Text} from "grommet";
import {useAccount} from "wagmi";
import styled from "styled-components";
import {AccountMargin} from "./AccountMargin";
import {PageModal} from "../../constants";
import useCurrentFuture from "../../hooks/useCurrentFuture";
import {useProtocolData} from "../../providers/ProtocolDataProvider";
import {useActiveModal} from "../../providers/ModalsProvider";

const PrimaryButtonContainer = styled(Button)`
    padding: 4px 16px;
    background: #444CE4;
    font-family: 'Roboto',serif;
    &:hover {
        background: #444CE4;
    }
`

const DepositPrimaryButton = (props: ButtonProps) => {
  return <PrimaryButtonContainer {...props}>
    <Text size={'14px'} weight={500}>Deposit</Text>
  </PrimaryButtonContainer>
}

export const HeaderDeposit = () => {
  const { address: userAddress } = useAccount()
  const { markets } = useProtocolData()
  const { marketId } = useCurrentFuture()
  const {
    portfolioIsFetched,
    accountTotalMarginUSD
  } = useProtocolData()
  const { setActiveModal } = useActiveModal()

  const onOpenManageMargin = (marketId: string, type: 'deposit' | 'withdraw') => {
    setActiveModal(PageModal.margin, {
      type,
      marketId
    })
  }

  const depositButton = <Box onClick={() => {
    if(marketId) {
      onOpenManageMargin(marketId, 'deposit')
    } else if(markets.length > 0) {
      onOpenManageMargin(markets[0].descriptor.id, 'deposit')
    }
  }}>
    <DepositPrimaryButton />
  </Box>

  const accountButton = <AccountMargin />
  return <Box>
    {userAddress
      ? (accountTotalMarginUSD === 0 ? depositButton : accountButton)
      : (accountButton)
    }
  </Box>
}
