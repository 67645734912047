import {toast} from "react-toastify";
import {Box, Text} from "grommet";
import {bnToDecimal, formatNumber, truncateEthAddress} from "../../utils";
import {Typography} from "antd";
import React from "react";
import {VaultInfo} from "../../types";
import {CoinName} from "../../components";
import {Address} from "viem";

export const showDepositNotification = (amount: string, vault: VaultInfo, txnReceiptHash: Address) => {
  toast.success(
    <Box>
      <Box>
        <Text size={"18px"}>Vault - Deposit</Text>
      </Box>
      <Box width={"220px"} gap={"4px"} margin={{top: "8px"}}>
        <Box>
          <Box direction={"row"} justify={"between"}>
            <Box>
              <Text color={"textSecondary"}>Amount</Text>
            </Box>
            <Box direction={"row"} gap={"6px"}>
              <Text>{formatNumber(amount)}</Text>
              <CoinName name={vault.underlyingName} color={"#ADAEB8"} fontSize={"14px"} />
            </Box>
          </Box>
          <Box direction={"row"} justify={"between"}>
            <Box>
              <Text color={"textSecondary"}>In vault</Text>
            </Box>
            <Box direction={"row"} gap={"6px"}>
              <Text>{formatNumber(bnToDecimal(vault.maxWithdraw, vault.underlyingDecimals).toString())}</Text>
              <CoinName name={vault.underlyingName} color={"#ADAEB8"} fontSize={"14px"} />
            </Box>
          </Box>
        </Box>
        <Box direction={"row"} justify={"between"}>
          <Typography.Text style={{fontSize: "14px", color: "#838495"}}>Txn hash</Typography.Text>
          <Typography.Text copyable={{text: txnReceiptHash}} style={{fontSize: "14px", color: "#ADAEB8"}}>
            {truncateEthAddress(txnReceiptHash)}
          </Typography.Text>
        </Box>
      </Box>
    </Box>,
    {
      closeOnClick: false,
      autoClose: 5000,
      draggable: false,
    }
  );
};

export const showWithdrawNotification = (amount: string, vault: VaultInfo, txnReceiptHash: Address) => {
  toast.success(
    <Box width={"100%"}>
      <Box>
        <Text size={"18px"}>Vault - Redemption</Text>
      </Box>
      <Box gap={"4px"} margin={{top: "8px"}}>
        <Box>
          <Text>Your request is being processed</Text>
        </Box>
        <Box margin={{top: "8px"}}>
          <Box direction={"row"} justify={"between"}>
            <Box>
              <Text color={"textSecondary"}>Amount</Text>
            </Box>
            <Box direction={"row"} gap={"6px"}>
              <Text>{formatNumber(amount)}</Text>
              <CoinName name={vault.underlyingName} color={"#ADAEB8"} fontSize={"14px"} />
            </Box>
          </Box>
          <Box direction={"row"} justify={"between"}>
            <Box>
              <Text color={"textSecondary"}>Pending withdrawals</Text>
            </Box>
            <Box direction={"row"} gap={"6px"}>
              <Text>{formatNumber(bnToDecimal(vault.userPendingWithdrawalsAmount, vault.underlyingDecimals).toString())}</Text>
              <CoinName name={vault.underlyingName} color={"#ADAEB8"} fontSize={"14px"} />
            </Box>
          </Box>
        </Box>
        <Box direction={"row"} justify={"between"}>
          <Typography.Text style={{fontSize: "14px", color: "#838495"}}>Txn hash</Typography.Text>
          <Typography.Text copyable={{text: txnReceiptHash}} style={{fontSize: "14px", color: "#ADAEB8"}}>
            {truncateEthAddress(txnReceiptHash)}
          </Typography.Text>
        </Box>
      </Box>
    </Box>,
    {
      closeOnClick: false,
      autoClose: 5000,
      draggable: false,
      style: {
        width: "320px",
      },
    }
  );
};
