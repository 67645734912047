import React, {useEffect, useState} from 'react'
import {Box, Text} from "grommet";
import {Input, Slider, SliderSingleProps} from 'antd';
import {InputWrapper} from "../Input";
import {QuestionMark} from "../QuestionMark";
import tooltips from "../../utils/tooltips";
import {RiskDirection} from "../../types";
import styled from "styled-components";
import {Tooltip} from "react-tooltip";
import {getIconByName} from "../Icon";

const GradientSlider = styled(Slider)`
  .ant-slider-track {
    display: none;
  }

  .ant-slider-rail {
    background: linear-gradient(to right, #D65C5C, #333333, #3CB473);
  }

  .ant-slider-dot {
    width: 3px;
    height: 8px;
    border: 0;
    border-radius: 0px;
  }

  .ant-slider-mark-text { color: #9192A1 }

  .ant-slider-handle::after {
    background-color: #E4E4E8;
  }
`;

export interface RhoSliderProps extends SliderSingleProps {
  futureId?: string
  formRiskDirection: RiskDirection
  currentLeverage?: number
}

const getMarks = (currentLeverage?: number, min?: number, max?: number) => {
  const marks: Record<string, any> = {}

  if(typeof min === 'undefined' || typeof max === 'undefined') {
    return marks
  }

  marks[min] = Math.abs(min).toFixed(0) + 'x'
  marks[max] = Math.abs(max).toFixed(0) + 'x'

  if(min <= 0 && max >= 0) {
    marks[0] = '0x'
  }

  if (currentLeverage) {
    const { icon: iconTriangle } = getIconByName('triangle', '10px')

    marks[currentLeverage] = {
      style: {
        marginTop: '-40px'
      },
      label:
        <Box data-tooltip-id={'rho_slider_current_leverage'}
             data-tooltip-content={tooltips.currentLeverage}
        >
          <Text size={'14px'} color={'#D6D6DC'}>{Math.abs(currentLeverage).toFixed(0) + 'x'}</Text>
          {iconTriangle}
          <Tooltip
              id={'rho_slider_current_leverage'}
              border={'1px solid #454A55'}
              opacity={1}
              style={{
                fontSize: '14px',
                fontWeight: 'normal',
                textAlign: 'left',
              }}
          />
      </Box>
    }
  }

  return marks
}

const valueFormatter = (value: number | undefined) => {
  return typeof value !== 'undefined'
    ? Math.abs((+value)).toFixed(2)
    : ''
}

const parseStringToNumber = (value: string) => {
  let valueFormatted = value.replaceAll(/[a-zA-Z]+/g, '')
  if(valueFormatted.endsWith('.')) {
    valueFormatted = valueFormatted.substring(0, valueFormatted.length - 1);
  }
  const numberValue = +valueFormatted
  return numberValue
}

export const LeverageSlider = (props: RhoSliderProps) => {
  const [inputValue, setInputValue] = useState<string>('0.00x')
  const [isInputFocused, setInputFocused] = useState(false)
  const [lastInputTimestamp, setLastInputTimestamp] = useState(0)

  useEffect(() => {
    const lastInputDelta = Date.now() - lastInputTimestamp

    if(lastInputDelta > 1000 && typeof props.value !== 'undefined') {
      if(Math.abs(props.value - parseStringToNumber(inputValue)) > 0.01) {
        setInputValue(`${valueFormatter(props.value)}x`)
      }
    }
  }, [props.value]);

  // Dynamic dots colouring
  useEffect(() => {
    const slider = document.querySelector('.ant-slider');
    if (!slider) return;
    updateDotsColor()

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'childList') {
          updateDotsColor()
        }
      });
    });

    // Configuration of the observer:
    const config = { attributes: true, childList: true, subtree: true };
    observer.observe(slider, config);

    // Make sure to disconnect the observer when the component unmounts
    return () => observer.disconnect();
  }, []);

  function updateDotsColor() {
    const dots = document.querySelectorAll('.ant-slider-dot');

    dots.forEach((dot) => {
      if (dot instanceof HTMLElement) {
        const positionPercent = parseFloat(dot.style.left);
        dot.style.background = getColorForPosition(positionPercent);
      }
    });
  }

  function getColorForPosition(percent: number): string {
    const colorStart = [214, 92, 92] // hexToRgb('#D65C5C');
    const colorMiddle = [51, 51, 51] // hexToRgb('#333333');
    const colorEnd = [60, 180, 115] // hexToRgb('#3CB473');

    let color: number[];

    if (percent <= 50) {
      // Interpolate between start and middle colors
      color = interpolateColor(colorStart, colorMiddle, percent / 50);
    } else {
      // Interpolate between middle and end colors
      color = interpolateColor(colorMiddle, colorEnd, (percent - 50) / 50);
    }

    // Convert RGB array back to a CSS color string
    return `rgb(${color[0]}, ${color[1]}, ${color[2]})`;
  }

  function interpolateColor(color1: number[], color2: number[], factor: number): number[] {
    let result = color1.slice();
    for (let i = 0; i < 3; i++) {
      result[i] = Math.round(result[i] + factor * (color2[i] - color1[i]));
    }

    return result;
  }

  return <InputWrapper pad={'12px 12px 0'}>
    <Box direction={'row'} align={'center'} gap={'4px'}>
      <Text size={'13px'} color={'#7B7C8E'} weight={400}>Leverage</Text>
      <QuestionMark tooltipId={'rho_slider_leverage'} tooltipText={tooltips.futureLeverage} />
      {/*<Box margin={{ left: '8px' }}>*/}
      {/*  <RiskDirectionTag direction={leverageRiskDirection} />*/}
      {/*</Box>*/}
    </Box>
    <Box
      direction={'row'}
      align={'center'}
    >
      <Box width={'120px'} height={'50px'}>
      <Input
  className={'rho_slider_input'}
  value={inputValue}
  onKeyDown={(e) => {
    if (e.key === 'Delete' || e.key === 'Backspace') {
      const numericPart = inputValue.replace('x', '').trim(); 

      if (numericPart) {
        const newValue = numericPart.slice(0, -1);
        const finalValue = newValue === '' ? '0' : newValue; 
        setInputValue(finalValue + 'x');
        if (props.onChange) {
          props.onChange(parseStringToNumber(finalValue)); 
        }
      }
      e.preventDefault(); 
    }
  }}
  onChange={(e) => {
    let { value } = e.target;
    value = value.replace(/[^\d.]/g, '');
    value = value.replace(/^0+(?!\.|$)/, ''); 
    const decimalCount = (value.match(/\./g) || []).length;

    if (decimalCount > 1) {
      value = value.substring(0, value.lastIndexOf('.'));
    }
    const finalValue = value === '' ? '0' : value;
    setInputValue(finalValue + 'x');
    setLastInputTimestamp(Date.now());

    const numericValue = parseStringToNumber(finalValue);
    if (props.onChange) {
      props.onChange(isNaN(numericValue) ? 0 : numericValue);
    }

    const inputElement = e.target;
    const caretPosition = value.length; 
    setTimeout(() => {
      inputElement.setSelectionRange(caretPosition, caretPosition);
    }, 0);
  }}
  onFocus={(e) => {
    if (inputValue === '0.00x') {
      setInputValue(''); 
      setTimeout(() => {
        e.target.setSelectionRange(0, 0); 
      }, 0);
    }
    setInputFocused(true);
  }}
  onBlur={(e) => {
    const { value } = e.target;
    const inputWithoutSuffix = value.replace('x', '').trim();

    if (!inputWithoutSuffix) {
      setInputValue('0.00x');
      if (props.onChange) {
        props.onChange(0); 
      }
      return;
    }

    let numberValue = Math.abs(parseStringToNumber(inputWithoutSuffix));
    if (props.value && props.value < 0) {
      numberValue *= -1;
    }

    if (
      props.onChange &&
      typeof props.max !== 'undefined' &&
      typeof props.min !== 'undefined'
    ) {
      let newValue = null;

      if (numberValue > props.max) {
        newValue = props.max;
      } else if (numberValue < props.min) {
        newValue = props.min;
      }

      if (newValue !== null) {
        setInputValue(`${valueFormatter(newValue)}x`);
        props.onChange(newValue);
        return;
      }
    }

    setInputValue(`${valueFormatter(numberValue)}x`);
  }}
  style={{
    width: '100%',
    border: 'none',
    background: '#19191A',
    fontSize: '18px',
  }}
/>


      </Box>
      <Box width={'100%'} style={{ paddingRight:'10px' }}>
        <GradientSlider
          {...props}
          // disabled={isDisabled}
          min={props.min}
          max={props.max}
          marks={getMarks(props.currentLeverage, props.min, props.max)}
          defaultValue={props.value}
          value={props.value}
          tooltip={{
            open: false,
            formatter: valueFormatter
          }}
          onChange={(value) => {
            setInputValue(`${valueFormatter(value)}x`)
            if(props.onChange) {
              props.onChange(value)
            }
          }}
          onChangeComplete={props.onChangeComplete}
        />
      </Box>
    </Box>
  </InputWrapper>
}
