import React, {useState} from 'react'
import {Box, Text} from 'grommet'
import {getNumberText, PrimaryButton} from '../../components'
import {ModalBox, ModalRow} from '../../components/modals/modalBox'
import {useActiveModal} from '../../providers/ModalsProvider'
import {useAccount, useWriteContract} from 'wagmi'
import config from '../../config'
import RouterABI from "../../abi/RouterABI.json";
import erc20MockABI from "../../abi/erc20MockABI.json";
import {getOraclePackage, prepareFormNumber} from "../../utils";
import {RiskDirection} from "../../types";
import {useProtocolData} from '../../providers/ProtocolDataProvider'
import {Typography} from "antd";
import {toast} from "react-toastify";
import {unwindSuccessnotification} from '../../components/notifications'
import {getTradeQuote} from '../../api/quoterContract'
import {wagmiConfig} from "../../modules/wagmi";
import { useUserPositions } from '../../hooks/useUserPositions'
import { usePositionState } from '../../providers/PositionStateProvider'
import {usePublicClient} from "../../hooks/blockchainHooks";
import { formatDate, removeUSDT } from '../../mappers'
import moment from 'moment'
import { useWithdrawableMargin } from '../../hooks/useWithdrawableMargin'

export const UnwindModal = () => {
  const { modalParams } = useActiveModal()
  const client = usePublicClient()
  const { address: userAddress} = useAccount()
  const { addTx, refetchPortfolio } = useProtocolData()
  const { refetch } = useUserPositions(userAddress)
  //@ts-ignore
  const riskDirection = modalParams?.modalProps?.riskDirection || 0

  const {setPositionField} = usePositionState()

  const {
    refetch: refetchWithdrawableMargin
  } = useWithdrawableMargin(modalParams?.modalProps?.marketId)

  const underlying = modalParams?.props?.underlying
  const futureId = modalParams?.position?.futureId
  const marketId = modalParams?.modalProps?.marketId
  const tradeQuote = modalParams?.props?.tradeQuote
  const formValues = modalParams?.props?.formValues
  const underlyingName = modalParams?.modalProps?.underlyingName || ''
  const underlyingDecimals = modalParams?.modalProps?.underlyingDecimals || 0

  const notional = modalParams?.modalProps?.notional
  const decimals = modalParams?.modalProps?.underlyingDecimals

  let notionalValue = 0

  if( notional && decimals) {
    //@ts-ignore
    notionalValue = getNumberText({value: modalParams?.modalProps?.notional, decimals: modalParams?.modalProps?.underlyingDecimals} )
  }

  let notionalDisplayedValue = notionalValue

  const fullName: string = removeUSDT(modalParams?.modalProps?.marketName || '')
  let futureDate: string = ''

  if (modalParams?.props?.future) {
   futureDate = formatDate(moment((modalParams?.props?.future?.termStart?.add(modalParams?.props?.future?.termLength).toNumber() * 1000)).format('DD-MM-YYYY'))
  }

  const symbolDisplayedValue = `${fullName} ${futureDate}`
  const directionDisplayedValue =  riskDirection === 1 ? 'Long' : 'Short'

  const { closeModals } = useActiveModal()

  const selectedQuote = riskDirection === 1
  ? tradeQuote?.payerQuote
  : tradeQuote?.receiverQuote

  const selectedTradeRate = selectedQuote?.tradeInfo.tradeRate


  const expectedTradeRate = selectedTradeRate
    ? (+selectedTradeRate.toString() / 10 ** 16).toFixed(4)
    : ''



  const {
    writeContractAsync: executeTrade,
  } = useWriteContract({config: wagmiConfig})

  const onTradeClicked = async () => {
    //@ts-ignore
    const notionalValue = modalParams?.position?.notional

    try {
      if (!marketId) {
        return false
      }
      const oraclePackage = await getOraclePackage(marketId)
      const collateralValue = formValues.isNativeTokenSelected
        ? 0
        : prepareFormNumber(formValues?.collateral, underlyingDecimals)
      // const notionalValue = new Decimal(leverage).toDecimalPlaces(2).eq(0)
      //   ? zero
      //   : prepareFormNumber(formValues.notional, underlyingDecimals)
      //@ts-ignore
      const notionalValue = 0

      let maxRateLimit = formValues.maxRateLimit

      if (futureId && underlying && modalParams?.position?.notional) {

        //@ts-ignore
        const tradeQuote = await getTradeQuote(futureId, modalParams?.position?.notional, underlying, [oraclePackage])

        const tradeRate = riskDirection === 1
        ? 0
        : tradeQuote.receiverQuote.tradeInfo.tradeRate

        //@ts-ignore
        maxRateLimit = tradeRate

      }

      const txConfig = {
        args: [
          futureId,
          riskDirection !== 1  ? '1' : '0',
          notionalValue,
          prepareFormNumber(maxRateLimit, 16),
          collateralValue,
          (Date.now() + 5 * 60 * 1000),
          true,
          [oraclePackage]
        ],
        value: formValues.isNativeTokenSelected
          ? BigInt(prepareFormNumber(formValues?.collateral, underlyingDecimals).toString())
          : undefined
      }
      console.log('executeTrade params:', txConfig, 'config.gasEstimateMultiplier', config.gasEstimateMultiplier)

      let gas = undefined
      if(config.gasEstimateMultiplier) {
        try {
          gas = await client.estimateContractGas({
            address: config.routerContractAddress,
            abi: RouterABI,
            functionName: 'executeTrade',
            args: txConfig.args,
            account: userAddress as `0x${string}`
          })
          gas = BigInt(Math.round(+gas.toString() * config.gasEstimateMultiplier))
          console.log(`Using gas multiplier: ${config.gasEstimateMultiplier}, gas value: ${gas}`)
        } catch(e) {
          console.error('Failed to estimate gas', e)
        }
      }

      const resultHash = await executeTrade({
        address: config.routerContractAddress,
        abi: RouterABI,
        functionName: 'executeTrade',
        ...txConfig,
        gas
      })
      if (resultHash) {
        console.log('executeTrade transaction hash:', resultHash)
        // props.setExecuteTxHash(result.hash)
        unwindSuccessnotification(symbolDisplayedValue)

        const unwindDirection = riskDirection !== 1  ? RiskDirection.receiver : RiskDirection.payer
        setPositionField('positionState', 'loading')


        addTx({
          marketId,
          transactionHash: resultHash,
          status: 'pending',
          type: 'trade',
          amount: '0',
          underlyingName,
          direction: unwindDirection,
          rate: expectedTradeRate
        })
      }
    } catch (e) {
      console.log('Error on call executeTrade', e)
      const message = (e as Error).message
      let errorName = ''
      if (message && message.includes('Error')) {
        const [_, description] = message.split('Error:')
        if (description) {
          const [errorTitle] = description.split('\n')
          if (errorTitle) {
            errorName = errorTitle.trim()
          }
        }
      }
      toast.error(<Box>
        <Box direction={'row'} gap={'8px'}>
          <Typography.Text
            copyable={{text: message}}>
          </Typography.Text>
          <Text>Trade error</Text>
        </Box>
        {errorName &&
            <Box><Text>{errorName}</Text></Box>
        }
      </Box>, {
        toastId: 'trade_quote_error',
      })
    } finally {
      closeModals()
      refetchWithdrawableMargin()
      refetchPortfolio()
      refetch()
    }
  }

  return (
    <ModalBox title={'Position Market Close'}>
      <ModalRow
        keyTitle={'Market'}
        keyTooltip={'Market'}
        value={symbolDisplayedValue}
        style={{ marginTop: '28px' }}
      />

      <ModalRow
        keyTitle={'Position size'}
        keyTooltip={'Position size'}
        value={`${notionalDisplayedValue.toLocaleString()} USDT`}
        style={{ marginTop: '11px' }}
      />

      <ModalRow
        keyTitle={'Risk direction'}
        keyTooltip={'Risk direction'}
        value={directionDisplayedValue}
        style={{ marginTop: '11px' }}
      />

      <hr
        style={{
          marginTop: '24px',
          marginBottom: '24px',
          marginLeft: '7px',
          marginRight: '7px',
          height: '1px',
          backgroundColor: '#2D2E43',
          border: 'none',
        }}
      />

      <Box>
        <Box justify="center" width={'100%'}>
          <PrimaryButton text={'Close position'} onClick={() => onTradeClicked()} />
        </Box>
      </Box>
    </ModalBox>
  )
}
