import {Box, Table, TableBody, TableCell, TableRow, Text} from "grommet";
import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {bnToDecimal, prepareFormNumber} from "../../utils";
import {ManageMarginProps, OperationType} from "./index";
import {BigNumber} from "ethers";
import {Checkbox, MenuProps, Skeleton} from 'antd';
import {
  QuestionMark,
  FormErrorMessage,
  Switch,
  SwitchOption,
  MarketIcon, ValuesChangeBadge, TokenAmountInput, Number
} from "../../components";
import { Select as RhoSelect } from '../../components'
import {NativeToken, NetworksList} from "./constants";
import {useProtocolData} from "../../providers/ProtocolDataProvider";
import tooltips from "../../utils/tooltips";
import { removeUSDT } from "../../mappers";
import { MarketInfo } from "../../types";
import { useActiveModal } from "../../providers/ModalsProvider";

const TableContainer = styled(Table)`
    tr td:nth-child(1) {
        width: 140px;
    }
    tr td:nth-child(2) {
      width: 420px;
    }
    tr {
        td {
            text-align: left;
            padding: 0;
        }
    }
`

export const ManageMarginRequest = (props: ManageMarginProps) => {
  const {
    viewType,
    markets = [],
    market,
    underlyingName,
    fromTokenBalance,
    fromTokenDecimals,
    depositType,
    formValues,
    formError,
    withdrawableMargin,
    isSquidTokensLoading,
    availableSquidTokens,
    squidQuote,
    isSquidQuoteLoading,
    onChangeFormValue,
    totalFees,
    gasFee
  } = props

  const { isProtocolUser } = useProtocolData()

  const [selectedTokenName, setSelectedTokenName] = useState(underlyingName)

  const {activeModal} = useActiveModal()
  const totalFeesAmount = (gasFee || 0) + (totalFees || 0)

  useEffect(() => {
    if(formValues.wrapNativeToken) {
      setSelectedTokenName(NativeToken)
    } else {
      setSelectedTokenName(underlyingName)
    }
  }, [underlyingName, formValues.wrapNativeToken]);

  const switchOptions = [
    { id: 'deposit', text: 'Deposit' },
    { id: 'withdraw', text: 'Withdraw' }
  ]

  const getFormatedName = (market: MarketInfo) => {
    let marketType = market?.descriptor?.tag
     marketType = marketType.charAt(0).toUpperCase() + marketType.slice(1).toLowerCase();
    return `${market.descriptor.sourceName} ${removeUSDT(market.descriptor.instrumentName)} ${marketType}`
  }
  
  const onChange = (option: SwitchOption) => onChangeFormValue({ 'operation': option.id as OperationType })

  const marketsSelectOptions: MenuProps['items'] = markets
    .map((market) => {
      return {
        key: market.descriptor.id,
        label: <Box
          direction={'row'}
          gap={'8px'}
          align={'center'}
        >
          <MarketIcon name={market.descriptor.sourceName} size={'30px'} iconSize={'15px'} />
          <Text>{getFormatedName(market)}</Text>
        </Box>,
      }
    })

  const networkSourceSelect: MenuProps['items'] = NetworksList
    .map((network) => {
      return {
        key: network.id,
        label: <Box
          direction={'row'}
          gap={'8px'}
          align={'center'}
        >
          <Text>{network.name}</Text>
        </Box>,
      }
    })

    const handleMarketChange = (id: string) => {
      props.setMarketId(id)
      onChangeFormValue({
        'wrapNativeToken': false
      })
    }

    useEffect(() => {
      if(!activeModal) {
        onChangeFormValue({
          'wrapNativeToken': false
        })      }
    }, [activeModal])

  const tokenSourceSelect: MenuProps['items'] = availableSquidTokens
    .map((item) => {
      const { symbol } = item
      return {
        key: symbol,
        label: <Box
          direction={'row'}
          gap={'8px'}
          align={'center'}
        >
          <Text>{symbol}</Text>
        </Box>,
      }
    })

  const nextBalance = fromTokenBalance.sub(prepareFormNumber(formValues.amount, fromTokenDecimals))
  const nextToWithdraw = withdrawableMargin.sub(prepareFormNumber(formValues.amount, fromTokenDecimals))

  const valueFrom = (formValues.operation === 'deposit') ? fromTokenBalance : withdrawableMargin
  const valueTo = formValues.operation === 'deposit' ? nextBalance : nextToWithdraw

  const inputBottomPanel = <Box pad={'0 10px 0'}>
    <Box background={'#272835'} width={'100%'} height={'1px'} />
    <Box direction={'row'} justify={'between'} align={'center'}>
      <Box direction={'row'} margin={{ top: '8px', bottom: '8px' }} gap={'10px'} align={'center'}>
        <Box direction={'row'} align={'center'} gap={'4px'}>
          <Text color={'textSecondary'}>
            {formValues.operation === 'deposit'
              ? 'Wallet'
              : 'Available'}
          </Text>
          <QuestionMark tooltipId={'margin_mgmt_wallet'} tooltipText={formValues.operation === 'deposit' ? 'User balance' : 'Available to withdraw'} />
        </Box>

        
        <ValuesChangeBadge


          from={valueFrom}


          to={valueTo}
          decimals={fromTokenDecimals}
          name={selectedTokenName}
          showName={false}
          fontSize={'12px'}
        />
      </Box>
      {viewType !== 'mobile' &&
          <Box direction={'row'} gap={'4px'}>
            {[25, 50, 75, 100].map((value) => {
              return <Box key={value} background={'optionBg'} round={'4px'} pad={'4px 7px'} onClick={() => {
                try {
                  const amount = bnToDecimal(
                    formValues.operation === 'deposit' ? fromTokenBalance : withdrawableMargin
                    , fromTokenDecimals)
                  const amountDecimal = amount.mul(value / 100)
                  onChangeFormValue({ 'amount': amountDecimal.toString() })
                } catch (e) {
                  console.error('Failed to format amount', fromTokenBalance)
                }
              }}>
                <Text size={'12px'} color={'accentWhite2'}>{value}%</Text>
              </Box>
            })}
          </Box>
      }
    </Box>
  </Box>

  return <Box>
    <TableContainer>
      <TableBody>
        <TableRow>
          <TableCell>
            <Box direction={'row'} align={'center'} gap={'4px'}>
              <Text color={'textSecondary'}>Market</Text>
              <QuestionMark tooltipId={'margin_mgmt_market'} tooltipText={'Market'} />
            </Box>
          </TableCell>
          <TableCell>
            <RhoSelect
              height={'60px'}
              items={marketsSelectOptions}
              onClick={(id) => handleMarketChange(id)}
              placeholder={
                <Box width={'100%'} direction={'row'} gap={'8px'} align={'center'}>
                  <MarketIcon name={market.descriptor.sourceName} size={'34px'} iconSize={'20px'} />
                  <Text color={'accentWhite2'}>
                    {getFormatedName(market)}
                  </Text>
                </Box>
              }
              onUpdate={() => {}}
            />
          </TableCell>
        </TableRow>
        {props.depositType === 'direct' &&
            <TableRow>
                <TableCell>
                    <Box margin={{ top: '24px' }} direction={'row'} align={'center'} gap={'4px'}>
                        <Text color={'textSecondary'}>Operation</Text>
                        <QuestionMark tooltipId={'margin_mgmt_operation'} tooltipText={'Operation'} />
                    </Box>
                </TableCell>
                <TableCell>
                    <Box margin={{ top: '24px' }} background={'inputBg'} pad={'10px'} round={'6px'}>
                        <Switch
                            height={'40px'}
                            options={switchOptions}
                            optionId={formValues.operation}
                            align={'center'}
                            onChange={onChange}
                        />
                    </Box>
                </TableCell>
            </TableRow>
        }
        {props.depositType === 'cross-chain' &&
            <TableRow>
                <TableCell>
                    <Box margin={{ top: '24px' }} direction={'row'} align={'center'} gap={'4px'}>
                        <Text color={'textSecondary'}>Source</Text>
                        <QuestionMark tooltipId={'margin_mgmt_source'} tooltipText={'Source network'} />
                    </Box>
                </TableCell>
                <TableCell>
                    <Box margin={{ top: '24px' }} justify={'between'} direction={'row'}>
                        <RhoSelect
                            width={'200px'}
                            height={'60px'}
                            items={networkSourceSelect}
                            onClick={(id) => {
                              props.onChangeFormValue({
                                sourceChainId: +id,
                                amount: '0'
                              })
                            }}
                            placeholder={
                              <Box width={'100%'} direction={'row'} gap={'8px'} align={'center'}>
                                <Text color={'accentWhite2'}>
                                  {NetworksList.find(item => +item.id === +formValues.sourceChainId)?.name || 'n/a'}
                                </Text>
                              </Box>
                            }
                            onUpdate={() => {}}
                        />
                        <RhoSelect
                            width={props.viewType === 'default' ? '200px' : 'unset'}
                            height={'60px'}
                            loading={isSquidTokensLoading}
                            items={tokenSourceSelect}
                            onClick={(id) => props.onChangeFormValue({ 'tokenSymbol': id })}
                            placeholder={
                              <Box width={'100%'} direction={'row'} gap={'8px'} align={'center'}>
                                <Text color={'accentWhite2'}>
                                  {availableSquidTokens.find(item => item.symbol === formValues.tokenSymbol)?.symbol || 'n/a'}
                                </Text>
                              </Box>
                            }
                            onUpdate={() => {}}
                        />
                    </Box>
                </TableCell>
            </TableRow>
        }
        <TableRow>
          <TableCell>
            <Box direction={'row'} margin={{ top: '24px' }} align={'center'} gap={'4px'}>
              <Text color={'textSecondary'}>Amount</Text>
              <QuestionMark tooltipId={'margin_mgmt_amount'} tooltipText={'Amount'} />
            </Box>
          </TableCell>
          <TableCell>
            <Box margin={{ top: '24px' }}>
              <Box>
                <TokenAmountInput
                  id={'margin_mgmt_amount'}
                  value={formValues.amount}
                  autoFocus={isProtocolUser}
                  status={formError && formError.field === 'notional' ? 'error' : ''}
                  options={[{
                    text: formValues.wrapNativeToken ? NativeToken : underlyingName
                  }]}
                  bottompanel={inputBottomPanel}
                  onChange={(value) => onChangeFormValue({ 'amount': (value || 0).toString() })}
                />
              </Box>
              {underlyingName === 'WETH' &&
                  <Box margin={{ top: '8px' }}>
                      <Checkbox
                          checked={props.formValues.wrapNativeToken}
                          onChange={(e) => onChangeFormValue({
                            'wrapNativeToken': e.target.checked
                          })}
                      >
                          Use ETH to {formValues.operation}
                      </Checkbox>
                  </Box>
              }
              {formError && formError.field === 'amount' &&
                  <FormErrorMessage formError={formError} />
              }
            </Box>
          </TableCell>
        </TableRow>
        {(props.depositType === 'cross-chain' && (props.isSquidQuoteLoading || !!props.squidQuote)) &&
            <TableRow>
                <TableCell>
                    <Box direction={'row'} margin={{ top: '24px' }} align={'center'} gap={'4px'}>
                        <Text color={'textSecondary'}>Summary</Text>
                        <QuestionMark tooltipId={'margin_mgmt_summary'} tooltipText={'Summary'} />
                    </Box>
                </TableCell>
                <TableCell>
                    <Box
                        margin={{ top: '24px' }}
                        pad={'10px 16px'}
                        border={{ color: '#2D2E43', size: '1px' }}
                        round={'6px'}
                        gap={'4px'}
                    >
                      <Box direction={'row'} justify={'between'}>
                        <Text color={'textSecondary'}>Expected deposit</Text>
                        {isSquidQuoteLoading &&
                            <Skeleton.Input size={'small'} active />
                        }
                        {(!isSquidQuoteLoading && squidQuote) &&
                            <Number
                                value={BigNumber.from(squidQuote.route.estimate.toAmount)}
                                decimals={squidQuote.route.estimate.toToken.decimals}
                                name={squidQuote.route.estimate.toToken.symbol}
                                showName={true}
                                fontColor={'accentWhite2'}
                            />
                        }
                      </Box>
                      <Box direction={'row'} justify={'between'}>
                      <Box direction={'row'} align="center" gap='4px'>
                        <Text color={'textSecondary'}>Expected fees</Text>
                        <QuestionMark tooltipId={'squid_fee'} width='200px' tooltipText={`Gas fee: ${gasFee}, Bridge fee: ${totalFees}`} />
                      </Box>
                        {isSquidQuoteLoading &&
                            <Skeleton.Input size={'small'} active />
                        }
                        {(!isSquidQuoteLoading && squidQuote) &&
                         <Text color={'accentWhite2'}>{totalFeesAmount.toFixed(2)} USDT</Text>
                        }
                      </Box>

                      {/* <Box direction={'row'} justify={'between'}>
                        <Text color={'textSecondary'}>Expected gas fee</Text>
                        {isSquidQuoteLoading &&
                            <Skeleton.Input size={'small'} active />
                        }
                        {(!isSquidQuoteLoading && squidQuote) &&
                         <Text color={'accentWhite2'}>{gasFee} USDT</Text>

                        }
                      </Box> */}
                      <Box direction={'row'} justify={'between'}>
                        <Text color={'textSecondary'}>Expected time</Text>
                        {isSquidQuoteLoading &&
                            <Skeleton.Input size={'small'} active />
                        }
                        {(!isSquidQuoteLoading && squidQuote) &&
                            <Text color={'accentWhite2'}>
                              {squidQuote.route.estimate.estimatedRouteDuration}s
                            </Text>
                        }
                      </Box>
                    </Box>
                </TableCell>
            </TableRow>
        }
        {(props.depositType === 'cross-chain' && props.squidQuoteErrorMessage) &&
            <TableRow>
                <TableCell>
                    <Box direction={'row'} margin={{ top: '24px' }} align={'center'} gap={'4px'}>
                        <Text color={'textSecondary'}>Error</Text>
                        <QuestionMark tooltipId={'sq_quote_error'} tooltipText={'Squid quote error'} />
                    </Box>
                </TableCell>
                <TableCell>
                    <Box
                        margin={{ top: '24px' }}
                        pad={'10px 16px'}
                        border={{ color: '#2D2E43', size: '1px' }}
                        round={'6px'}
                        gap={'4px'}
                    >
                        <Box direction={'row'} justify={'between'}>
                            <Box>
                                <Text color={'errorMessage'}>
                                  {props.squidQuoteErrorMessage}
                                </Text>
                            </Box>
                        </Box>
                    </Box>
                </TableCell>
            </TableRow>
        }
        <TableRow>
          <Box margin={{ top: '32px' }}></Box>
        </TableRow>
      </TableBody>
    </TableContainer>
  </Box>
}
