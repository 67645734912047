import {TradeLimiterParameters} from "../types";
import {BigNumber} from "ethers";

export enum RhoLsKey {
  bottomPanelVisibilityStatus = 'rho_client_bottom_panel_visibility_status',
  lastUsedMarket = 'rho_last_used_market_v1',
  tradeLimiterParams = 'rho_market_trade_limiter_params_v1',
  lastUsedMarketFunding = 'rho_last_used_market_funding_v1',
  lastUsedMarketStaking = 'rho_last_used_market_staking_v1',

}

const DefaultTTL = 24 * 60 * 60

export const setLsKey = (key: RhoLsKey, value: string) => {
  return window.localStorage.setItem(key, value)
}

export const getLsKey = (key: RhoLsKey) => {
  return window.localStorage.getItem(key)
}

export const getLastUsedMarketAlias = (currentMarketType: string) => {
  if (currentMarketType == 'funding') {
    return localStorage.getItem(RhoLsKey.lastUsedMarketFunding) || null
  } else {
    return localStorage.getItem(RhoLsKey.lastUsedMarketStaking) || null
  }

}

export const setLastUsedMarketAlias = (marketAlias: string, currentMarketType: string) => {
  if (currentMarketType == 'funding') {
    localStorage.setItem(RhoLsKey.lastUsedMarketFunding, marketAlias)
  } else {
    localStorage.setItem(RhoLsKey.lastUsedMarketStaking, marketAlias)
  }
}

export const setMarketTradeLimiterParamsToLS = (
  marketId: string,
  params: TradeLimiterParameters,
  ttl = DefaultTTL
) => {
  localStorage.setItem(`${RhoLsKey.tradeLimiterParams}_${marketId}`, JSON.stringify({
    marketId: marketId,
    params,
    timestamp: Date.now(),
    expirationTimestamp: Date.now() + ttl * 1000
  }))
}

export const getMarketTradeLimiterParamsFromLS = (marketId: string) => {
  const data = localStorage.getItem(`${RhoLsKey.tradeLimiterParams}_${marketId}`)
  if(data) {
    try {
      const parsedData: {
        expirationTimestamp: number
        params: TradeLimiterParameters
      } = JSON.parse(data)

      if(Date.now() < parsedData.expirationTimestamp) {
        return {
          ...parsedData.params,
          marketRateChangeLimit: BigNumber.from(parsedData.params.marketRateChangeLimit),
          maxTradeNotional: BigNumber.from(parsedData.params.maxTradeNotional),
        }
      }
    } catch (e) {}
  }
  return null
}
