import React, {createContext, useContext, useState, PropsWithChildren} from "react";
import {PageModal} from "../constants";
import {OpenPosition} from "../api/dataService";
import {usePositionState} from "./PositionStateProvider";
import {arbitrum, arbitrumSepolia} from "viem/chains";
import config from "../config";
import {switchNetwork, useNetwork} from "../hooks/blockchainHooks";

interface ModalProps {
  notional: number;
  marketName: string;
  direction: string;
  underlyingDecimals?: number;
  underlyingName?: string;
  marketId?: string;
}
interface ModalParams {
  marketId?: string;
  type?: "deposit" | "withdraw";
  position?: OpenPosition;
  modalProps?: ModalProps;
  props?: any;
}

export interface ModalsState {
  activeModal: PageModal | null;
  modalParams?: ModalParams;
  setActiveModal: (newModal: PageModal | null, params?: ModalParams) => void;
  closeModals: () => void;
}

const getInitialState = (): ModalsState => {
  return {
    activeModal: null,
    modalParams: undefined,
    setActiveModal: () => {},
    closeModals: () => {},
  };
};

const initialState = getInitialState();
const UserDataContext = createContext(initialState);

export const useActiveModal = () => useContext(UserDataContext);

export const ModalsProvider: React.FC<PropsWithChildren<unknown>> = ({children}) => {
  const [activeModal, setActiveModal] = useState<PageModal | null>(null);
  const [modalParams, setModalParams] = useState<ModalParams>();
  const {resetPositionState} = usePositionState();
  const {chain} = useNetwork();
  const urlParams = new URLSearchParams(window.location.search);
  const networkParam = urlParams.get("network");
  const closeModals = () => {
    // if (activeModal === PageModal.margin) {
    //   let targetChainId;
    //   switch (networkParam) {
    //     case "mainnet":
    //       targetChainId = arbitrum.id;
    //       break;
    //     case "testnet":
    //       targetChainId = arbitrumSepolia.id;
    //       break;
    //     default:
    //       targetChainId = config.chainId;
    //   }
    //   switchNetwork({chainId: targetChainId});
    // }

    setActiveModal(null);
    setModalParams(undefined);
  };

  return (
    <UserDataContext.Provider
      value={{
        activeModal,
        modalParams,
        setActiveModal: (newModal: PageModal | null, params?: ModalParams) => {
          setActiveModal(newModal);
          setModalParams(params);
        },
        closeModals,
      }}
    >
      {children}
    </UserDataContext.Provider>
  );
};
