import { useEffect, useState } from "react";
import { Box, Text } from "grommet";
import { MarketIcon } from "./Icon";
import { useProtocolData } from "../providers/ProtocolDataProvider";

export default function CryptoBubbleSelector({ items, setSelect }: any) {
  const [active, setActive] = useState<string | null>(null);

  const {
    currentMarketType
  } = useProtocolData()

  const handleOnSelect = (item: string) => {
    setActive(item);
    setSelect(item);

    if (item == active || item == 'All') {
       setActive('');
       setSelect('');
    }
  }

  useEffect(() => {
    if (currentMarketType == 'staking') {
      setActive('');
      setSelect('');
    }
  }, [currentMarketType])

  const getStyles = (item: string) => ({
    border: active === item ? { color: "#E5E5E5", size: "1px" } : {},
    background: active === item ? "#26262b" : "#2f2f34",
    hoverIndicator: { background: "#26262b" },
  });

  return (
    
    <Box direction="row" gap="small" width="100%" justify="start" align="center">
      {currentMarketType != 'staking' &&
      items.map((item: any) => (
        <Box
          key={item}
          direction="row"
          align="center"
          pad="4px 12px"
          gap="8px"
          height="30px"
          round="large"
          justify="end"
          focusIndicator={false}
          onClick={() => handleOnSelect(item)}
          {...getStyles(item)}
        >
          <MarketIcon name={item} size="20px" iconSize="20px" borderSize="1px" />
          <Text weight={500} color="#textSecondary">
            {item}
          </Text>
        </Box>
      ))
    }
    </Box>
  );
}
