import React from "react";
import styled, { keyframes } from "styled-components";

// Define the spinning animation
const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

// Container for centering
const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

// Background circle (static)
const BackgroundCircle = styled.div`
  width: ${({ size }) => size || "50px"};
  height: ${({ size }) => size || "50px"};
  border: ${({ thickness }) => thickness || "5px"} solid ${({ bgColor }) => bgColor || "#e0e0e0"};
  border-radius: 50%;
  position: absolute;
`;

// Spinning circle (animated)
const SpinnerCircle = styled.div`
  width: ${({ size }) => size || "50px"};
  height: ${({ size }) => size || "50px"};
  border: ${({ thickness }) => thickness || "5px"} solid transparent;
  border-top: ${({ thickness, color }) => `${thickness || "5px"} solid ${color || "#3498db"}`};
  border-radius: 50%;
  animation: ${spin} 1s linear infinite;
  position: absolute;
`;

// Wrapper to position both circles
const SpinnerWrapper = styled.div`
  position: relative;
  top: -2.5px;
  width: ${({ size }) => size || "50px"};
  height: ${({ size }) => size || "50px"};
`;

export const RhoSpinner = ({ size = '14px', thickness = '1px', color = '#5358e3', bgColor = '#353535' }) => (
  <SpinnerContainer>
    <SpinnerWrapper size={size}>
      <BackgroundCircle size={size} thickness={thickness} bgColor={bgColor} />
      <SpinnerCircle size={size} thickness={thickness} color={color} />
    </SpinnerWrapper>
  </SpinnerContainer>
);


