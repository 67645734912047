import styled from "styled-components";
import {Box, Text} from "grommet";
import {NotificationBadge} from "../Badge";
import config from "../../config";

import {switchNetwork, useNetwork} from "../../hooks/blockchainHooks";

export const WidgetContainer = styled(Box)`
    padding: 16px;
    border-radius: 12px;
    background: #1E1E20;
    position: relative;
    height: inherit;
`

export const WidgetsBox = styled(Box)<{ overlay?: boolean }>`
  opacity: ${props => props.overlay ? 0.3 : 1};
  transition: opacity 150ms;
  filter: ${props => props.overlay ? 'blur(4px)' : 'unset'};
`

export const UnsupportedNetwork = () => {
  const { chains } = useNetwork()
  const supportedChain = chains.find(item => item.id === config.chainId)

  const onClick = async () => {
    try {
      if(supportedChain) {
        await switchNetwork({
          chainId: supportedChain.id
        })
      }
    } catch (e) {
      console.error('Failed to switch network:', e)
    }
  }

  return <NotificationBadge
    type={'warning'}
    title={''}
    description={supportedChain && <Box onClick={onClick}
    >
        <Box pad={'8px'}>
            <Text color={'textWarning'} style={{ textDecoration: 'underline' }}>
                Switch to {supportedChain.name}
            </Text>
        </Box>
    </Box>}
  />
}

export * from './MarketMargin'
export * from './MarketLeverage'
