import React, { FC, ReactNode, CSSProperties } from 'react'
import { Box, Text } from 'grommet'
import styled from 'styled-components'
import { QuestionMark, MarketIcon } from '../../components'
import { ReactComponent as CrossImg } from '../../assets/images/cross.svg'
import { Button as AntdButton } from 'antd'
import { useActiveModal } from '../../providers/ModalsProvider'
import { PrimaryButton } from '../../components'

interface ModalBoxProps {
  title: string
  children: ReactNode
  className?: string // For styled-components or CSS modules
  style?: CSSProperties // For inline styles
}

interface ModalRowProps {
  keyTitle: string
  keyTooltip: string
  value: string | number
  className?: string // For styled-components or CSS modules
  style?: CSSProperties // For inline styles
}

export const ModalRow: FC<ModalRowProps> = ({
  keyTitle,
  keyTooltip,
  value,
  className,
  style,
}) => {
  return (
    <Box
      direction="row"
      align="center"
      justify="between"
      className={className}
      style={style}
    >
      <Box direction={'row'} align={'center'} gap={'4px'}>
        <Text color={'textSecondary'} size={'14px'}>
          {keyTitle}
        </Text>
        <QuestionMark tooltipId={keyTitle} tooltipText={keyTooltip} />
      </Box>
      <Box direction={'row'} align={'center'}>
        <Text color={'textSecondary'} size={'14px'}>
          {value}
        </Text>
      </Box>
    </Box>
  )
}

export const ModalBox: FC<ModalBoxProps> = ({
  title,
  children,
  className,
  style,
}) => {
  const { activeModal, setActiveModal, closeModals, modalParams } =
    useActiveModal()

  return (
    <Box pad="26px" className={className} style={style}>
      <Box direction="row" justify="between" align="center">
        <Text color="textHeader" size="20px" weight={500}>
          {title}
        </Text>
        <AntdButton type="text" onClick={closeModals}>
          <Box justify="center" align="center">
            <CrossImg />
          </Box>
        </AntdButton>
      </Box>
      {children}
    </Box>
  )
}