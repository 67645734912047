import {getSquidStatus, SquidRouterQuote, SquidStatus, SquidStatusType} from "../api/squid";
import {useAccount, useSendTransaction} from "wagmi";
import {useState} from "react";
import {waitForTransactionReceipt, writeContract} from "@wagmi/core";
import {getAllowance} from "../api/erc20Contract";
import {BigNumber} from "ethers";
import {toast} from "react-toastify";
import USDT_ERC20ABI from '../abi/USDT_ERC20ABI.json'
import ERC20ABI from '../abi/erc20MockABI.json'
import {USDTAddress} from "../pages/margin-management/constants";
import {wagmiConfig} from "../modules/wagmi";
import {switchNetwork, useNetwork} from "./blockchainHooks";

export interface SquidTransactionRequest {
  transactionHash: string
  quote: SquidRouterQuote
  status?: SquidStatus
  timestamp: number
}

export const useSquidRouteExecute = () => {
  const { chain } = useNetwork()
  const { address: userAddress } = useAccount()
  const { data: hash, sendTransactionAsync } = useSendTransaction()

  const [inProgress, setInProgress] = useState<boolean>(false)
  const [requests, setRequests] = useState<SquidTransactionRequest[]>([])

  const execute = async (quote: SquidRouterQuote) => {
    setInProgress(true)
    try {
      const chainId = +quote.route.params.fromChain

      if(chainId !== chain?.id) {
        await switchNetwork({ chainId })
      }

      const isUSDT = quote.route.params.fromToken.toLowerCase() === USDTAddress
      const TokenABI = isUSDT ? USDT_ERC20ABI : ERC20ABI

      let allowance = null
      try {
        allowance = await getAllowance(
          quote.route.params.fromToken as `0x${string}`,
          userAddress as string,
          quote.route.transactionRequest.target as `0x${string}`,
          chainId
        )
      } catch (e) {
        // Ignore failed allowance for native tokens (ETH, MATIC)
      }

      console.log("[useSquidRouteExecute] allowance:", allowance ? allowance.toString() : allowance)

      try {
        if(
          allowance !== null &&
          allowance.lt(BigNumber.from(quote.route.params.fromAmount))
        ) {
          const erc20Address = quote.route.params.fromToken
          const spender = quote.route.transactionRequest.target
          const amount = BigInt(quote.route.params.fromAmount)

          // First, you have to set allowance = 0 for USDT
          if(isUSDT && allowance.gt(BigNumber.from(0))) {
            const approveTxHash = await writeContract(wagmiConfig, {
              address: erc20Address as `0x${string}`,
              abi: TokenABI as any,
              functionName: 'approve',
              args: [spender as `0x${string}`, 0n],
              chainId
            })
            await waitForTransactionReceipt(wagmiConfig, { hash: approveTxHash, chainId, confirmations: 1 })
          }

          // Set actual allowance
          console.log(`Approve params: ERC20 contract address: ${erc20Address}, spender: ${spender}, amount: ${amount}`)
          const approveTxHash = await writeContract(wagmiConfig, {
            address: erc20Address as `0x${string}`,
            abi: TokenABI as any,
            functionName: 'approve',
            args: [spender as `0x${string}`, amount],
            chainId
          })
          console.log('[useSquidRouteExecute] Approve tx hash: ', approveTxHash)
          await waitForTransactionReceipt(wagmiConfig, { hash: approveTxHash, chainId, confirmations: 1 })
        }
      } catch (e) {
        console.error("[useSquidRouteExecute] Failed to update allowance:", e)

        toast.error(`Failed to update allowance. Try again later.`, {
          closeOnClick: false,
        })
        return
      }

      const transactionRequest = quote.route.transactionRequest;

      const resultHash = await sendTransactionAsync({
        data: transactionRequest.data as `0x${string}`,
        to: transactionRequest.target,
        value: transactionRequest.value as any,
        gas: transactionRequest.gasLimit as any,
        gasPrice: transactionRequest.gasPrice as any,
      })

      console.log('[useSquidRouteExecute]: transaction hash', resultHash)

      const txRequest: SquidTransactionRequest = {
        transactionHash: resultHash,
        quote: {...quote},
        timestamp: Date.now(),
      }

      setRequests((currentValues) => [txRequest, ...currentValues])
      pollStatus(txRequest)

      return txRequest
    } catch (e) {
      setInProgress(false)
      throw new Error((e as Error).message)
    } finally {
      setInProgress(false)
    }
  }

  const sleep = (ms: number) => new Promise(r => setTimeout(r, ms));

  const pollStatus = async (request: SquidTransactionRequest) => {
    const { transactionHash, quote } = request

    for(let i = 0; i < 200; i++) {
      try {
        const status = await getSquidStatus({
          requestId: quote.requestId,
          transactionId: transactionHash,
          fromChainId: +quote.route.params.fromChain,
          toChainId: +quote.route.params.toChain
        })

        setRequests((currentRequests) => currentRequests.map(item => {
          if(item.transactionHash === transactionHash) {
            return {
              ...item,
              status
            }
          }
          return item
        }))

        if([
          SquidStatusType.EXPRESS_EXECUTED,
          SquidStatusType.DEST_ERROR,
          SquidStatusType.DEST_ERROR,
          SquidStatusType.SUCCESS
        ].includes(status.status)) {
          console.log(`useSquidRouteExecute: transaction ${transactionHash} success! Squid status: ${status}.`)
          break
        }
      } catch (e) {
        console.error(`Failed to get squid status ${transactionHash}:`, e)
      } finally {
        await sleep(5000)
      }
    }
  }

  return {
    execute,
    inProgress,
    requests
  }
}
