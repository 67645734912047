import styled from 'styled-components';
import {Anchor, Text} from 'grommet'
import React from "react";
import {getExplorerUrl} from "../utils";

import {useNetwork} from "../hooks/blockchainHooks";

export const H5HeadLine = styled(Text)`
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 22px;
`

export interface ExplorerLinkProps {
  text?: string
  route: string
}

export const ExplorerLink = (props: ExplorerLinkProps) => {
  const { text = 'Show transaction in Explorer', route } = props

  const { chain } = useNetwork()
  const explorerUrl = getExplorerUrl(chain?.id)

  return <Anchor href={`${explorerUrl}${route}`} target={'_blank'}>
    <Text weight={400} color={'textSecondary'}>{text}</Text>
  </Anchor>
}
