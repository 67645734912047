import {getIconByName} from "./Icon";
import {Box, Text} from "grommet";
import React from "react";
import { getMapperForUSDT } from "../mappers";

export const CoinName = (props: { name: string; color?: string; fontSize?: string }) => {
  const { color = 'textSecondary', fontSize = '12px' } = props

  const { icon: minorIcon } = getIconByName(props.name)

  return <Box direction={'row'} align={'center'} justify={'center'} gap={'4px'}>
    <Box
      width={'14px'}
      height={'14px'}
      round={'14px'}
    >
      {minorIcon}
    </Box>
    <Text color={color} size={fontSize}>{getMapperForUSDT(props.name)}</Text>
  </Box>

}
