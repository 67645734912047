import axios from "axios";
import config from "../config";

const client = axios.create({
  baseURL: `${config.vaultsApiUrl}/api/v1`,
  headers: {
    'Accept': 'application/json',
  }
})


const clientVault = axios.create({
  baseURL: `https://mainnet-vaults-api.rhoservice.com/api/v1`,
  headers: {
    'Accept': 'application/json',
  }
})


export type VaultOpType = 'deposit' | 'withdrawal' | 'withdrawal_request'

export const VaultOpTypeAlias: Record<VaultOpType | 'all', string> = {
    'all': 'All transactions',
    'deposit': 'Deposit',
    'withdrawal': 'Withdrawal',
    'withdrawal_request': 'Withdrawal Request'
}

export interface GetHistoryParamsBase {
  opType?: VaultOpType
  userAddress?: string
}

export interface GetHistoryParams extends GetHistoryParamsBase {
  offset?: number
  limit?: number
}

export interface VaultHistoryItem {
  txHash: string
  blockNumber: number
  blockTimestamp: string
  opType: VaultOpType
  vaultId: string
  sender: string
  owner: string
  receiver: string
  assets: string
  shares: string
}

export interface VaultPortfolio {
  vaultId: string
  netBalance: number
}

export interface VaultStats {
  profitPercentage: string
  timestamp: string;
  totalValueLocked: string
}

export const getVaultHistory = async (params: GetHistoryParams) => {
  const { data } = await client.get<VaultHistoryItem[]>(`/history`, {
    params,
  })
  return data
}

export const getVaultHistoryCount = async (params: GetHistoryParamsBase) => {
  const { data } = await client.get<number>(`/history/count`, {
    params,
  })
  return data
}

export const getVaultPortfolio = async (params: { userAddress: string }) => {
  const { data } = await client.get<VaultPortfolio>(`/portfolio`, {
    params,
  })
  return data
}

export const getVaultStats = async (params: { fromDate: string | number, limit: number }) => {
  const { data } = await clientVault.get<VaultStats[]>(`/vault-stats`, {
    params,
  })
  return data
}