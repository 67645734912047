import React from 'react'
import { Box } from 'grommet'
import { Deposited } from './Deposited'
import { Rewards } from './Rewards'
import { VaultInfoPage } from './VaultInfo'
import { EarnHeader } from './Header'
import { VaultProps } from './common'
import { useVault } from '../../providers/VaultDataProvider'
import { TransactionsHistory } from './TransactionsHistory'
import { useMediaQuery } from 'react-responsive'
import { breakpoints } from '../../utils/breakpoints'
import { MainWidget } from './new/index'
import Info from './new/info'
import { EarnHistoryChart } from './new/history-chart'

export const EarnPage = () => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${breakpoints.mobile})`,
  })

  const {
    vaults,
    vault,
    vaultPortfolio,
    isInitialLoading: isVaultLoading,
    refetch: refetchVaultBalance,
  } = useVault()

  const vaultProps: VaultProps = {
    vaults,
    vault,
    vaultPortfolio,
    isVaultLoading,
    refetchVaultBalance,
  }

  return (
    <Box
      margin={{ bottom: '42px' }}
      pad={isMobile ? '0 16px' : '0px'}
      style={{
        minWidth: isMobile ? 'unset' : '700px',
        position: 'relative',
        top: '-20px'
      }}
    >
      <Box margin={{ top: '36px' }}>
        <MainWidget />
      </Box>

      <Box margin={{ top: '26px' }}>
        <Info />
      </Box>

      <Box margin={{ top: '30px' }}>
        <EarnHistoryChart />
      </Box>

      <Box margin={{ top: '30px' }}>
        <TransactionsHistory {...vaultProps} />
      </Box>
    </Box>
  )
}
