import moment from "moment/moment";
import {getFutureRates} from "../../../api/dataService";
import {DatafeedConfiguration, PeriodParams} from "../../../charting_library";
import {SupportedResolutions} from "./constants";
import {getFloatingRates} from "../../../api/oracleService";
import { getActiveMarketsInfo } from "../../../api/viewContract";
import { getMarketByFutureId } from "../../../utils";
export interface DatafeedSymbolInfo {
  symbol: string;
  full_name: string;
  description?: string;
  exchange?: string;
  ticker: string;
  type?: string;
}

const configurationData: DatafeedConfiguration = {
  // Represents the resolutions for bars supported by your datafeed
  supported_resolutions: SupportedResolutions,
  // The `exchanges` arguments are used for the `searchSymbols` method if a user selects the exchange
  exchanges: [
    { value: 'Rho', name: 'Rho', desc: 'Rho'},
  ],
  // The `symbols_types` arguments are used for the `searchSymbols` method if a user selects this symbol type
  symbols_types: [
    { name: 'crypto', value: 'crypto'}
  ]
};

export interface DatafeedParams {
  marketId: string
  futureId: string
  timestampFrom: number
  symbols: DatafeedSymbolInfo[]
}

const subscribeIntervals: Record<string, NodeJS.Timeout> = {}
let isSubscribeRequestRunning = false

const getSafetyInterval = (interval: string) => {
  switch (interval) {
    case '1h': return 60 * 60 - 1; // 59 minutes
    case '4h': return 60 * 60 * 2 - 1; // 1h 59 minutes
    case '1m': return 240; // 4 minutes
    case '5m': return 300; // 6 minutes 
    default: return 600; // 10 minutes (default) for unknown or larger intervals
  }
}

const getSafetyHighInterval = (interval: string) => {

  const MAX_SMALL_AMOUNT_DURATION = 5 * 24 * 60 * 60; // 5 days in seconds
  const MAX_MEDIUM_AMOUNT_DURATION = 10 * 24 * 60 * 60; // 10 days in seconds
  const MAX_LARGE_AMOUNT_DURATION = 20 * 24 * 60 * 60; // 20 days in seconds


  switch (interval) {
    case '1h': return MAX_MEDIUM_AMOUNT_DURATION; 
    case '4h': return MAX_MEDIUM_AMOUNT_DURATION; 
    case '1m': return MAX_SMALL_AMOUNT_DURATION; 
    case '5m': return MAX_SMALL_AMOUNT_DURATION; 
    default: return MAX_LARGE_AMOUNT_DURATION; 
  }
}

const getFutureRateBars = async (
  from: number,
  to: number,
  futureId: string,
  interval: '1h' | '4h' | '1d' | string
): Promise<Array<{ close: number; high: number; low: number; open: number; volume: number; time: number }>> => {
  const maxCandles = 100;
  let intervalSeconds: number | null = null;

  switch (interval) {
    case '1h':
      intervalSeconds = 3600;
      break;
    case '4h':
      intervalSeconds = 14400;
      break;
    case '1d':
      intervalSeconds = 86400;
      break;
    default:
        intervalSeconds = null;
  }

  const params = { futureId, from, to, interval };
  const markets = await getActiveMarketsInfo();
  const data = await getMarketByFutureId(futureId, markets);
  const selectedMarket = markets.find((market) => market.descriptor.id === data);
  const underlyingDecimal = selectedMarket?.descriptor?.underlyingDecimals;
  let decimal = underlyingDecimal ?? 16;


  if (intervalSeconds) {
    const totalCandles = Math.ceil((to - from) / intervalSeconds);
    if (totalCandles > maxCandles) {
      const requests: Promise<any>[] = [];
      let currentFrom = from;

      while (currentFrom < to) {
        const currentTo = Math.min(currentFrom + maxCandles * intervalSeconds, to);
        requests.push(getFutureRates({ futureId, from: currentFrom, to: currentTo, interval }));
        currentFrom = currentTo;
      }


      const results = await Promise.all(requests);
      return results.flat()
        .filter((item: { open: number | null; close: number | null }) => item.open !== null && item.close !== null)
        .map((item: { close: number; high: number; low: number; open: number; volume: number; timestamp: number }) => ({
          close: +(item.close / 10 ** 16).toFixed(4),
          high: +(item.high / 10 ** 16).toFixed(4),
          low: +(item.low / 10 ** 16).toFixed(4),
          open: +(item.open / 10 ** 16).toFixed(4),
          volume: +(item.volume / 10 ** decimal).toFixed(4),
          time: moment(item.timestamp).unix() * 1000,
        }));
    }
  }

  const candlesData = await getFutureRates(params);
  return candlesData
    .filter((item) => item.open !== null && item.close !== null)
    .map((item) => ({
      close: +(item.close / 10 ** 16).toFixed(4),
      high: +(item.high / 10 ** 16).toFixed(4),
      volume: +(item.volume / 10 ** decimal ).toFixed(4),
      low: +(item.low / 10 ** 16).toFixed(4),
      open: +(item.open / 10 ** 16).toFixed(4),
      time: moment(item.timestamp).unix() * 1000
    }));
};


const getFloatingRateBars = async (
  marketId: string,
  from: number,
  to: number,
  interval: any
) => {
  const maxCandles = 340;
  let intervalSeconds;

  switch (interval) {
    case '1h':
      intervalSeconds = 3600;
      break;
    case '4h':
      intervalSeconds = 14400;
      break;
    case '1d':
      intervalSeconds = 86400;
      break;
    default:
      intervalSeconds = null;
  }

  if (intervalSeconds) {
    const totalCandles = Math.ceil((to - from) / intervalSeconds);
    if (totalCandles > maxCandles) {
      const requests = [];
      let currentFrom = from;

      while (currentFrom < to) {
        const currentTo = Math.min(currentFrom + maxCandles * intervalSeconds, to);
        requests.push(getFloatingRates({ marketId, from: currentFrom, to: currentTo, interval }));
        currentFrom = currentTo;
      }

      const results = await Promise.all(requests);
      return results.flat().map(item => ({
        close: +item.close / 10 ** 16,
        high: +item.high / 10 ** 16,
        low: +item.low / 10 ** 16,
        open: +item.open / 10 ** 16,
        time: +item.timestamp * 1000,
      }));
    }
  }

  // If within limit, fetch normally
  const barsRaw = await getFloatingRates({
    marketId,
    from,
    to,
    //@ts-ignore
    interval
  });

  return barsRaw.map(item => ({
    close: +item.close / 10 ** 16,
    high: +item.high / 10 ** 16,
    low: +item.low / 10 ** 16,
    open: +item.open / 10 ** 16,
    time: +item.timestamp * 1000,
  }));
};

export const CreateDatafeed = (params: DatafeedParams) => {
  const { marketId, futureId, timestampFrom, symbols } = params

  const getAllSymbols = () => {
    return symbols
  }

  return {
    onReady: (callback: (options: {}) => void) => {
      console.log('[onReady]: Method call');
      setTimeout(() => callback(configurationData));
    },
    searchSymbols: async (
      userInput: string,
      exchange: string,
      symbolType: string,
      onResultReadyCallback: (
        options: DatafeedSymbolInfo[]
      ) => void
    ) => {
      console.log('[searchSymbols]: Method call');
      const symbols = getAllSymbols();
      const newSymbols = symbols.filter(symbol => {
        const isExchangeValid = exchange === '' || symbol.exchange === exchange;
        const isFullSymbolContainsInput = symbol.full_name
          .toLowerCase()
          .indexOf(userInput.toLowerCase()) !== -1;
        return isExchangeValid && isFullSymbolContainsInput;
      });
      onResultReadyCallback(newSymbols);
    },
    resolveSymbol: async (
      symbolName: string,
      onSymbolResolvedCallback: (symbolInfo: ISymbolInfo) => void,
      onResolveErrorCallback: (error: any) => void,
      extension: string
    ) => {
      console.log('[resolveSymbol]: Method call', symbolName);
      const symbols = getAllSymbols();
      const symbolItem = symbols.find(({ full_name }) => full_name === symbolName);
      if (!symbolItem) {
        console.log('[resolveSymbol]: cannot resolve symbol:', symbolName, 'all symbols:', symbols);
        onResolveErrorCallback('Cannot resolve symbol');
        return;
      }
      // Symbol information object
      const symbolInfo = {
        ticker: symbolItem.full_name,
        name: symbolItem.symbol,
        description: symbolItem.description,
        type: symbolItem.type,
        session: '24x7',
        timezone: 'Etc/UTC',
        exchange: symbolItem.exchange,
        minmov: 1,
        pricescale: 100,
        has_intraday: true,
        visible_plots_set: 'ohlc',
        has_weekly_and_monthly: true,
        supported_resolutions: configurationData.supported_resolutions,
        volume_precision: 2,
        data_status: 'streaming',
      };
      console.log('[resolveSymbol]: Symbol resolved', symbolName);
      // @ts-ignore
      onSymbolResolvedCallback(symbolInfo);
    },
    getBars: async (
      symbolInfo: ISymbolInfo,
      resolution: string,
      periodParams: PeriodParams,
      onHistoryCallback: (bars: IBar[], options: { noData?: boolean }) => void,
      onErrorCallback: (error: string) => void
    ) => {
      const { to, from } = periodParams;
      console.log('[getBars]: Method call', symbolInfo, 'resolution:', resolution, 'periodParams:', periodParams);

      if (to - from < getSafetyInterval(resolution)) {
        console.log('[getBars]: Time difference is less than 5 minutes, skipping request');
        onHistoryCallback([], { noData: true });
        return;
      }

      // if (to - from > getSafetyHighInterval(resolution)) {
      //   console.log('[getBars]: Time difference is more than 20 days, skipping request');
      //   onHistoryCallback([], { noData: true });
      //   return;
      // }
      let bars: IBar[] = []

      if(timestampFrom < to) {
        try {
          const from = Math.max(periodParams.from, timestampFrom)
          const interval = Number(resolution) ? `${Number(resolution) / 60}h` : resolution.toLowerCase()
          const customTo = to + 8000

          if(symbolInfo.name.toLowerCase().includes('rate')) {
            bars = await getFloatingRateBars(
              marketId,
              from,
              customTo,
              interval
            )
          } else {
            bars = await getFutureRateBars(from, to, futureId, interval)
          }
        } catch (e) {
          console.log('[getBars]: historical data error:', e);
          onErrorCallback('Unable to load historical data');
          return false
        }
      }

      console.log(`[getBars]: returned ${bars.length} bar(s)`, bars);
      onHistoryCallback(bars, { noData: bars.length === 0 });
    },
    subscribeBars: async (
      symbolInfo: ISymbolInfo,
      resolution: string,
      onRealtimeCallback: (ohlc: IBar) => void,
      subscriberUID: string,
      onResetCacheNeededCallback: () => void
    ) => {
      console.log('[subscribeBars]: symbolInfo', symbolInfo);

      const runSubscribeUpdate = async () => {
        try {
          let bars: IBar[] = []
          const params = {
            futureId,
            from: Math.round((Date.now() - 60_000) / 1000),
            to: Math.round(Date.now() / 1000),
            interval: Number(resolution) ? `${Number(resolution) / 60}h` : resolution.toLowerCase()
          }
          isSubscribeRequestRunning = true
          if(symbolInfo.name.toLowerCase().includes('rate')) {
            bars = await getFloatingRateBars(marketId, params.from, params.to, params.interval)
          } else {
            bars = await getFutureRateBars(params.from, params.to, futureId, params.interval)
          }
          // console.log('[subscribeBars] bars result: ', bars)
          bars.forEach((bar) => {
            onRealtimeCallback(bar);
          })
        } catch (error) {
          console.log('[subscribeBars]: Failed to fetch:', error);
        } finally {
          isSubscribeRequestRunning = false
        }
      }

      subscribeIntervals[subscriberUID] = setInterval(() => {
        if(!isSubscribeRequestRunning) {
          // console.log('runSubscribeUpdate: update')
          runSubscribeUpdate()
        } else {
          // console.log('runSubscribeUpdate: already running')
        }
      }, 5 * 1000)
    },
    unsubscribeBars: (subscriberUID: string) => {
      console.log('[unsubscribeBars]: Method call with subscriberUID:', subscriberUID);
      const timerId = subscribeIntervals[subscriberUID]
      if(timerId) {
        clearInterval(timerId)
        console.log('[unsubscribeBars]: Clear interval', subscriberUID);
      }
    },
  };
}
