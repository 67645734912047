import {
  FormError,
  FutureInfo,
  MarketDescriptor,
  MarketInfo,
  MarketPortfolio,
  RiskDirection,
  TradeQuote
} from "../../types";
import {BigNumber} from "ethers";
import {TransactionReceipt} from "viem";
import {Box} from "grommet";
import styled from "styled-components";
import {FutureMarketStats} from "../../api/dataService";
import {PrimaryButtonProps} from "../../components";

export interface FormValuesState {
  notional: string,
  collateral: string,
  riskDirection: RiskDirection
  isMaxRateLimitAuto: boolean
  maxRateLimit: string
  isNativeTokenSelected: boolean
}

export interface TradeParams {
  futureId?: string
  marketId?: string
  markets: MarketInfo[]
  market?: MarketInfo
  future?: FutureInfo
  formValues: FormValuesState
  leverage: number
  leverageRange: { min: number; max: number }
  tradeQuote: TradeQuote | null
  quoteError: Error | null
  isQuoteSwapFetching: boolean
  portfolio?: MarketPortfolio
  allowance: BigNumber
  underlying: string,
  underlyingBalance: BigNumber
  underlyingDecimals: number
  underlyingName: string
  nativeTokenBalance: BigNumber
  isApproveTxLoading: boolean
  isExecuteTradeLoading: boolean
  approveTxReceipt?: TransactionReceipt
  executeTradeReceipt?: TransactionReceipt
  modalId: string
  setModalId: (id: string) => void
  setExecuteTxHash: (hash: string) => void
  setApproveTxHash: (hash: string) => void
  onSelectFuture: (market: MarketInfo, future: FutureInfo) => void
  setLeverage: (value: number) => void
}

export interface TradeProps extends TradeParams {
  txError: Error | null
  formError: FormError | null
  setFilteredMarkets?: (item: string) => void;
  filteredMarkets?: string;
  onChangeFormValue: (newValues: Record<string, string | boolean | number | RiskDirection>) => void
}

export const defaultValuesState: FormValuesState = {
  notional: '0',
  collateral: '0',
  riskDirection: RiskDirection.payer,
  isMaxRateLimitAuto: true,
  maxRateLimit: '0',
  isNativeTokenSelected: false
}

export interface TradeButtonProps extends PrimaryButtonProps {
  inProgress?: boolean
  onClick?: () => void
}

