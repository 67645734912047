import {Navigate, Route, Routes} from 'react-router-dom';
import {MarketsPage} from "./pages/markets";
import {AppLayout} from "./components/layout";
import {PortfolioPage} from "./pages/portfolio";
import {LiquidityProvision} from "./pages/liquidity-provision";
import {History} from "./pages/history";
import {PageNotFound} from "./pages/404";
import {Trade} from "./pages/trade";
import {RestrictedPersons} from "./pages/restricted-persons";
import {ModalsHOC} from "./components/modals/ModalsHOC";
import {EarnPage} from "./pages/earn";
import {StatsPage} from "./pages/stats";
import {RoutePaths} from "./constants";

export const AppRoutes = () => {
  // const isMobile = useMediaQuery({ query: '(max-width: 767px)' })
  // if(isMobile) {
  //   return <MobileNotSupported />
  // }

  return <Routes>
    <Route element={
      <ModalsHOC>
        <AppLayout />
      </ModalsHOC>
    }>
      <Route path={'/trade'} element={<Navigate to={'/funding'} />} />
      <Route path={'/trade/:futureAlias'} element={<Navigate to={'/funding'} />} />

      <Route path={'/'} element={<Navigate to={'/funding'} />} />
      <Route path={'/restricted'} element={<Navigate to={'/funding'} />} />
      {/* <Route path={'/trade/:futureAlias'} element={<Trade />} />
      <Route path={'/trade'} element={<Trade />} /> */}

      <Route path={'/funding/:futureAlias'} element={<Trade />} />
      <Route path={'/funding'} element={<Trade />} />
      <Route path={'/staking/:futureAlias'} element={<Trade />} />
      <Route path={'/staking'} element={<Trade />} />

      <Route
        path={'/funding/liquidity/:futureAlias'}
        element={<LiquidityProvision />}
      />
            <Route
        path={'/staking/liquidity/:futureAlias'}
        element={<LiquidityProvision />}
      />
      <Route path={'/markets'} element={<MarketsPage />} />
      <Route path="markets">
        <Route index element={<MarketsPage />} />
      </Route>
      <Route path="portfolio" element={<PortfolioPage />}>
        <Route path=":marketAlias" element={<PortfolioPage />} />
      </Route>
      <Route path="history" element={<History />} />
      <Route path="earn" element={<EarnPage />} />
      <Route path="stats" element={<StatsPage />} />
      <Route path={RoutePaths.restrictedPersons} element={<RestrictedPersons />} />
      <Route path={'*'} element={<Navigate to={'/funding'} />} />
    </Route>
  </Routes>
}