import { Box } from 'grommet'
import React from 'react'
import { Deposit } from './deposit'
import {Stats} from './stats'
export const MainWidget = () => {
  return (
    <Box direction='row' gap='25px'>
        <Stats/>
        <Deposit/>
    </Box>
  )
}
