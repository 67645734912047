import { Box, Text } from 'grommet'
import React from 'react'
import { getNumberText, QuestionMark, WidgetContainer } from '../../../components'
import { InvestModal } from '../InvestModal'
import { WithdrawModal } from '../WithdrawModal'
import styled from 'styled-components'
import { Skeleton } from 'antd'
import { useAccount } from 'wagmi'
import { useVault } from '../../../providers/VaultDataProvider'
import { Number } from '../../../components'
import { BigNumber } from 'ethers'

const zero = BigNumber.from(0)

interface MenuItemProps {
  item: string
  value?: string
  active: boolean
  setActive: (item: any) => void
}

const MenuItemContainer = styled(Box)<{ isActive?: boolean }>`
  position: relative;
  flex-shrink: 0;
  padding: 8px 23px;

  ${(props) =>
    props.isActive &&
    `
    background: #26262B;
    border-radius: 6px;
  `}
`

export const MenuItem = ({ item, value, active, setActive }: MenuItemProps) => {
  const itemValue = value || item
  return (
    <MenuItemContainer
      isActive={active}
      onClick={() => setActive(itemValue.toLowerCase())}
    >
      <Text
        weight={active ? 500 : 400}
        size="15px"
        color={active ? '#E4E4E8' : '#B6B7C8'}
      >
        {item}
      </Text>
    </MenuItemContainer>
  )
}

interface StatsProps {
  text: string
  value: number
}

const Stats = ({ text, value }: StatsProps) => {
  return (
    <Box
      direction="row"
      align="center"
      justify="between"
      margin={{ top: text.includes('P&L') ? '15px' : '0px' }}
    >
      <Box direction="row" align="center" gap="8px">
        <Text size="20px" color="#838495">
          {text}
        </Text>
        <QuestionMark tooltipId={text} tooltipText={text} />
      </Box>
      <Text
        size="20px"
        weight={400}
        color={
          text.includes('P&L') ? (value > 0 ? '#5ABF7D' : '#E76565') : '#D6D6DC'
        }
      >
        {text.includes('P&L') && (value > 0 ? '+' : '-')}
        {value.toLocaleString()}{' '}
        <Text
          size="20px"
          weight={400}
          color={
            text.includes('P&L')
              ? value > 0
                ? '#5ABF7D'
                : '#E76565'
              : '#D6D6DC'
          }
        >
          USDT
        </Text>
      </Text>
    </Box>
  )
}

export const Deposit = () => {
  const [operationType, setOperationType] = React.useState<'invest' | 'redeem'>(
    'invest'
  )
  const { address: userAddress } = useAccount()
  const { vault, vaultPortfolio, totalEarned } = useVault()

  const statsArray = [
    { text: 'Your balance', value: 18600 },
    { text: 'Your P&L', value: 1834 },
  ]

  return (
    <WidgetContainer background="widgetBg" width="402px" height="373px">
      <Box pad="8px 2px">
        <Box direction="row" align="center" justify="between">
          <Box direction="row" align="center" gap="8px">
            <Text size="20px" color="#838495">
              Your balance
            </Text>
            <QuestionMark
              tooltipId={'Your balance'}
              tooltipText={'Your balance'}
            />
          </Box>

          {!userAddress ? (
            <Number
              value={zero}
              decimals={6}
              fontSize={'20px'}
              fontColor={'textHeader'}
              name={'USDT'}
              showName={true}
            />
          ) : !vault ? (
            <Skeleton.Input
              active
              size={'small'}
              style={{ width: '140px', height: '35px' }}
            />
          ) : (
            <Number
              value={vault.maxWithdraw}
              decimals={vault.underlyingDecimals}
              fontSize={'20px'}
              fontColor={'textHeader'}
              name={vault.underlyingName}
              showName={true}
            />
          )}
        </Box>

        <Box
          direction="row"
          align="center"
          justify="between"
          margin={{ top: '15px' }}
        >
          <Box direction="row" align="center" gap="8px">
            <Text size="20px" color="#838495">
              Your P&L
            </Text>
            <QuestionMark tooltipId={'Your P&L'} tooltipText={'Your P&L'} />
          </Box>
          {!userAddress ? (
            <Number
              value={zero}
              decimals={6}
              fontSize={'20px'}
              fontColor={'textHeader'}
              name={'USDT'}
              showName={true}
            />
          ) : !vault || !vaultPortfolio ? (
            <Skeleton.Input
              active
              size={'small'}
              style={{ width: '140px', height: '33px' }}
            />
          ) : (
            <Number
              value={totalEarned}
              decimals={vault.underlyingDecimals}
              fontSize={'20px'}
              fontColor={totalEarned.toNumber() >= 0 ? '#5ABF7D' : '#E76565'}
              name={vault.underlyingName}
              showName={true}
            />
          )}
        </Box>
        <hr
          style={{
            height: '1px',
            width: '100%',
            border: 'none',
            backgroundColor: '#272835',
            marginTop: '24px',
            marginBottom: '28px',
          }}
        />

        <Box direction="row" gap="8px" margin={{ bottom: '17px' }}>
          <MenuItem
            item="Invest"
            active={operationType === 'invest'}
            setActive={setOperationType}
          />
          <MenuItem
            item="Redeem"
            active={operationType === 'redeem'}
            setActive={setOperationType}
          />
        </Box>

        {operationType === 'invest' ? <InvestModal /> : <WithdrawModal />}
      </Box>
    </WidgetContainer>
  )
}
