import {useBalance as useWagmiBalance, useBlockNumber, usePublicClient as useClient} from "wagmi";
import {wagmiConfig} from "../modules/wagmi";
import {getAccount, switchChain} from "@wagmi/core";
import {useEffect, useMemo} from "react";
import {Address} from "viem";

export const useNetwork = () => {
    const {chains} = wagmiConfig
    const {chainId} = getAccount(wagmiConfig)
    return useMemo(() => {
        return {
            chain: chains.find(chain => chain.id === chainId),
            chains,
        }
    }, [chainId, chains])
}
export const switchNetwork = (options: { chainId: number }) => {
    return switchChain(wagmiConfig, options)
}
export const usePublicClient = () => {
    const client = useClient()
    if (client === undefined) {
        throw new Error("Public client is not available")
    }
    return client
}
export const useBalance = ({address, watch, enabled, token, chainId}: {
    address?: Address,
    watch?: boolean,
    enabled?: boolean,
    token?: Address,
    chainId?: number
}) => {
    const {data: blockNumber} = useBlockNumber({watch: true})
    const {data, refetch} = useWagmiBalance({
        address,
        token,
        chainId,
        query: {
            enabled,
        }
    })

    useEffect(() => {
        if (watch) {
            refetch()
        }
    }, [blockNumber]);

    return useMemo(() => ({data}), [data])
}
