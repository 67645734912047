import React from 'react'
import {Box} from "grommet";
import {PrimaryButton} from "../../../components";
import {walletLoginDialog} from "../../../ConnectWalletModal";

export const ConnectWalletButton = () => {
  return <Box>
    <PrimaryButton
      text={'Connect Wallet'}
      onClick={() => walletLoginDialog.open()}
    />
  </Box>
}
