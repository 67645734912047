import React, {useEffect, useState} from 'react'
import {useProtocolData} from "../../providers/ProtocolDataProvider";
import {MarketInfo} from "../../types";
import {PortfolioProps} from "./common";
import {useMarketsWithdrawableMargin} from "../../hooks/useWithdrawableMargin";
import {useNavigate, useParams} from "react-router-dom";
import {getMarketAlias} from "../../utils";
import {useMediaQuery} from "react-responsive";
import {breakpoints} from "../../utils/breakpoints";
import {PortfolioDesktop} from "./responsive/desktop";
import {PortfolioMobile} from "./responsive/mobile";
import usePortfolio from '../../hooks/usePortfolio';
import { useAccount } from 'wagmi';

export const PortfolioPage = () => {
  const { markets, portfolio } = useProtocolData()
  const navigate = useNavigate();
  const { marketAlias } = useParams()
  const {address} = useAccount()
  const {refetch} = usePortfolio({userAddress: address})

  useEffect(() => {
    refetch()
  }, [address])

  const isMobile = useMediaQuery({ query: `(max-width: ${breakpoints.mobile})` })

  const {
    marketsWithdrawableMargin
  } = useMarketsWithdrawableMargin(markets.map(item => item.descriptor.id))
  const [selectedMarket, setSelectedMarket] = useState<MarketInfo | 'all'>('all')
  const [isDropdownOpened, setDropdownOpened] = useState(false)

  useEffect(() => {
    if(marketAlias) {
      if(marketAlias !== 'all') {
        for (const market of markets) {
          const alias = getMarketAlias(market)
          if (marketAlias === alias) {
            setSelectedMarket(market)
            return
          }
        }
      }
      setSelectedMarket('all')
    } else {
      navigate('/portfolio/all')
    }
  }, [marketAlias, markets.length]);

  // Set initial market
  // useEffect(() => {
  //   if(!selectedMarket && futureData?.market) {
  //     setSelectedMarket(futureData.market)
  //   }
  // }, [futureData, selectedMarket]);

  const commonProps: PortfolioProps = {
    viewType: 'default',
    market: selectedMarket,
    isMarketSelected: selectedMarket && selectedMarket !== 'all',
    portfolio,
    marketPortfolio: selectedMarket !== 'all'
      ? portfolio.find(item => item.descriptor.id === selectedMarket.descriptor.id)
      : undefined,
    marketsWithdrawableMargin,
    isDropdownOpened,
    setDropdownOpened
  }

  if(isMobile) {
    return <PortfolioMobile {...commonProps} viewType={'mobile'} />
  }

  return <PortfolioDesktop {...commonProps} />
}
