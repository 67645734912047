import React, {useEffect, useMemo, useState} from "react";
import {Anchor, Box, Button, Spinner, Text} from "grommet";
import {useAccount, useReadContract, useWriteContract} from "wagmi";
import {erc20Abi} from "viem";
import {FormError, MarginState, MarketInfo, MarketPortfolio} from "../../types";
import config, {configMode, isMainnet} from "../../config";
import RouterABI from "../../abi/RouterABI.json";
import {BigNumber} from "ethers";
import {ManageMarginRequest} from "./Request";
import {getLeverage, getOraclePackage, prepareFormNumber} from "../../utils";
import erc20MockABI from "../../abi/erc20MockABI.json";
import {getFormError} from "./validator";
import {getIconByName, HeaderSwitch, MarketLeverage, MarketMargin, PrimaryButton, QuestionMark, RouterErrorMessage, UnsupportedNetwork, WidgetContainer} from "../../components";
import {ReactComponent as CrossImg} from "../../assets/images/cross.svg";
import {Button as AntdButton} from "antd";
import {CrossChainDepositNotification, marginSuccessNotification} from "../../components/notifications";
import {useProtocolData} from "../../providers/ProtocolDataProvider";
import {marginTotal} from "../../utils/mappers";
import {useWithdrawableMargin} from "../../hooks/useWithdrawableMargin";
import {useSquidRoute} from "../../hooks/useSquidRoute";
import {useSquidRouteExecute} from "../../hooks/useSquidRouteExecute";
import {toast} from "react-toastify";
import {useSquidData} from "../../hooks/useSquidData";
import {arbitrum} from "wagmi/chains";
import useDebounce from "../../hooks/useDebounce";
import {waitForTransactionReceipt} from "@wagmi/core";
import {toBn} from "evm-bn";
import {SquidRouterQuote, SquidStatusType, SquidToken} from "../../api/squid";
import {BridgeTokensSymbols, NativeSymbols, NativeToken} from "./constants";
import {getAllowance} from "../../api/erc20Contract";
import tooltips from "../../utils/tooltips";
import {ReactComponent as SquidLogoImg} from "../../assets/images/squid.svg";
import {ReactComponent as AxelarLogoImg} from "../../assets/images/axelar.svg";
import {RestrictedAccess} from "../trade/trade-request/RestrictedAccess";
import {useMediaQuery} from "react-responsive";
import {breakpoints} from "../../utils/breakpoints";
import {ViewType} from "../../app.types";
import {useActiveModal} from "../../providers/ModalsProvider";
import {PageModal} from "../../constants";
import {wagmiConfig} from "../../modules/wagmi";
import {switchNetwork, useBalance, useNetwork, usePublicClient} from "../../hooks/blockchainHooks";

export interface ManageMarginParams {
  markets: MarketInfo[];
  marketId: string;
  depositType: DepositType;
  market: MarketInfo;
  marginDetails?: MarginState;
  formValues: FormValuesState;
  nativeTokenBalance: BigNumber;
  underlyingAddress: string;
  underlyingName: string;
  underlyingBalance: BigNumber;
  withdrawableMargin: BigNumber;
  marketPortfolio?: MarketPortfolio;
  isSquidTokensLoading: boolean;
  availableSquidTokens: SquidToken[];
  isSquidQuoteLoading: boolean;
  squidQuote?: SquidRouterQuote;
  squidQuoteErrorMessage: string;
  fromTokenBalance: BigNumber;
  fromTokenDecimals: number;
  setMarketId: (id: string) => void;
  viewType: ViewType;
  totalFees?: number;
  gasFee?: number;
}

export enum OperationType {
  deposit = "deposit",
  withdraw = "withdraw",
}

interface FormValuesState {
  operation: OperationType;
  amount: string;
  wrapNativeToken: boolean;
  sourceChainId: number;
  tokenSymbol: string;
}

type FormValueProperty = {
  [K in keyof FormValuesState]?: FormValuesState[K];
};

export interface ManageMarginProps extends ManageMarginParams {
  formError: FormError | null;
  onChangeFormValue: (newState: FormValueProperty) => void;
}

const defaultValuesState: FormValuesState = {
  operation: OperationType.deposit,
  amount: "0",
  wrapNativeToken: false,
  sourceChainId: config.chainId,
  tokenSymbol: "ETH",
};

export interface ManageMarginContainerProps {
  marketId?: string;
  operationType?: OperationType;
  onClose: () => void;
}

export enum DepositType {
  direct = "direct",
  crossChain = "cross-chain",
}

export const MarginManagement = (props: ManageMarginContainerProps) => {
  const {marketId = "", operationType = "deposit"} = props;
  const {chain} = useNetwork();
  const {isConnected, address: userAddress} = useAccount();
  const client = usePublicClient();
  const isMobile = useMediaQuery({query: `(max-width: ${breakpoints.mobile})`});

  const {setActiveModal} = useActiveModal();

  const {isProtocolUser, markets, portfolio, refetchPortfolio, portfolioInitialFetching: isLoading} = useProtocolData();

  const {amount: withdrawableMargin, refetch: refetchWithdrawableMargin} = useWithdrawableMargin(props.marketId);

  const [depositType, setDepositType] = useState<DepositType>(DepositType.direct);
  const [formValues, setValues] = useState<FormValuesState>(defaultValuesState);
  const debouncedAmount = useDebounce(formValues.amount, 350);
  const [allowance, setAllowance] = useState<BigNumber>(BigNumber.from(0));

  const [isRequestRunning, setRequestRunning] = useState(false);
  const [txError, setTxError] = useState<Error | null>(null);
  const [txTimestamp, setTxTimestamp] = useState(0);

  const [marginDetails, setMarginState] = useState<MarginState>();

  const {tokens: squidTokens, isLoading: isSquidTokensLoading} = useSquidData({
    enabled: depositType === "cross-chain",
  });
  const availableSquidTokens = useMemo(() => {
    return squidTokens.filter((item) => +item.chainId === formValues.sourceChainId && BridgeTokensSymbols.includes(item.symbol));
  }, [squidTokens.length, formValues.sourceChainId]);

  const fromToken = squidTokens.find((token) => token.chainId === formValues.sourceChainId.toString() && token.symbol === formValues.tokenSymbol);

  const marketPortfolio = portfolio.find((item) => item.descriptor.id === marketId);
  const market = markets.find((market) => market.descriptor.id === marketId);
  const underlying = market ? market.descriptor.underlying : "";
  const underlyingDecimals = market ? market.descriptor.underlyingDecimals : 18;
  const underlyingName = (depositType === "direct" ? market?.descriptor.underlyingName : fromToken?.symbol) || "";
  const underlyingAddress = depositType === "direct" ? underlying : fromToken?.address || "";

  const toToken = squidTokens.find(
    (token) => token.chainId === arbitrum.id.toString() && token.symbol === market?.descriptor.underlyingName // USDT / WETH
  );

  const balancesChainId = depositType === "direct" ? config.chainId : formValues.sourceChainId;

  const {data: underlyingBalanceData} = useReadContract({
    address: underlyingAddress as `0x${string}`,
    functionName: "balanceOf",
    abi: erc20Abi,
    args: [userAddress as `0x${string}`],
    // enabled: !!userAddress && !NativeSymbols.includes(underlyingName),
    chainId: balancesChainId,
  });

  const {data: userBalance} = useBalance({
    address: userAddress,
    token: underlyingAddress as `0x${string}`,
    watch: true,
    chainId: balancesChainId,
  });

  const underlyingBalance = BigNumber.from(userBalance ? userBalance.value : "0");

  const {data: nativeTokenBalanceData} = useBalance({
    address: userAddress,
    enabled: !!userAddress,
    watch: true,
    chainId: balancesChainId,
  });
  const nativeTokenBalance = nativeTokenBalanceData ? BigNumber.from(nativeTokenBalanceData?.value.toString()) : BigNumber.from(0);

  const fromTokenBalance = useMemo(() => {
    if (depositType === "direct") {
      if (formValues.wrapNativeToken) {
        return nativeTokenBalance;
      }
      return underlyingBalance;
    }
    // if(formValues.sourceChainId !== chain?.id) {
    //   return BigNumber.from(0)
    // }
    return NativeSymbols.includes(fromToken?.symbol || "") ? nativeTokenBalance : underlyingBalance;
  }, [depositType, formValues.wrapNativeToken, formValues.sourceChainId, nativeTokenBalance, underlyingBalance]);

  const fromTokenDecimals = depositType === "direct" ? underlyingDecimals : fromToken?.decimals || 18;

  const fromAmount = toBn(debouncedAmount, fromToken?.decimals).toString();
  const {
    squidQuote,
    isLoading: isSquidQuoteLoading,
    squidQuoteErrorMessage,
  } = useSquidRoute({
    userAddress,
    marketId,
    fromChain: formValues.sourceChainId,
    fromToken: fromToken?.address,
    fromAmount, // 100000000000000
    toToken: toToken?.address,
    enabled: depositType === "cross-chain",
  });

  const [totalFees, setTotalFees] = useState(0);
  const [gasFee, setTotalGasFee] = useState(0);

  useEffect(() => {
    if (squidQuote) {
      const amountFee = squidQuote?.route?.estimate?.feeCosts?.reduce((acc, transaction) => acc + Number(transaction.amountUsd), 0);
      setTotalFees(amountFee);
      const amountGas = squidQuote?.route?.estimate?.gasCosts?.reduce((acc, transaction) => acc + Number(transaction.amountUsd), 0);
      setTotalGasFee(amountGas);
    }
  }, [squidQuote]);

  const {execute: executeSquidRoute, inProgress: inExecutionInProgress, requests: squidRequests} = useSquidRouteExecute();
  const successfulSquidRequests = useMemo(() => {
    return squidRequests.filter((request) => request.status && [SquidStatusType.EXPRESS_EXECUTED, SquidStatusType.SUCCESS].includes(request.status.status));
  }, [squidRequests]);

  const {writeContractAsync: callApprove} = useWriteContract();

  const onChangeFormValue = (newState: FormValueProperty) => {
    setValues((currentState) => {
      return {
        ...currentState,
        ...newState,
      };
    });
  };

  const {writeContractAsync: depositRequest} = useWriteContract();

  const {writeContractAsync: withdrawRequest} = useWriteContract();

  useEffect(() => {
    if (!availableSquidTokens.find((item) => item.symbol === formValues.tokenSymbol)) {
      if (availableSquidTokens[0]) {
        setValues((currentState) => {
          return {
            ...currentState,
            tokenSymbol: availableSquidTokens[0].symbol,
          };
        });
      }
    }
  }, [availableSquidTokens, formValues.sourceChainId, formValues.tokenSymbol]);

  useEffect(() => {
    const loadData = async () => {
      try {
        if (underlyingAddress && userAddress) {
          const allowanceData = await getAllowance(underlyingAddress, userAddress);
          setAllowance(allowanceData);
          console.log("Updated allowance:", allowanceData.toString());
        }
      } catch (e) {}
    };
    loadData();
  }, [chain?.id, underlyingAddress, userAddress]);

  useEffect(() => {
    refetchPortfolio();
  }, [successfulSquidRequests.length]);

  // useEffect(() => {
  //   if(marketIdFromQuery) {
  //     setMarketId(marketIdFromQuery)
  //     onChangeFormValue({ 'amount': '0' })
  //   }
  //   if(depositType === 'cross-chain') {
  //     onChangeFormValue({ operation: OperationType.deposit })
  //   }
  // }, [marketIdFromQuery, depositType]);

  useEffect(() => {
    setTxError(null);
  }, [formValues.operation, formValues.wrapNativeToken]);

  useEffect(() => {
    onChangeFormValue({amount: "0"});
  }, [formValues.operation]);

  useEffect(() => {
    if (Date.now() - txTimestamp > 1000) {
      setTxError(null);
    }
  }, [formValues.amount]);

  useEffect(() => {
    if (operationType) {
      setValues((currentState) => {
        return {
          ...currentState,
          operation: operationType as OperationType,
          amount: "0",
        };
      });
    }
  }, [operationType, formValues.tokenSymbol]);

  if (!market) {
    return (
      <Box width={"100%"} justify={"center"} align={"center"} pad={{top: "32px", bottom: "32px"}}>
        {isLoading && <Spinner color={"spinner"} size={"small"} />}
      </Box>
    );
  }

  const onRequestClicked = async () => {
    try {
      setRequestRunning(true);
      let resultHash;
      if (formValues.operation === "deposit") {
        const amount = prepareFormNumber(formValues.amount, underlyingDecimals);

        if (!formValues.wrapNativeToken && amount.gt(allowance)) {
          const approveResultHash = await callApprove({
            address: (market ? market.descriptor.underlying : "0x") as `0x${string}`,
            abi: erc20MockABI as any[],
            functionName: "approve",
            args: [config.routerContractAddress, prepareFormNumber(formValues.amount, underlyingDecimals).toString()],
          });
          await waitForTransactionReceipt(wagmiConfig, {hash: approveResultHash, confirmations: config.txConfirmations});
        }

        const args = [marketId, userAddress, formValues.wrapNativeToken ? "0" : amount, true, []];
        const txValue = formValues.wrapNativeToken ? BigInt(amount.toString()) : undefined;

        let gas = undefined;
        if (config.gasEstimateMultiplier) {
          try {
            gas = await client.estimateContractGas({
              address: config.routerContractAddress,
              abi: RouterABI,
              functionName: "deposit",
              args,
              value: txValue,
              account: userAddress as `0x${string}`,
            });
            gas = BigInt(Math.round(+gas.toString() * config.gasEstimateMultiplier));
            console.log(`Using gas multiplier: ${config.gasEstimateMultiplier}, gas value: ${gas}`);
          } catch (e) {
            console.error(`Failed to estimate gas:`, e);
          }
        }

        console.log("Provide arguments:", args, "gas:", gas);
        resultHash = await depositRequest({
          address: config.routerContractAddress,
          abi: RouterABI,
          functionName: "deposit",
          args,
          value: txValue,
          gas,
        });
      } else {
        const oraclePackage = await getOraclePackage(marketId);
        const args = [marketId, formValues.wrapNativeToken, prepareFormNumber(formValues.amount, underlyingDecimals), true, [oraclePackage]];

        let gas = undefined;
        if (config.gasEstimateMultiplier) {
          try {
            gas = await client.estimateContractGas({
              address: config.routerContractAddress,
              abi: RouterABI,
              functionName: "withdraw",
              args,
              account: userAddress as `0x${string}`,
            });
            gas = BigInt(Math.round(+gas.toString() * config.gasEstimateMultiplier));
            console.log(`Using gas multiplier: ${config.gasEstimateMultiplier}, gas value: ${gas}`);
          } catch (e) {
            console.error("Failed to estimate gas:", e);
          }
        }

        console.log("Withdraw arguments: ", args, "gas:", gas);
        resultHash = await withdrawRequest({
          address: config.routerContractAddress,
          abi: RouterABI,
          functionName: "withdraw",
          args,
          gas,
        });
      }

      if (resultHash) {
        const transactionHash = resultHash;
        console.log(`Transaction hash (${formValues.operation}): ${transactionHash}`);

        const txItem = {
          marketId,
          transactionHash,
          type: formValues.operation,
          status: "pending",
          direction: null,
          amount: formValues.amount,
          underlyingName,
          rate: "",
        };

        await waitForTransactionReceipt(wagmiConfig, {hash: transactionHash, confirmations: config.txConfirmations}).catch((e) => {
          console.error(`Failed on wait for transaction "${transactionHash}": `, e);
        });

        setTxTimestamp(Date.now());
        onChangeFormValue({amount: defaultValuesState.amount});
        refetchWithdrawableMargin();
        refetchPortfolio();

        const market = markets.find((m) => m.descriptor.id === txItem?.marketId);
        console.log("txItem:", txItem, "transactionHash", transactionHash);
        if (txItem) {
          const text = (
            <Box>
              <Text>Successfully {txItem.type === "deposit" ? "deposited" : "withdrawn"}</Text>
              <Text>
                {txItem.amount} {formValues.wrapNativeToken ? NativeToken : txItem.underlyingName} {txItem.type === "deposit" ? "to" : "from"} {market?.descriptor.sourceName} {market?.descriptor.instrumentName}
              </Text>
            </Box>
          );
          marginSuccessNotification({
            type: txItem.type as "deposit" | "withdraw",
            text,
            transactionHash: txItem.transactionHash,
          });
        }
        props.onClose();
      }
    } catch (e) {
      console.error(`Error on call ${formValues.operation}:`, e);
      toast.error(`${formValues.operation === "deposit" ? "Deposit" : "Withdraw"} failed. Try again later.`);
    } finally {
      setRequestRunning(false);
    }
  };
  const formParams: ManageMarginParams = {
    markets,
    marketId,
    market,
    depositType,
    marginDetails,
    formValues,
    nativeTokenBalance,
    underlyingAddress,
    underlyingName,
    underlyingBalance,
    withdrawableMargin,
    marketPortfolio,
    isSquidTokensLoading,
    availableSquidTokens,
    isSquidQuoteLoading,
    squidQuote,
    squidQuoteErrorMessage,
    fromTokenBalance,
    fromTokenDecimals,
    totalFees,
    gasFee,
    setMarketId: (marketId: string) => {
      setActiveModal(PageModal.margin, {
        marketId,
        type: operationType,
      });
    },
    viewType: isMobile ? "mobile" : "default",
  };
  const formError = getFormError(formParams);
  const commonProps: ManageMarginProps = {
    ...formParams,
    formError,
    onChangeFormValue,
  };

  const formAmount = prepareFormNumber(formValues.amount, underlyingDecimals);
  const isSwitchNetworkVisible = depositType === "cross-chain" && formValues.sourceChainId !== chain?.id;
  const isRequestEnabled = !formError && formAmount.gt(BigNumber.from(0));

  const onCrossChainClicked = async () => {
    try {
      console.log("SquidRouter quote:", squidQuote);
      if (squidQuote) {
        await executeSquidRoute(squidQuote);
        setValues((currentState) => {
          return {
            ...currentState,
            amount: "0",
          };
        });
      } else {
        console.error("Squid router quote not found");
      }
    } catch (e) {
      console.error("Cross-chain deposit failed:", e);
    }
  };

  let actionButtons = null;
  if (!isConnected) {
    //  isConnected && chain && (chain.unsupported || chain.id !== config.chainId)
    actionButtons = (
      <Box margin={{top: "24px"}}>
        <UnsupportedNetwork />
      </Box>
    );
  } else if (!isProtocolUser) {
    actionButtons = (
      <Box width={"100%"}>
        <RestrictedAccess />
      </Box>
    );
  } else if (depositType === "direct") {
    actionButtons = (
      <Box direction={"row"} gap={"16px"}>
        {txError && <RouterErrorMessage prefix={"Request error"} error={txError} />}
        {chain?.id === config.chainId ? (
          <PrimaryButton disabled={isRequestRunning || !isRequestEnabled || isSquidQuoteLoading} loading={isRequestRunning} text={isRequestRunning ? "Confirming transaction" : formValues.operation === "deposit" ? "Deposit" : "Withdraw"} onClick={onRequestClicked} />
        ) : (
          <PrimaryButton
            text={"Switch network"}
            onClick={() => {
              switchNetwork({chainId: config.chainId}).catch((e) => {});
            }}
          />
        )}
        {!isConnected && (
          <Button style={{padding: "12px 32px"}} disabled={true}>
            <Text>Connect wallet</Text>
          </Button>
        )}
      </Box>
    );
  } else if (depositType === "cross-chain") {
    actionButtons = (
      <Box direction={"row"} justify={"end"} gap={"16px"}>
        {isSwitchNetworkVisible ? (
          <PrimaryButton
            text={"Switch network"}
            onClick={async () => {
              switchNetwork({chainId: formValues.sourceChainId}).catch((e) => {
                console.error("Failed to switch network:", e);
              });
            }}
          />
        ) : (
          <PrimaryButton disabled={isSquidQuoteLoading || !squidQuote?.route || !!formError || inExecutionInProgress} loading={isSquidQuoteLoading || inExecutionInProgress} text={inExecutionInProgress ? "Depositing" : isSquidQuoteLoading ? "Fetching best prices" : squidQuote?.route ? "Deposit" : !formValues.amount || formValues.amount === "0" ? "Enter amount" : "Route not found"} onClick={onCrossChainClicked} />
        )}
      </Box>
    );
  }

  let margin = marketPortfolio ? marginTotal(marketPortfolio.marginState.margin) : BigNumber.from(0);
  let marginTo = formValues.operation === "deposit" ? margin.add(formAmount) : margin.sub(formAmount);
  if (depositType === "cross-chain") {
    const estimatedTokenAmount = squidQuote?.route ? BigNumber.from(squidQuote.route.estimate.toAmount) : BigNumber.from(0);
    marginTo = margin.add(estimatedTokenAmount);
  }

  let nextLeverage = getLeverage({
    market,
    marketPortfolio,
    margin: marginTo,
  });
  const isCrossChainAvailable = depositType === DepositType.direct && isMainnet();

  const handleChangeDepositType = (type: DepositType) => {
    setDepositType(type as DepositType);
    onChangeFormValue({amount: (0).toString()});
  };

  return (
    <Box align={"center"}>
      <WidgetContainer style={{position: "relative", padding: "24px"}}>
        <Box direction={"row"} justify={"between"} align={"center"}>
          <Text color={"textHeader"} size={"20px"} weight={500}>
            {depositType === "cross-chain" ? "Deposit" : isMobile ? "Margin" : "Margin management"}
          </Text>
          <Box direction={"row"} justify={"end"}>
            {false && (
              <Box margin={{right: isMobile ? "8px" : "64px"}}>
                <HeaderSwitch
                  options={[
                    {id: DepositType.direct, text: "Direct"},
                    {id: DepositType.crossChain, text: "Cross-chain"},
                  ]}
                  selectedOptionId={depositType}
                  onChange={(type) => handleChangeDepositType(type as DepositType)}
                />
              </Box>
            )}
            <AntdButton
              type={"text"}
              onClick={() => {
                props.onClose();
              }}
            >
              <Box justify={"center"} align={"center"}>
                <CrossImg />
              </Box>
            </AntdButton>
          </Box>
        </Box>
        {!isMobile && margin.gt(0) && (
          <Box margin={{top: "40px"}} direction={"row"} pad={"0 8px"} justify={"between"}>
            <MarketMargin showLMR={true} marketId={props.marketId} marginTo={formAmount.gt(0) ? marginTo : undefined} />
            <MarketLeverage marketId={props.marketId} nextLeverage={formAmount.gt(0) ? nextLeverage : undefined} />
          </Box>
        )}
        <Box margin={{top: "40px"}}>
          <ManageMarginRequest {...commonProps} />
        </Box>
        {/*<Box width={'100%'} height={'1px'} background={'border'} />*/}
        <Box width={"100%"} direction={"row"} justify={"between"} margin={{top: "30px"}}>
          {isProtocolUser && isCrossChainAvailable && !isMobile && (
            <Box justify={"center"} align={"center"} direction={"row"} gap={"12px"}>
              <Box background={"optionBg"} width={"50px"} height={"50px"} round={"100%"} align={"center"} justify={"center"}>
                {getIconByName("branch").icon}
              </Box>
              <Box>
                <Box direction={"row"} align={"center"} gap={"4px"}>
                  <Text color={"textSecondary"}>Cross-chain deposit</Text>
                  <QuestionMark tooltipId={"t_cross_chain_deposit"} tooltipText={tooltips.crossChainDeposit} />
                </Box>
                <Box direction={"row"} align={"center"} gap={"2px"} onClick={() => setDepositType(DepositType.crossChain)}>
                  <Text>Bridge & swap other assets</Text>
                  {getIconByName("external", "12px").icon}
                </Box>
              </Box>
            </Box>
          )}
          {!isMobile && configMode === "testnet" && (
            <Box justify={"center"} align={"center"} direction={"row"} gap={"12px"}>
              <Box>{getIconByName("USDT", "32px").icon}</Box>
              <Box>
                <Box direction={"row"} align={"center"} gap={"4px"}>
                  <Text color={"textSecondary"}>Need testnet ETH or USDT?</Text>
                </Box>
                <Anchor href={"https://docs.rho.trading/how-to-trade-on-rho/depositing-collateral/requesting-testnet-assets"} target={"_blank"}>
                  <Box direction={"row"} align={"center"} gap={"2px"}>
                    <Text weight={400} style={{color: "#ADAEB8"}}>
                      How to get testnet assets
                    </Text>
                    {getIconByName("external", "12px").icon}
                  </Box>
                </Anchor>
              </Box>
            </Box>
          )}
          {isProtocolUser && depositType === "cross-chain" && !isMobile && (
            <Box direction={"row"} align={"center"} gap={"8px"}>
              <Text color={"textSecondary"}>Powered by</Text>
              <Box direction={"row"} align={"center"} gap={"6px"} onClick={() => window.open("https://www.squidrouter.com", "_blank")}>
                <SquidLogoImg />
                <Text color={"textSecondary"} size={"20px"}>
                  +
                </Text>
                <AxelarLogoImg />
              </Box>
            </Box>
          )}
          {actionButtons}
        </Box>
      </WidgetContainer>
      {depositType === "cross-chain" && <CrossChainDepositNotification requests={squidRequests} />}
    </Box>
  );
};
