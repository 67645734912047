import {BigNumber} from "ethers";
import {toBn} from "evm-bn";
import bn from "bignumber.js";
import {getMarketRates} from "../api/oracleService";
import config from "../config";
import {arbitrum, arbitrumGoerli, arbitrumSepolia} from "wagmi/chains";
import {Decimal} from "decimal.js";
import {
  FutureInfo, FutureOpenPosition,
  IRateMathType, MarketDescriptor,
  MarketInfo,
  MarketPortfolio,
  RiskDirection,
  RiskDirectionType
} from "../types";
import {marginTotal} from "./mappers";
import {CompoundingRateMath, LinearRateMath, PotentialPosition} from "./leverage";
import {FormValuesState} from "../pages/trade/common";
import { removeUSDT } from "../mappers";

const zero = BigNumber.from(0)

const truncateRegex = /^(0x[a-zA-Z0-9]{4})[a-zA-Z0-9]+([a-zA-Z0-9]{4})$/;

export const truncateEthAddress = (address: string) => {
  const match = address.match(truncateRegex);
  if (!match) return address;
  return `${match[1]}…${match[2]}`;
};

export const formatNumber = (
  value: string | number,
  currency = '',
  addPrefix = false,
  maximumFractionDigits?: number
) => {
  let options: any = {
    maximumFractionDigits: maximumFractionDigits || (Math.abs(+value) >= 100 ? 0 : 2)
  }
  if (currency) {
    options = { ...options, style: 'currency', currency: 'USD' }
  }
  let result = new Intl.NumberFormat('en-US', options).format(+value)
  let prefix = ''

  if (addPrefix) {
    if (+value !== 0) {
      prefix = +value > 0 ? '+' : ''
    }
  }

  return `${prefix}${result}`
}

export const abbreviateNumber = (value: string | number, decimalPlaces = 1, threshold = 4) => {
  const length = (Math.abs(parseInt(value.toString(), 10)) + '').length,
    index = Math.ceil((length - 3) / 3),
    suffix = ['k', 'm', 'b', 't', 'q', 'q'];

  if (length <= threshold) { // < 10000
    return new bn(value).dp(decimalPlaces).toString();
  }

  return (+value / Math.pow(1000, index))
    .toFixed(decimalPlaces)
    .replace(/\.0$/, '') + suffix[index - 1];
}

export const getPageTitleByRoute = (route: string) => {
  if (route.startsWith('markets')) {
    if (route === 'markets') {
      return 'Rates Markets'
    } else if (route.includes('future')) {
      return 'Trade Futures'
    } else if (route.includes('liquidity')) {
      return 'Liquidity Provision'
    } else if (route.includes('manage')) {
      return 'Margin Management'
    }
  }

  // return backup name
  const [firstRoute] = route.split('/')
  return firstRoute.charAt(0).toUpperCase()
    + firstRoute.slice(1)
}

export const capitalizeString = (str: string) => str.charAt(0).toUpperCase() + str.slice(1)

export const prepareFormNumber = (num: string, decimals: number) => {
  let value = (num || 0).toString()
  if (value.startsWith('.')) {
    value = '0' + num
  }
  if(value.endsWith('.')){
    value = value + '0'
  }
  try {
    const bnValue = toBn(value || '0', decimals)
    return bnValue
  } catch (e) {
    console.log('prepareFormNumber error:', e)
  }
  return BigNumber.from(0)
}

export const getOraclePackage = async (marketId: string) => {
  const marketRates = await getMarketRates(marketId)
  if(marketRates) {
    return marketRates.oraclePackage
  }
  throw new Error(`Rates not found for marketId ${marketId}`)
}

export const getExplorerUrl = (chainId = config.chainId) => {
  if(chainId === arbitrum.id) {
    return 'https://arbiscan.io'
  } else if(chainId === arbitrumGoerli.id) {
    return 'https://goerli.arbiscan.io'
  } else if(chainId === arbitrumSepolia.id) {
    return 'https://sepolia.arbiscan.io'
  }
  return 'https://mumbai.polygonscan.com'
}

export const getDirectionAlias = (direction: RiskDirectionType | RiskDirection | null) => {
  if(direction === RiskDirectionType.RECEIVER || direction === RiskDirection.receiver) {
    return 'Short (Receive)'
  } else if(direction === RiskDirectionType.PAYER || direction === RiskDirection.payer) {
    return 'Long (Pay)'
  }
  return 'None'
}

export const bnToDecimal = (value: BigNumber, underlyingDecimals: number) => {
  return new Decimal(value.toString()).div(10 ** underlyingDecimals)
}

export const numberToBN = (value: number, underlyingDecimals: number) => {
  const decimalValue = new Decimal(value).mul(10 ** underlyingDecimals)
  const decimalPart = (decimalValue.toString().split('.')[0] || '0')
  return BigNumber.from(decimalPart)
}

export const getLiquidationRate = (
  market: MarketInfo,
  future: FutureInfo,
  portfolio: MarketPortfolio,
  formValues: FormValuesState,
) => {
  const { id: futureId } = future
  const { underlyingDecimals } = market.descriptor
  const totalMargin = portfolio
    ? marginTotal(portfolio.marginState.margin)
    : BigNumber.from(0)
  const currentMargin = portfolio ? bnToDecimal(totalMargin, market.descriptor.underlyingDecimals) : new Decimal(0)
  const rateMath = market.descriptor.rateMathType === IRateMathType.LINEAR
    ? new LinearRateMath()
    : new CompoundingRateMath()
  const potentialPosition: PotentialPosition = {
    futureId,
    notional: new Decimal(formValues.notional),
    riskDirection: formValues?.riskDirection === RiskDirection.receiver ? RiskDirectionType.RECEIVER : RiskDirectionType.PAYER
  }
  const liquidationThreshold = rateMath.calcMarginThreshold(
    market.futures,
    portfolio.futureOpenPositions,
    bnToDecimal(market.riskParameters.liquidationThresholdDelta, 18),
    bnToDecimal(market.riskParameters.hedgeMarginFactor, 18),
    underlyingDecimals,
    market.riskParameters.marginRequirementSecondsFloor,
    potentialPosition
  )

  const openPosition = portfolio ? portfolio.futureOpenPositions.find(pos => pos.futureId === futureId) : undefined
  let openPositionNotional = openPosition
    ? openPosition.notional
    : BigNumber.from(0)
  let formNotional = prepareFormNumber(formValues.notional, underlyingDecimals)
  if (formValues?.riskDirection === RiskDirection.receiver) {
    formNotional = formNotional.mul(-1)
  }
  if(openPosition && openPosition?.riskDirection === RiskDirectionType.RECEIVER) {
    openPositionNotional = openPositionNotional.mul(-1)
  }
  const resultNotional = formNotional.add(openPositionNotional)

  const currentFutureRate = bnToDecimal(future.vAMMParams.currentFutureRate, 18)
  const timeToMaturity = future.termStart.add(future.termLength).sub(Math.round(Date.now()/1000)).toNumber()

  const dv100 = rateMath.getDv100(
    bnToDecimal(resultNotional, underlyingDecimals),
    currentFutureRate,
      timeToMaturity
  )
  const liquidationThresholdDiff = (currentMargin.sub(liquidationThreshold))
  const liquidationRate = currentFutureRate.add(liquidationThresholdDiff.mul(-0.01).div(dv100)).mul(100)

  return liquidationRate.toNumber()
}

export const getLeverage = (
  params: {
    market?: MarketInfo,
    marketPortfolio?: MarketPortfolio,
    margin?: BigNumber
  }
) => {
  const { market, marketPortfolio } = params
  const underlyingDecimals = market ? market.descriptor.underlyingDecimals : 6

  const notional = marketPortfolio?.futureOpenPositions.filter(item => {
    const { futureId } = item
    const future = market?.futures.find(future => future.id === futureId)
    return future && future.termStart.add(future.termLength).mul(1000).gt(Date.now())
  }).reduce((acc, item) => {
    return acc.add(item.notional)
  }, zero)

  const margin = params.margin
    ? params.margin
    : (marketPortfolio ? marginTotal(marketPortfolio.marginState.margin) : zero)

  let leverage = (notional && margin.gt(0)
    ? bnToDecimal(notional, underlyingDecimals).div(bnToDecimal(margin, underlyingDecimals))
    : new Decimal(0)).toNumber()

  const maxLeverage = marketPortfolio && notional && marketPortfolio.marginState.initialMarginThreshold.gt(0)
    ? notional.div(marketPortfolio.marginState.initialMarginThreshold).toNumber()
    : 1000

  const leveragePercentage = leverage / maxLeverage

  return {
    leverage,
    maxLeverage,
    leveragePercentage
  }
}

export const getActivePositions = (positions: FutureOpenPosition[], market: MarketInfo) => {
  return positions.filter(position => {
    const { futureId } = position
    const future = market?.futures.find(future => future.id === futureId)
    return future
      ? future.termStart.add(future.termLength).gt(BigNumber.from(Math.round(Date.now() / 1000)))
      : false
  })
}


export const getFutureAlias = (market: MarketInfo, future: FutureInfo) => {
  return getFutureAliasByDescriptor(market.descriptor, future)
}

export const monthsAlias: Record<number, string> = {
  1: 'JAN',
  2: 'FEB',
  3: 'MAR',
  4: 'APR',
  5: 'MAY',
  6: 'JUN',
  7: 'JUL',
  8: 'AUG',
  9: 'SEP',
  10: 'OCT',
  11: 'NOV',
  12: 'DEC'
}

export const getShortDate = (timestamp: number) => {
  const date = new Date(timestamp)
  const month = monthsAlias[(date.getMonth() + 1)]
  const year = (date.getFullYear() - 2000)
  return `${month}${year}`
}

const trimString = (value: string) => {
  return value.trim().toLowerCase().replaceAll(' ', '-').toUpperCase()
}

export const getFutureAliasByDescriptor = (marketDescriptor: MarketDescriptor, future: FutureInfo) => {
  const { termStart, termLength } = future

  const sourceName = trimString(marketDescriptor.sourceName)
  const [instrumentName] = trimString(marketDescriptor.instrumentName).split('-')
  const termEnd = termStart.add(termLength).toNumber() * 1000
  const formattedDate = getShortDate(termEnd)
  if(sourceName === 'COINDESK-INDICES') {
    return `${instrumentName}-${formattedDate}`
  }
  return `${sourceName}-${instrumentName}-${formattedDate}`
}

export const getFutureByAlias = (
  alias: string,
  markets: MarketInfo[],
) => {
  const futures = markets.flatMap(market => market.futures)
  for(const future of futures) {
    const market = markets.find(market => market.descriptor.id === future.marketId)
    if(market) {
      if(getFutureAlias(market, future).toLowerCase() === alias.toLowerCase()) {
        return {
          market,
          future
        }
      }
    }
  }
}


export const getMarketByFutureId = (
  futureId: string,
  markets: MarketInfo[],
) => {
  const flatMarkets = markets.flatMap(market => market.futures)
  const searchedFuture = flatMarkets.find(market => market.id == futureId)
  let foundMarketId = null
  if (searchedFuture) {
    foundMarketId = searchedFuture.marketId
  }
return foundMarketId
}

export const getMarketAlias = (market: MarketInfo) => {
  const sourceName = trimString(market.descriptor.sourceName)
  const [instrumentName] = trimString(market.descriptor.instrumentName).split('-')
  if(sourceName === 'COINDESK-INDICES') {
    return `${instrumentName}`
  }
  return `${sourceName}-${instrumentName}`
}

export const formatMarketName = (market: string) => {
  const parts = market.split("-");
  if (parts.length !== 3) return market; // Handle unexpected formats

  let [exchange, pair, date] = parts;
  exchange = exchange.charAt(0).toUpperCase() + exchange.slice(1).toLowerCase();
  return `${exchange} ${removeUSDT(pair)} ${date}`;
}

export const getColorByValue = (value: number | BigNumber | RiskDirection) => {
  let valueNumber = 0
  if(typeof value === 'number') {
    valueNumber = value
  } else if(value instanceof BigNumber) {
    valueNumber = value.gt(0) ? 1 : value.lt(0) ? -1 : 0
  } else if(Object.values(RiskDirection).includes(value)) {
    valueNumber = value === RiskDirection.payer ? 1 : value === RiskDirection.receiver ? -1 : 0
  }

  return (valueNumber > 0)
    ? 'ratePositive'
    : valueNumber < 0
      ? 'rateNegative'
      : 'text'
}

export const isFulfilled = <T,>(p:PromiseSettledResult<T>): p is PromiseFulfilledResult<T> => p.status === 'fulfilled';
export const isRejected = <T,>(p:PromiseSettledResult<T>): p is PromiseRejectedResult => p.status === 'rejected';

