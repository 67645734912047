import {networks, wagmiAdapter} from "./modules/wagmi";
import {createAppKit} from "@reown/appkit";
import config from "./config";
import {RoutePaths} from "./constants";

export const walletLoginDialog = createAppKit({
    adapters: [wagmiAdapter],
    networks,
    projectId: config.walletConnectProjectId,
    metadata: {
        name: 'Rho Labs Wallet Connect',
        description: 'Rho Labs Wallet Connect Service',
        url: 'https://example.com', // origin must match your domain & subdomain
        icons: ['https://avatars.githubusercontent.com/u/179229932'],
    },
    privacyPolicyUrl: RoutePaths.restrictedPersons,
    termsConditionsUrl: 'https://www.rho.trading/terms',
    features: {
        legalCheckbox: true,
        email: false,
        socials: false,
        collapseWallets: false,
    },
    allWallets: 'SHOW',
});
