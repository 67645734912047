import { Box, Text } from "grommet";
import { FloatRateBadge, MarketIcon, MaturityBadge, OpTypeBadge, TickerBadge } from "../../components";
import React from "react";
import { OpType } from "../../types";
import { LiquidityProvisionProps } from "./index";
import { BigNumber } from "ethers";
import { formatFloatRates, useRates } from "../../providers/FloatRateProvider";
import { formatDate, getMapperForUSDT, removeUSDT } from "../../mappers";
import moment from "moment";

export const Header = (props: LiquidityProvisionProps) => {
  const { market, currentSwap } = props

  const futureRate = currentSwap ? currentSwap.vAMMParams.currentFutureRate : BigNumber.from(0)

  const maturity = BigNumber.from(currentSwap?.termStart || '0')
    .add(BigNumber.from(currentSwap?.termLength || '0'))
    .mul(1000).toNumber()


    const sourceName =  market.descriptor.sourceName
    const marketName =  `${market.descriptor.sourceName} ${market.descriptor.instrumentName}` 

    
    let currentInstrumentName = 'All markets'
    let futureInstrument = ''
  
    if (market && currentSwap) {
      const futureDate = formatDate(moment((currentSwap.termStart.add(currentSwap.termLength).toNumber() * 1000)).format('DD-MM-YYYY'))
       futureInstrument = removeUSDT(market?.descriptor?.instrumentName || '')
      currentInstrumentName = `${sourceName} ${futureInstrument} ${futureDate}`
    }

  const title = `${market.descriptor.sourceName} ${market.descriptor.instrumentName}`
  const underlyingName = market.descriptor.underlyingName
  const tag = market.descriptor.tag as OpType

  const rates = useRates()
  const floatRate = formatFloatRates({
    rates,
    sourceName: title,
    tag,
    marketId: market.descriptor.id
  })

  return <Box direction={'row'} justify={'between'}>
    <Box direction={'row'}>
      <Box>
        <MarketIcon
          name={futureInstrument == 'CESR' ? 'ETH' : futureInstrument}
          tokenName={ sourceName}
          size={'60px'}
          minorIconSize={'35px'}
          iconSize={'60px'}
        />
      </Box>
      <Box margin={{ left: '32px' }} justify={'center'}>
        <Box direction={'row'}>
          <Text size={'20px'} weight={500}>{currentInstrumentName}</Text>
          <Box margin={{ left: '8px' }}>
            <OpTypeBadge opType={tag} />
          </Box>
        </Box>
        <Box direction={'row'} gap={'8px'}>

          <Text color={'textSecondary'}>{getMapperForUSDT(underlyingName)}</Text>
          <FloatRateBadge floatRate={floatRate} coloredValue={false} />

        </Box>
      </Box>
    </Box>
    <Box direction={'row'} gap={'16px'}>
      <Box pad={'6px 16px'} justify={'center'} background={'badgeBackground'} round={'3px'}>
        <MaturityBadge value={maturity} />
      </Box>
      <Box pad={'6px 16px'} justify={'center'} background={'badgeBackground'} round={'3px'}>
        <TickerBadge value={futureRate} />
        <Text color={'textSecondary'} size={'12px'} style={{ lineHeight: '22px' }}>Last rate</Text>
      </Box>
    </Box>
  </Box>
}
