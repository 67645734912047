import React, {useState} from "react";
import {Box, Text} from "grommet";
import {Number, PrimaryButton, QuestionMark, TokenAmountInput, ValuesChangeBadge, WidgetContainer} from "../../components";
import {Button as AntdButton, Skeleton, Typography} from "antd";
import {ReactComponent as CrossImg} from "../../assets/images/cross.svg";
import {bnToDecimal, prepareFormNumber} from "../../utils";
import {BigNumber} from "ethers";
import {useAccount, useConnect, useWriteContract} from "wagmi";
import VaultRouterABI from "../../abi/VaultRouterABI.json";
import {waitForTransactionReceipt} from "@wagmi/core";
import {connect, writeContract} from "wagmi/actions";
import {toast} from "react-toastify";
import {getOraclePackages} from "../../api/oracleService";
import {getAllowance} from "../../api/erc20Contract";
import config from "../../config";
import {useVault} from "../../providers/VaultDataProvider";
import {showDepositNotification} from "./notifications";
import {erc20Abi} from "viem";
import {wagmiConfig} from "../../modules/wagmi";
import {switchNetwork, useBalance, useNetwork, usePublicClient} from "../../hooks/blockchainHooks";
import {getMapperForUSDT} from "../../mappers";
import styled from "styled-components";

const zero = BigNumber.from(0);

const WarningText = styled(Text)`
  color: #ff4d4f;
  font-size: 12px;
  margin-top: 8px;
`;

export const CustomWidgetContainer = styled(Box)`
  border-radius: 12px;
  background: #1e1e20;
  position: relative;
  height: inherit;
`;

interface FormValuesState {
  amount: string;
}

const defaultValuesState: FormValuesState = {
  amount: "0",
};

type FormValueProperty = {
  [K in keyof FormValuesState]?: FormValuesState[K];
};

export const InvestModal = (props: {onClose?: () => void}) => {
  const {vault, isInitialLoading: isVaultLoading, refetch: refetchVaultBalance} = useVault();
  const {isConnected, address: userAddress} = useAccount();
  const {chain} = useNetwork();
  const {connectors} = useConnect();
  const metamaskConnector = connectors.find((item) => item.id.toLowerCase() === "metamask");

  const {data: underlyingBalance} = useBalance({
    address: userAddress,
    token: vault?.underlyingAddress as `0x${string}`,
    watch: true,
    enabled: !!vault?.underlyingAddress,
    chainId: config.chainId,
  });

  const client = usePublicClient();

  const [formValues, setValues] = useState<FormValuesState>(defaultValuesState);
  const [depositInProgress, setDepositInProgress] = useState(false);

  const fromTokenDecimals = vault?.underlyingDecimals || 6;
  const valueFrom = BigNumber.from(underlyingBalance?.value || 0);
  const amount = prepareFormNumber(formValues.amount, fromTokenDecimals);
  const valueTo = valueFrom.sub(amount);
  const selectedTokenName = vault?.underlyingName || "USDT";
  const underlyingName = vault?.underlyingName || "USDT";
  const deposited = bnToDecimal(vault?.maxWithdraw || zero, vault?.underlyingDecimals || 6);

  const onChangeFormValue = (newState: FormValueProperty) => {
    setValues((currentState) => {
      return {
        ...currentState,
        ...newState,
      };
    });
  };

  const {writeContractAsync: depositAsync} = useWriteContract();

  const depositAmount = prepareFormNumber(formValues.amount, fromTokenDecimals);

  const isAmountBelowMinimum = depositAmount.gt(0) && depositAmount.lt(BigNumber.from(100).mul(BigNumber.from(10).pow(fromTokenDecimals)));

  const onDepositClick = async () => {
    try {
      if (!vault) {
        console.error("No vault found");
        return;
      }
      if (!userAddress) {
        return;
      }
      setDepositInProgress(true);
      const packages = await getOraclePackages();

      const depositAmountBigint = BigInt(depositAmount.toString());
      const minSharesOut = 0n;
      const deadline = Date.now() + 5 * 60 * 1000;

      const allowance = await getAllowance(vault.underlyingAddress, userAddress, config.vaultRouterAddress);
      console.log("allowance", allowance.toString(), "depositAmount", depositAmount.toString());

      let gas = undefined;
      if (config.gasEstimateMultiplier) {
        try {
          gas = await client.estimateContractGas({
            address: vault.underlyingAddress as `0x${string}`,
            abi: erc20Abi,
            functionName: "approve",
            args: [config.vaultRouterAddress as `0x${string}`, depositAmountBigint],
            account: userAddress as `0x${string}`,
          });
          gas = BigInt(Math.round(+gas.toString() * config.gasEstimateMultiplier));
          console.log(`Using gas multiplier: ${config.gasEstimateMultiplier}, gas value: ${gas}`);
        } catch (e) {
          console.error("Failed to estimate gas", e);
        }
      }

      if (allowance.lt(depositAmount)) {
        const approveResultHash = await writeContract(wagmiConfig, {
          address: vault.underlyingAddress as `0x${string}`,
          abi: erc20Abi,
          functionName: "approve",
          args: [config.vaultRouterAddress as `0x${string}`, depositAmountBigint],
          chainId: config.chainId,
          gas,
        });
        try {
          await waitForTransactionReceipt(wagmiConfig, {
            hash: approveResultHash,
            chainId: config.chainId,
          });
        } catch (e) {
          console.error("Failed waitForTransactionReceipt:", e);
          await new Promise((resolve) => setTimeout(resolve, 6000));
        }
      }
      const depositReceiptHash = await depositAsync({
        address: config.vaultRouterAddress as `0x${string}`,
        abi: VaultRouterABI,
        functionName: "deposit",
        chainId: config.chainId,
        args: [vault.id, depositAmountBigint, userAddress, minSharesOut, packages, deadline],
      });
      console.log("Deposit tx:", depositReceiptHash);
      try {
        await waitForTransactionReceipt(wagmiConfig, {
          hash: depositReceiptHash,
          chainId: config.chainId,
        });
        const updatedVault = await refetchVaultBalance();
        showDepositNotification(formValues.amount, updatedVault || vault, depositReceiptHash);
      } catch (e) {
        console.error("Failed waitForTransactionReceipt:", e);
        await new Promise((resolve) => setTimeout(resolve, 6000));
      }
      // const updatedVault = await refetchVaultBalance();
      // showDepositNotification(formValues.amount, updatedVault || vault, depositReceiptHash);
      setValues({
        amount: "0",
      });
    } catch (e) {
      let message = (e as Error).message;
      let messageText = message;
      const [description] = message.split("(0x");
      if (description.length > 0) {
        messageText = description;
      }

      toast.error(
        <Typography.Text copyable={{text: message}} style={{color: "white"}}>
          {"Failed to deposit: " + messageText}
        </Typography.Text>,
        {
          closeOnClick: false,
        }
      );
    } finally {
      setDepositInProgress(false);
    }
  };

  const isUnsupportedNetwork = isConnected && chain === undefined;

  const onConnectClicked = async () => {
    try {
      if (metamaskConnector) {
        await connect(wagmiConfig, {
          connector: metamaskConnector,
          chainId: config.chainId,
        });
      }
    } catch (e) {
      console.error("Failed to connect wallet", e);
    }
  };

  const inputBottomPanel = (
    <Box pad={"0 10px 0"}>
      <Box background={"#272835"} width={"100%"} height={"1px"} />
      <Box direction={"row"} justify={"between"} align={"center"}>
        <Box direction={"row"} margin={{top: "8px", bottom: "8px"}} gap={"10px"} align={"center"}>
          <Box direction={"row"} align={"center"} gap={"4px"}>
            <Text color={"textSecondary"}>Wallet</Text>
            <QuestionMark tooltipId={"margin_mgmt_wallet"} tooltipText={"User balance"} />
          </Box>
          <ValuesChangeBadge from={valueFrom} to={valueTo} decimals={fromTokenDecimals} name={selectedTokenName} showName={false} fontSize={"12px"} />
        </Box>
        <Box direction={"row"} gap={"4px"}>
          {[25, 50, 75, 100].map((value) => {
            return (
              <Box
                key={value}
                background={"optionBg"}
                round={"4px"}
                pad={"4px 7px"}
                onClick={() => {
                  try {
                    const amount = bnToDecimal(valueFrom, fromTokenDecimals);
                    const amountDecimal = amount.mul(value / 100);
                    onChangeFormValue({amount: amountDecimal.toString()});
                  } catch (e) {
                    console.error("Failed to format amount", valueFrom.toString());
                  }
                }}
              >
                <Text size={"12px"} color={"accentWhite2"}>
                  {value}%
                </Text>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );

  return (
    <CustomWidgetContainer style={{position: "relative"}}>
      <Box>
        <TokenAmountInput id={"margin_mgmt_amount"} value={formValues.amount} status={""} options={[{text: getMapperForUSDT(underlyingName || "")}]} width={"auto"} bottompanel={vault ? inputBottomPanel : <Skeleton.Input active size={"small"} style={{width: "80px", height: "20px"}} />} onChange={(value) => onChangeFormValue({amount: (value || 0).toString()})} />
        {isAmountBelowMinimum && (
          <Box style={{position: "absolute", top: "85px", left: "4px"}}>
            <WarningText>Minimum deposit amount is 100 USD</WarningText>
          </Box>
        )}
      </Box>
      <Box margin={{top: "22px"}}>
        {isConnected ? (
          isUnsupportedNetwork ? (
            <PrimaryButton
              text={"Switch network"}
              onClick={() => {
                switchNetwork({
                  chainId: config.chainId,
                }).catch((e) => {
                  console.error("Failed to switch network", e);
                });
              }}
            />
          ) : (
            <PrimaryButton
              text={"Deposit"}
              disabled={
                !vault || amount.lte(0) || depositInProgress || valueTo.lt(0) || isAmountBelowMinimum // Added this condition
              }
              loading={depositInProgress}
              onClick={onDepositClick}
            />
          )
        ) : (
          <PrimaryButton text={"Connect wallet"} onClick={onConnectClicked} />
        )}
      </Box>
    </CustomWidgetContainer>
  );
};
